<template>
  <div class="grid grid-flow-row auto-cols-auto border-cc-border-color">
    <div class="my-2 grid grid-flow-col grid-cols-cc-6 border-cc-border-color">
      <div
          class="flex justify-center items-center"
          :aria-label="`Zeile ${idx + 1}`"
          tabindex="0"
          role="cell"
          style="text-align: center"
      >
        {{ idx + 1 }}.
      </div>
      <div role="row" class="flex flex-col xl:flex-row justify-center items-center">
        <!-- Period (Zeitraum) input  -->
        <!-- From (von) -->
        <div role="form" aria-label="Zeitraum von ">
          <material-date-input
              :col-num="0"
              :idx="idx"
              type-string="from"
              :ref-Ids="['from-day-inp', 'from-month-inp', 'from-year-inp']"
              :placeholder="['TT', 'MM', 'JJJJ']"
              :disabledInputs="[]"
              :init-values="initPeriodFrom"
              :initial-focus="initialFocus"
              :resetContext="resetContext"
              :revalidate-bus="revalidateBus"
              :period-err-ctx="periodErrCtx"
              :row-id="row.id"
              @input-invalid="inputIsInvalid($event)"
              @date-constructed="savePeriod"
              @focus-next="focusNext"
              @propagate-refs="addFromRefs"
          ></material-date-input>
        </div>
        <div tabindex="-1">-</div>
        <!-- To (bis)-->
        <div role="form" aria-label="Zeitraum bis ">
          <material-date-input
              :col-num="1"
              :idx="idx"
              type-string="to"
              :ref-Ids="['to-day-inp', 'to-month-inp', 'to-year-inp']"
              :placeholder="['TT', 'MM', 'JJJJ']"
              :disabledInputs="[]"
              :init-values="initPeriodTo"
              :resetContext="resetContext"
              :revalidate-bus="revalidateBus"
              :period-err-ctx="periodErrCtx"
              :row-id="row.id"
              @input-invalid="inputIsInvalid($event)"
              @date-constructed="savePeriod"
              @focus-next="focusNext"
              @propagate-refs="addFromRefs"
          ></material-date-input>
        </div>
      </div>
      <div class="flex justify-center items-center">
        <!-- Employment (Beschäftigung) select -->
        <BaseTree
            :rowId="this.row.id"
            :optionsMenus="selectOptions"
            :init-val="initEmployment"
            :closeDropdownContext="closeDropdownContext"
            :resetContext="resetContext"
            @item-selected="itemSelected"
        />
      </div>

      <div class="flex flex-col xl:flex-row items-center justify-center">
        <!-- time fracture (Teilzeitbruch)  inputs-->
        <!-- counter (Zähler) -->
        <div>
          <material-input
              :row-id="row.id"
              class="m-0"
              col="Teilzeitbruch"
              comp-place="Zähler"
              ref-id="fracCounter"
              next-ref="fracDenum"
              type-string="number"
              :step-cnt="0.01"
              placeholder-string="00.00"
              :init-value="initFractionCounter"
              :isDisabled="fractureDisabled"
              :resetContext="fractureResetContext"
              @input-invalid="inputIsInvalid($event)"
              @focus-next="focusNextFrac"
              @save-value="saveFractureCounter"
              @propagate-refs="addFracRefs"
              @close-dropdown="onCloseDropdown"
          ></material-input>
        </div>
        <span class="mr-1.5">/</span>
        <!-- denominator (Nenner) -->
        <div>
          <material-input
              :row-id="row.id"
              col="Teilzeitbruch"
              comp-place="Nenner"
              ref-id="fracDenum"
              next-ref="durYear"
              type-string="number"
              :step-cnt="0.01"
              placeholder-string="00.00"
              :init-value="initFractionDenom"
              :isDisabled="fractureDisabled"
              :resetContext="fractureResetContext"
              @input-invalid="inputIsInvalid($event)"
              @save-value="saveFractureDenom"
              @focus-next="focusNextFrac"
              @propagate-refs="addFracRefs"
          ></material-input>
        </div>
      </div>
      <div class="flex flex-col xl:flex-row justify-center items-center">
        <!-- This is a duration -->
        <material-input
            :row-id="row.id"
            col="Höchtens anrechenbar"
            comp-place="Jahre"
            ref-id="durYear"
            next-ref="durDay"
            type-string="number"
            placeholder-string="JJ"
            :maxLength="4"
            :init-value="initEligibleYears"
            :isDisabled="false"
            :resetContext="resetContext"
            :poll-context="eligYearPolCtx"
            @input-invalid="inputIsInvalid($event)"
            @save-value="saveEligibleYears('eligible', $event)"
            @focus-next="focusNextFrac"
            @propagate-refs="addFracRefs"
        ></material-input>
        <material-input
            :row-id="row.id"
            col="Höchtens anrechenbar"
            comp-place="Tage"
            ref-id="durDay"
            type-string="number"
            placeholder-string="TTT"
            :maxLength="4"
            :init-value="initEligibleDays"
            :isDisabled="false"
            :resetContext="resetContext"
            :poll-context="eligDayPolCtx"
            @input-invalid="inputIsInvalid($event)"
            @save-value="saveEligibleDays('eligible', $event)"
            @focus-next="focusNextFrac"
            @propagate-refs="addFracRefs"
        ></material-input>
      </div>
      <div class="flex justify-center items-center">
        <BaseDelete
            role="button"
            aria-label="Lösche Zeile"
            class="delete-container"
            tabindex="0"
            @click="deleteRow"
            @keydown.enter="deleteRow"
        />
      </div>
    </div>

    <div class="ml-10" v-if="hasError || hasIntersection">
      <section v-if="error.from.length > 0 || error.to.length > 0 || error.period.length > 0" data-test='from_to-msg-container'>
        <h5 class="headline-xsmall">Startdatum - Enddatum</h5>
        <div v-if="error.from.length > 0">
          <div class="text-cc-red-600" :key="idx" v-for="(error, idx) in error.from" data-test='from-msg'>
            Startdatum: {{error.msg}}
          </div>
        </div>
        <div v-if="error.to.length > 0">
          <div class="text-cc-red-600" :key="idx" v-for="(error, idx) in error.to" data-test='to-msg'>
            Enddatum: {{error.msg}}
          </div>
        </div>

        <div class="text-cc-red-600" :key="idx" v-for="(error, idx) in error.period">
          {{error.msg}}
        </div>
      </section>

      <section v-if="error.fraction.length > 0">
        <h5 class="headline-xsmall">Teilzeitbruch</h5>
        <div class="text-cc-red-600" :key="idx" v-for="(error, idx) in error.fraction">
          {{error.msg}}
        </div>
      </section>
      <section v-if="error.eligible.length > 0">
        <h5 class="headline-xsmall">Höchst. anrechenbar</h5>
        <div class="text-cc-red-600" :key="idx" v-for="(error, idx) in error.eligible">
          {{error.msg}}
        </div>
      </section>

      <div v-if="missingFields !== null">
        <h5 class="headline-small text-cc-red-600" >Es fehlen folgende Felder </h5>
        <ul>
          <li class="text-cc-red-600" v-if="missingFields.from">Das Startdatum</li>
          <li class="text-cc-red-600" v-if="missingFields.to">Das Enddatum</li>
          <li class="text-cc-red-600" v-if="missingFields.employmentKey">Der Schlüssel der Beschäftigung</li>
          <li class="text-cc-red-600" v-if="missingFields.fractureCounter">Der Zähler des Teilzeitbruchs</li>
          <li class="text-cc-red-600" v-if="missingFields.fractureDenom">Der Nenner des Teilzeitbruchs</li>
        </ul>
      </div>
      <div v-if="hasIntersection" class="mt-2 text-cc-red-600">
        {{intersection}}
      </div>
      <div class="mr-5 bg-cc-border-color h-1 rounded"></div>
    </div>
  </div>
</template>

<script>
import MaterialDateInput from "../MaterialDateInput.vue";
import MaterialInput from "../MaterialInput.vue";
import BaseDelete from "../SVG-Icons/BaseDelete";
import BaseTree from "./BaseTree.vue";
import mitt from "mitt";
import RevalidationBus from "@/helper/revalidationBus";
import PollCtx from "@/helper/PollCtx";
import dayjs from "dayjs";

export default {
  components: {
    // BaseSelectTest,
    BaseDelete,
    MaterialDateInput,
    MaterialInput,
    BaseTree
  },
  props: {
    selectOptions: Array,
    row: Object,
    idx: Number,
    focused: Number,
    validationErrors: Object,
    revalidateBus: RevalidationBus,
    intersections: Array,
    intersectionCtx: Object
  },
  provide: {
    focusNext(nextHmtlElem) {
      if (nextHmtlElem) {
        nextHmtlElem.focus();
      } else {
        throw Error("Es gibt kein nächtest element zum Selektieren");
      }
    }
  },
  emits: ["deleteRow", "publish-id", "publish-validation", "reset-submit-val"],
  data() {
    return {
      from: {
        dayInvalid: false,
        monthInvalid: false,
        yearInvalid: false
      },
      to: {
        dayInvalid: false,
        monthInvalid: false,
        yearInvalid: false
      },
      counter: {
        dayInvalid: false
      },
      denominator: {
        dayInvalid: false
      },
      eligYears: {
        dayInvalid: false
      },
      eligDays: {
        dayInvalid: false
      },
      refArrayIds: [
        ["from-day-inp", "from-month-inp", "from-year-inp"],
        ["to-day-inp", "to-month-inp", "to-year-inp"]
      ],
      refsArr: [],
      fracRefArr: [],
      error: {
        index: 0,
        from: [],
        to: [],
        fraction: [],
        eligible: [],
        period:[],
      },
      closeDropdownContext: mitt(),
      fractureDisabled: true,
      fractureResetContext: mitt(),
      resetContext: mitt(),
      /**
       * @type{import("@/types").MissingField}
       */
      missingFields: null,
      eligDayPolCtx: new PollCtx(),
      eligYearPolCtx: new PollCtx(),
      periodErrCtx: mitt(),
      interactionCtx: mitt()
    };
  },
  methods: {
    checkLastEndDate(){
      const serivcePeriods = this.$store.getters.getServicePeriods;
      const thisServicePeriod = this.$store.getters.getServicePeriods.find(period => period.id === this.row.id);

      const lastPeriod = serivcePeriods.reduce((prevPeriod, currPeriod) => {
        if(prevPeriod.workPeriod.to < currPeriod.workPeriod.to) return currPeriod;
        else return prevPeriod;
      })

      if(thisServicePeriod.id === lastPeriod.id){
        const supplybeginDate = dayjs(this.$store.getters.getBegin);
        const thisEndDate = dayjs(thisServicePeriod.workPeriod.to);

        const subtractedSupplyBeginDate = supplybeginDate.subtract(1, "day");

        if(thisEndDate.isSame(subtractedSupplyBeginDate)){
          return { valid: true, reason: "" }
        }

        this.setError("to", "lastEndDate", "Das Enddatum der chronologisch letzten Dienstzeit muss unmittelbar vor dem Versorgungsbeginn liegen.");
        return {valid: false, reason: "" }
      }
      return { valid: true, reason: "" }
    },
    resetMissingFields(){
      this.missingFields = null;
      this.$emit("reset-submit-val");
    },
    checkForMissingFields(){
      const row = this.$store.getters.getServicePeriods.find(itRow => itRow.id === this.row.id);
      const missingFields = {
        rowNum: this.$store.getters.searchForPeriodRowNum(row.id),
        from: false,
        to: false,
        employmentKey: false,
        fractureCounter: false,
        fractureDenom: false
      }

      if(!row.workPeriod?.from) missingFields.from = true;
      if(!row.workPeriod?.to) missingFields.to = true;
      if(!row.employment) missingFields.employmentKey = true;
      if(row.employment?.text?.toLowerCase().includes("teil") && !row.employment?.text.toLowerCase().includes("altersteilzeit")){
        if(row.fracture === undefined || row.fracture === null){
          missingFields.fractureCounter = true;
          missingFields.fractureDenom = true;
        }
        if(row.fracture !== undefined && isNaN(row.fracture?.counter)){
          missingFields.fractureCounter = true;
        }
        if(row.fracture !== undefined &&  isNaN(row.fracture?.denominator)){
          missingFields.fractureDenom = true;
        }
      }
      if(missingFields.from || missingFields.to || missingFields.employmentKey || missingFields.fractureCounter || missingFields.fractureDenom){
        this.missingFields = missingFields;
        return {valid: false, reason: ""};
      }
      return {valid: true, reason: ""};
    },
    setError(/** @type{String} */key,/** @type{String} */type, /** @type{String} */ msg){
      if(this.error[key] !== undefined && !this.error[key].find(err => err.type === type)){
        this.error[key].push({id: Math.random() * 100, type, msg});
      }

    },
    removeError(/** @type{String} */key, /** @type{String} */type){
      if(this.error[key].find(err => err.type === type)){
        this.error[key] = this.error[key].filter(err => err.type !== type);
      }
    },
    deleteRow() {
      this.intersectionCtx.emit("reset");

      if(this.idx === 0 && this.$store.getters.getServicePeriods.length <= 1){
        this.resetContext.emit("reset", {shouldBubble: true});
        this.fractureResetContext.emit("reset", {shouldBubble: true});

        this.$store.dispatch({
          type: "removePeriodRow",
          row: this.row,
          reset: true
        });
        this.resetContext.emit("reset", {shouldBubble: true});
        return;
      }
      this.$store.dispatch({
        type: "removePeriodRow",
        row: this.row
      });
      this.resetContext.emit("reset", {shouldBubble: true});
    },
    savePeriod(date, key){
      this.resetMissingFields();

      if(date === null){
        this.$store.dispatch({
          type: "savePeriod",
          rowId: this.row.id,
          timestamp: null,
          objKey: key
        });
        return;
      }

      this.$store.dispatch({
        type: "savePeriod",
        rowId: this.row.id,
        timestamp: date.getTime(),
        objKey: key
      });
    },
    saveFractureCounter(value) {
      this.resetMissingFields();
      this.$store.dispatch({
        type: "setTimeFracture",
        rowId: this.row.id,
        objKey: "counter",
        value
      });
    },
    saveFractureDenom(value) {
      this.resetMissingFields();
      this.$store.dispatch({
        type: "setTimeFracture",
        rowId: this.row.id,
        objKey: "denominator",
        value
      });
    },
    saveEligibleYears(key, value) {
      this.resetMissingFields();
      this.$store.dispatch({
        type: "setEligible",
        rowId: this.row.id,
        objKey: "years",
        value
      });
      // this.checkIfBothEligibleFieldsSet(key)
    },
    saveEligibleDays(key, value) {
      this.resetMissingFields();
      this.$store.dispatch({
        type: "setEligible",
        rowId: this.row.id,
        objKey: "days",
        value
      });
      // this.checkIfBothEligibleFieldsSet(key)
    },
    inputIsInvalid(payload) {
      this.removeError("to", "lastEndDate");

      if (payload.action === "set") {
        this.setError(payload.key, payload.type, payload.msg);
      } else if (payload.action === "reset") {
        this.removeError(payload.key, payload.type);
      }
    },
    focusNext(col, row) {
      if (
        this.refsArr &&
        this.refArrayIds &&
        this.refsArr.length > col &&
        this.refArrayIds.length > col
      )
        this.refsArr[col][this.refArrayIds[col][row]]?.focus();
    },
    addFromRefs(elem) {
      this.refsArr.push(elem);
    },
    focusNextFrac(nextId) {
      this.fracRefArr.find(elem => elem.id === nextId)?.focus();
    },
    addFracRefs(elem) {
      this.fracRefArr.push(elem);
    },
    onCloseDropdown() {
      this.closeDropdownContext.emit("close-dropdown");
    },
    itemSelected(item){
      this.resetMissingFields();
      this.isFractureDisabled(item);

      this.inputIsInvalid({ key: "eligible", action: "reset", type: "elig-missing" })
      this.inputIsInvalid({ key: "eligible", action: "reset", type: "elig-mandatory" });
    },
    isFractureDisabled(item){
      if(item.title.toLowerCase().includes("teil")) {
        if(process.env.NODE_ENV === "development"){
          console.log("DynamicRow | isFractureDisabled | fractureDisabled: false")
        }
        this.fractureDisabled = false;

      } else {
        this.fractureDisabled = true;
        this.saveFractureCounter(0);
        this.saveFractureDenom(0);
        this.fractureResetContext.emit("reset");
      }
    },
    /**
     *
     * @returns {import("@/types").RevalidationResult}
     */
    checkIfBothEligibleFieldsSet(){
      /** @type {import("@/types").Period} */
      const periodRow = this.$store.getters.getServicePeriods.find(row => row.id === this.row.id);

      /** @type {import("@/types").Eligible} */
      const eligible = periodRow?.eligible;

      /** @type {import("@/types").Employment} */
      const employment = periodRow.employment;

      if((!employment.text.includes("1230") && !employment.text.includes("1233")) && eligible &&
          (
              isNaN(eligible.days) && !isNaN(eligible.years) ||
              isNaN(eligible.years) && !isNaN(eligible.days)
          )
      ){
        this.setError("eligible", "elig-missing", "Wenn ein Feld gesetzt ist müssen beide Felder gesetzt werden.");
        return { valid: false, reason: "Wenn ein Feld gesetzt ist müssen beide Felder gesetzt werden."};
      }
      return { valid: true, reason: ""};
    },

    /**
     *
     * @returns {import("@/types").RevalidationResult}
     */
    checkMandatoryEligible(){
      /** @type {import("@/types").Period} */
      const periodRow = this.$store.getters.getServicePeriods.find(row => row.id === this.row.id);

      /** @type {import("@/types").Eligible} */
      const eligible = periodRow?.eligible;

      /** @type {import("@/types").Employment} */
      const employment = periodRow.employment;

      if((employment.text.includes("1230") || employment.text.includes("1233")) && (!eligible ||
          eligible?.days === null || eligible?.days === undefined || isNaN(eligible?.days)|| eligible?.years === null || eligible?.years === undefined || isNaN(eligible?.years)))
      {

        const key = employment.text.split(" ")[0];
        this.setError("eligible", "elig-mandatory", `Für den Schlüssel ${key} muss Höchst anrechenbar gesetzt werden.`);
        return {valid: false, reason: `Für den Schlüssel ${key} muss Höchst anrechenbar gesetzt werden.`}
      }

      return {valid: true, reason: ""}
    },
    /**
     *
     * @returns {import("@/types").RevalidationResult}
     */
    checkPlausi(){
      /** @type{import("@/types").Period} */
      const servicePeriod = this.$store.getters.getServicePeriods.find(period => period.id === this.row.id && period.employment.text.includes("0657"));
      if(!servicePeriod) return {valid: true, reason: ""};

      const startTs = servicePeriod.workPeriod.from;
      const stopTs = servicePeriod.workPeriod.to;
      const startDate = dayjs(startTs);
      const stopDate = dayjs(stopTs);

      let reason = "";

      const sixMonthsAfter = startDate.add(6, "month");
      if(stopDate.isAfter(sixMonthsAfter)) {
        reason = "Ein Zeitraum in Erziehungszeit darf nur 6 Monate betragen.";
        return { valid: false, reason};
      }
      return { valid: true, reason};
    },
    /**
     * valid = true means there is no Error
     * @returns {import("@/types").RevalidationResult}
     */
    getError(){
      const valid = !(this.error.from.length > 0 || this.error.to.length > 0 || this.error.period.length > 0 || this.error.fraction.length > 0
          || this.error.eligible.length > 0 || this.missingFields !== null);

      return { valid, reason: ""}
    },
    /**
     *
     * @returns {import("../../../../../types").RevalidationResult}
     */
    checkFracture(){
      /** @type {import("../../../../../types").Period} */
      const period = this.$store.getters.getServicePeriods.find(period => period.id === this.row.id);
      /** @type {import("../../../../../types").Fracture} */
      const fracture = period.fracture;

      if(!fracture)
        return { valid: true, reason: ""};  

      const counter = fracture.counter;
      const denominator = fracture.denominator;

      console.log("DynamicRow | checkFracture", counter > denominator);

      if(counter > denominator){
        this.setError("fraction", "CounterDenomError", "Der Zähler darf nicht größer als der Nenner sein.");
        return { valid: false, reason: "Der Zähler darf nicht größer dem Nenner sein." }
      }

      return { valid: true, reason: ""};
    }
  },
  computed: {   
    hasIntersection(){
      console.log("DynamicRow | hasIntersection", !!this.intersections?.find(intersection => intersection.periodA === this.row.id || intersection.periodB === this.row.id))
      return !!this.intersections?.find(intersection => intersection.periodA === this.row.id || intersection.periodB === this.row.id);
    },
    intersection(){
      const intersectionObj = this.intersections?.find(intersection => {
        return intersection.periodA === this.row.id || intersection.periodB === this.row.id
      });

      let intersectionId;
      if(intersectionObj.periodA !== this.row.id){
        intersectionId =  intersectionObj.periodA;
      } else {
        intersectionId = intersectionObj.periodB
      }

      const idx = this.$store.getters.getServicePeriods.find(period => period.id === intersectionId)?.idx + 1;

      if(!intersectionObj || !idx) return "Überschneidung mit Zeile (Fehler bei Ermittlung der Zeile)";

      return "Diese Zeile überschneidet sich mit der Zeile " + idx;
    },
    /**
     * True Return => no Error
     * @returns {Boolean}
     */
    hasError(){
      return this.error.from.length > 0 || this.error.to.length > 0 || this.error.period.length > 0 || this.error.fraction.length > 0
          || this.error.eligible.length > 0 || this.missingFields !== null;
    },
    periodRow() {
      const periods = this.$store.getters.getServicePeriods;
      return periods.find(period => period.id === this.row.id);
    },
    initEmployment() {
      /** @type {import("../../../../../types").Period[]} */
      const periods = this.$store.getters.getServicePeriods;
      
      /** @type {import("../../../../../types").Period} */
      const period = periods.find(period => period.id === this.row.id);
      return period?.employment?.text ? period.employment.text : "";
    },
    initPeriodFrom() {
      const periods = this.$store.getters.getServicePeriods;
      const periodRow = periods.find(period => period.id === this.row.id);
      if (periodRow?.workPeriod?.from) {
        const birthdate = new Date(periodRow.workPeriod.from);
        const day = birthdate.getDate().toString();
        const month = (birthdate.getMonth() + 1).toString();
        const year = birthdate.getFullYear().toString();
        return [day, month, year];
      } else {
        return [];
      }
    },
    initPeriodTo() {
      const periods = this.$store.getters.getServicePeriods;
      const periodRow = periods.find(period => period.id === this.row.id);
      if (periodRow?.workPeriod?.to) {
        const birthdate = new Date(periodRow.workPeriod.to);
        const day = birthdate.getDate().toString();
        const month = (birthdate.getMonth() + 1).toString();
        const year = birthdate.getFullYear().toString();
        return [day, month, year];
      } else {
        return [];
      }
    },
    initFractionCounter() {
      const periodRow = this.periodRow;
      if (periodRow?.fracture?.counter) {
        const counterNum = periodRow?.fracture?.counter;
        return counterNum < 10 ? "0" + counterNum : counterNum;
      }
      return "";
    },
    initFractionDenom() {
      const periodRow = this.periodRow;
      if (periodRow?.fracture?.denominator) {
        const denomNum = periodRow?.fracture?.denominator;
        return denomNum < 10 ? "0" + denomNum : denomNum;
      }
      return "";
    },
    initEligibleYears() {
      const periodRow = this.periodRow;
      if (periodRow?.eligible?.years) {
        return periodRow?.eligible?.years;
      }
      return "";
    },
    initEligibleDays() {
      const periodRow = this.periodRow;
      if (periodRow?.eligible?.days) {
        return periodRow?.eligible?.days;
      }
      return "";
    },
    initialFocus() {
      if (this.idx === 0) return true;
      if (this.focused === this.row.id) return true;
      else return false;
    },
  },
  mounted() {
    /** @type {import("@/types").Period[]} */
    const periods = this.$store.getters.getServicePeriods;
    if(process.env.NODE_ENV === "development"){
      console.log("DynamicRow | Mounted | Periods: ", periods);
    }
    periods.forEach(period => {
      if(this.row.id === period.id && period.employment){
        this.isFractureDisabled({title: period.employment.text});
      }
    })

    this.$store.dispatch("updatePeriod", {type: "idx", rowId: this.row.id, idx: this.idx})

    this.revalidateBus.add(`row-${this.row.id}`, this.checkForMissingFields);
    this.revalidateBus.add(`row-${this.row.id}-getErr`, this.getError);
    this.revalidateBus.add(`row-${this.row.id}-checkPlausi`, this.checkPlausi);
    this.revalidateBus.add(`row-${this.row.id}-lasEndDate`, this.checkLastEndDate);
    this.revalidateBus.add(`row-${this.row.id}-checkifBothEligSet`, this.checkIfBothEligibleFieldsSet);
    this.revalidateBus.add(`row-${this.row.id}-mandatoryEligible`, this.checkMandatoryEligible);
    this.revalidateBus.add(`row-${this.row.id}-checkFractureCounterDenom`, this.checkFracture);
  },
  unmounted() {
    this.revalidateBus.remove(`row-${this.row.id}`);
    this.revalidateBus.remove(`row-${this.row.id}-getErr`);
    this.revalidateBus.remove(`row-${this.row.id}-checkPlausi`);
    this.revalidateBus.remove(`row-${this.row.id}-lasEndDate`);
    this.revalidateBus.remove(`row-${this.row.id}-checkifBothEligSet`);
    this.revalidateBus.remove(`row-${this.row.id}-mandatoryEligible`);
    this.revalidateBus.remove(`row-${this.row.id}-checkFractureCounterDenom`);
  }
};
</script>

<style scoped>
td {
  @apply border-l-2;
  @apply border-r-2;
}

svg:focus {
  outline: none;
}
.delete-container:focus,
.delete-container:hover {
  background-color: #eceded;
  border-radius: 40%;
}

.delete-container:hover {
  cursor: pointer;
}

.invalid-period {
  border: 2px solid red;
  border-radius: 10px;
  padding: 0.15rem;
  margin: 0.1rem;
}

.test {
  border: 2px solid red;
  border-radius: 10px;
  padding: 0.15rem;
  margin: 0.1rem;
}

td {
  @apply border-none;
}
</style>
