<template>
<form class="p-2 my-4 overflow-x-auto md:mx-20 xl:mx-64 2xl:mx-96 bg-blue-600 grid gap-2 auto-cols-max xl:auto-cols-max grid-flow-row rounded-sm flex justify-start items-center" @submit.prevent>
  <label for="rghsatz">Ruhegehaltssatz (aus Versorgungsauskunft):</label>
  <div>
    <input class="p-1 rounded-sm text-center" :class="{'input-error': error.rghsatzErr}" :aria-invalid="error.rghsatzErr" type="text" name="rghsatz" id="rghsatz" v-model="rghsatz" @input="checkRghstz">
    <span class="ml-1 text-large-strong">%</span>
  </div>

  <label for="versab">Versorgungsabschlag (aus Versorgungsauskunft):</label>
  <div>
    <input class="p-1 rounded-sm text-center" :class="{'input-error': error.versabschlErr}" :aria-invalid="error.versabschlErr" type="text" name="versab" id="versab" v-model="versabschl" @input="checkVersab">
    <span class="ml-1 text-large-strong">%</span>
  </div>

  <label for="rdbz">Ruhegehaltf&auml;hige Dienstbez&uuml;ge brutto:</label>
  <div>
    <input class="w-80 p-1 rounded-sm text-center" :class="{'input-error': error.ruhedbzErr}" :aria-invalid="error.ruhedbzErr" type="text" name="rdbz" id="rdbz" v-model="ruhedbz" @input="checkRuhedbz">
    <span class="ml-1 text-large-strong">€</span>
  </div>

  <label for="absfakt">Absenkungsfaktor f&uuml;r Besoldungsgruppe:</label>
  <div>
    <select class="p-1 rounded-sm" name="absfakt" id="absfakt" v-model="absfakt" @change="hideResult">
      <option value="1">A5-A6 | 1,00000</option>
      <option value="0.99518">A7-A8 | 0,99518</option>
      <option value="0.99349">Alle übrigen Besoldungsgruppen | 0,99349</option>
    </select>
  </div>
  <div class="flex flex-col place-content-start xl:col-span-2" v-if="hasError">
    <span class="text-red-500 text-large-large" v-if="error.rghsatzErr">{{ error.rghsatzErr }}</span>
    <span class="text-red-500 text-large-large" v-if="error.versabschlErr">{{ error.versabschlErr }}</span>
    <span class="text-red-500 text-large-large" v-if="error.ruhedbzErr">{{ error.ruhedbzErr }}</span>
  </div>
  <div class="mt-2 flex justify-end items-center xl:col-span-2">
    <button class="btn-primary text-large text-white" @click="calculate">Berechnen</button>
  </div>
    <div class="mt-4 grid col-span-2 gap-2 grid-cols-2 grid-flow-row" v-if="showCalcResult">
      <div>Ruhegehaltf&auml;hige Dienstbez&uuml;ge</div>
      <div class="col-start-2">{{ruhedbzComma}} EUR</div>
      <div class="col-span-2 h-0.5 bg-cc-border-color rounded-xl"></div>
      <div>Absenkungsfaktor</div>
      <div>{{absfaktComma}}</div>
      <div class="col-span-2 h-0.5 bg-cc-border-color rounded-xl"></div>
      <div>Abgesenkte Dienstbezüge</div>
      <div>{{abgesDienstbezuegeStr}} EUR</div>
      <div class="col-span-2 h-0.5 bg-cc-border-color rounded-xl"></div>
      <div>Ruhegehaltssatz</div>
      <div>{{rghsatz}} %</div>
      <div class="col-span-2 h-0.5 bg-cc-border-color rounded-xl"></div>
      <div>Versorgungsbezüge brutto</div>
      <div>{{bezBruttoStr}} EUR</div>
      <div class="col-span-2 h-0.5 bg-cc-border-color rounded-xl"></div>
      <div>davon {{versabschl}}% Abschlag</div>
      <div>{{abschlagInEurStr}} EUR</div>
      <div class="col-span-2 h-0.5 bg-cc-border-color rounded-xl"></div>
      <div>geminderte Versorgungsbezüge brutto</div>
      <div class="decoration-double" style="font-weight: bold">{{gemindBezBruttoStr}} EUR</div>
    </div>
</form>
</template>

<script>
import {containsUnwantedLetter, convertStrToCommaStr, strToNum} from "@/helper/helper"

export default {
  name: "PensionCalculation",
  data(){
    return {
      checkRegex: new RegExp(/[^0-9,]/, 'g'),
      showCalcResult: false,
      error: {
        rghsatzErr: "",
        versabschlErr: "",
        ruhedbzErr: ""
      },
      rghsatz: "",
      versabschl: "",
      ruhedbz: "",
      absfakt: "1",
      abgesDienstbezuege: 0,
      bezBrutto: 0,
      abschlagInEur: 0,
      gemindBezBrutto: 0,
    }
  },
  computed: {
    absfaktComma(){
      if(!this.absfakt.includes(".") && !this.absfakt.includes(","))
        return this.absfakt;

      return this.absfakt.replace(".", ",");
    },
    ruhedbzComma(){
      const commaNum = strToNum(this.ruhedbz).toFixed(2);
      return commaNum.replace(".", ",");
    },
    rghsatzNum(){
      return strToNum(this.rghsatz);
    },
    versabschlNum(){
      return strToNum(this.versabschl);
    },
    rdbzNum(){
      return strToNum(this.ruhedbz);
    },
    abgesDienstbezuegeStr(){
      const abgesDienstbezuegeRounded = this.abgesDienstbezuege.toFixed(2);
      return convertStrToCommaStr(abgesDienstbezuegeRounded);
    },
    bezBruttoStr(){
      const bezBruttoRounded = this.bezBrutto.toFixed(2);
      return convertStrToCommaStr(bezBruttoRounded);
    },
    abschlagInEurStr(){
      const abschlInEurRounded = this.abschlagInEur.toFixed(2);
      return convertStrToCommaStr(abschlInEurRounded);
    },
    gemindBezBruttoStr(){
      const gemindBezBruttoRounded = this.gemindBezBrutto.toFixed(2);
      return convertStrToCommaStr(gemindBezBruttoRounded);
    },
    hasError(){
      return !!(this.error.versabschlErr || this.error.rghsatzErr || this.error.ruhedbzErr);
    }
  },
  methods: {
    calculate(){
      if(!this.versabschl){
        this.error.rghsatzErr = "Für eine Berechnung muss der Ruhegehaltssatz gefüllt sein!";
      }
      if(!this.rghsatz){
        this.error.versabschlErr = "Für eine Berechnung muss der Versorgungsabschlag gefüllt sein!";
      }
      if(!this.ruhedbz){
        this.error.ruhedbzErr = "Für eine Berechnung müssen Ruhegehaltfähige Dienstbezüge brutto angegeben sein!";
      }

      if(this.hasError) return;

      this.abgesDienstbezuege = this.rdbzNum * this.absfakt;
      this.bezBrutto = this.abgesDienstbezuege * this.rghsatzNum / 100;
      this.abschlagInEur = this.bezBrutto * this.versabschlNum / 100;
      this.gemindBezBrutto = this.bezBrutto - this.abschlagInEur;

      this.showCalcResult = true;
    },
    initComp(){
      this.rghsatz = localStorage.getItem("Ruhegehaltssatz");
      this.versabschl = localStorage.getItem("Minderung");
    },
    checkRghstz(){
      this.hideResult();
      this.error.rghsatzErr = "";
      if(containsUnwantedLetter(this.rghsatz)){
        this.rghsatz = this.rghsatz.replaceAll(this.checkRegex, "");
      }

      if(strToNum(this.rghsatz) > 100){
        this.error.rghsatzErr = "Der Ruhegehaltssatz darf 100% nicht übersteigen!";
      }
    },
    checkVersab(){
      this.hideResult();
      this.error.versabschlErr = false;
      if(containsUnwantedLetter(this.versabschl)){
        this.versabschl = this.versabschl.replaceAll(this.checkRegex, "");
      }

      if(strToNum(this.versabschl) > 100){
        this.error.versabschlErr = "Der Versorgungsabschlag darf 100% nicht übersteigen!";
      }
    },
    checkRuhedbz(){
      this.hideResult();
      this.error.ruhedbzErr = false;
      if(containsUnwantedLetter(this.ruhedbz)){
        this.ruhedbz = this.ruhedbz.replaceAll(this.checkRegex, "");
      }
    },
    hideResult(){
      this.showCalcResult = false;
    },
  },
  mounted() {
    this.initComp();
  }
}
</script>

<style scoped>
.bg-blue-600{
  background: #E6F0FA;
}
input {
  border: 1px solid #acacac;
  box-sizing: border-box;
  border-radius: 4px;
  width: 95%;
  height: 40px;

  text-align: right;
}

.input-error {
  border: 2px solid #E30613;
}

select{
  border: 1px solid #acacac;
  border-radius: 4px;
}

.font-bold-cc{
  font-weight: bolder;
}

.decoration-double{
  text-decoration-style: double;
}
</style>