<template>
  <svg v-if="colorType === 'white'" class="fill-current text-white stroke-current w-7 h-7" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12.1151 7.67115C11.8103 7.65007 11.5056 7.71319 11.2342 7.85361C10.9628 7.99403 10.7353 8.20634 10.5765 8.46734C10.4176 8.72834 10.3336 9.02798 10.3335 9.33352C10.3334 9.88581 9.88559 10.3334 9.33331 10.3333C8.78102 10.3332 8.33339 9.88543 8.3335 9.33314C8.33362 8.66096 8.51853 8.00174 8.868 7.42754C9.21747 6.85334 9.71806 6.38625 10.3151 6.07733C10.9121 5.76841 11.5825 5.62954 12.2531 5.67591C12.9237 5.72227 13.5686 5.95208 14.1174 6.34022C14.6662 6.72836 15.0977 7.2599 15.3649 7.87673C15.632 8.49356 15.7244 9.17195 15.632 9.83776C15.5397 10.5036 15.266 11.1312 14.8411 11.652C14.4161 12.1728 13.8561 12.5667 13.2224 12.7908C13.1574 12.8138 13.1011 12.8564 13.0613 12.9127C13.0215 12.969 13.0001 13.0363 13.0002 13.1053V13.5C13.0002 14.0523 12.5524 14.5 12.0002 14.5C11.4479 14.5 11.0002 14.0523 11.0002 13.5L11.0002 13.1063C11 12.6238 11.1495 12.1525 11.428 11.7584C11.7066 11.3642 12.1006 11.0661 12.5557 10.9052C12.8438 10.8033 13.0983 10.6243 13.2915 10.3875C13.4846 10.1508 13.609 9.86554 13.651 9.5629C13.693 9.26026 13.651 8.9519 13.5296 8.67152C13.4082 8.39114 13.212 8.14954 12.9625 7.97311C12.7131 7.79668 12.4199 7.69222 12.1151 7.67115ZM11.2591 15.8914C11.4783 15.7449 11.7361 15.6667 11.9998 15.6667C12.3535 15.6667 12.6926 15.8071 12.9426 16.0572C13.1927 16.3072 13.3332 16.6464 13.3332 17C13.3332 17.2637 13.255 17.5215 13.1085 17.7408C12.962 17.96 12.7537 18.1309 12.5101 18.2318C12.2664 18.3327 11.9984 18.3591 11.7397 18.3077C11.4811 18.2563 11.2435 18.1293 11.057 17.9428C10.8706 17.7563 10.7436 17.5187 10.6921 17.2601C10.6407 17.0015 10.6671 16.7334 10.768 16.4897C10.8689 16.2461 11.0398 16.0379 11.2591 15.8914Z" fill="#fffff"/>
  </svg>
  <svg v-else-if="colorType === 'black'" class="stroke-current fill-current text-black stroke-current w-7 h-7" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM12.1151 7.67115C11.8103 7.65007 11.5056 7.71319 11.2342 7.85361C10.9628 7.99403 10.7353 8.20634 10.5765 8.46734C10.4176 8.72834 10.3336 9.02798 10.3335 9.33352C10.3334 9.88581 9.88559 10.3334 9.33331 10.3333C8.78102 10.3332 8.33339 9.88543 8.3335 9.33314C8.33362 8.66096 8.51853 8.00174 8.868 7.42754C9.21747 6.85334 9.71806 6.38625 10.3151 6.07733C10.9121 5.76841 11.5825 5.62954 12.2531 5.67591C12.9237 5.72227 13.5686 5.95208 14.1174 6.34022C14.6662 6.72836 15.0977 7.2599 15.3649 7.87673C15.632 8.49356 15.7244 9.17195 15.632 9.83776C15.5397 10.5036 15.266 11.1312 14.8411 11.652C14.4161 12.1728 13.8561 12.5667 13.2224 12.7908C13.1574 12.8138 13.1011 12.8564 13.0613 12.9127C13.0215 12.969 13.0001 13.0363 13.0002 13.1053V13.5C13.0002 14.0523 12.5524 14.5 12.0002 14.5C11.4479 14.5 11.0002 14.0523 11.0002 13.5L11.0002 13.1063C11 12.6238 11.1495 12.1525 11.428 11.7584C11.7066 11.3642 12.1006 11.0661 12.5557 10.9052C12.8438 10.8033 13.0983 10.6243 13.2915 10.3875C13.4846 10.1508 13.609 9.86554 13.651 9.5629C13.693 9.26026 13.651 8.9519 13.5296 8.67152C13.4082 8.39114 13.212 8.14954 12.9625 7.97311C12.7131 7.79668 12.4199 7.69222 12.1151 7.67115ZM11.2591 15.8914C11.4783 15.7449 11.7361 15.6667 11.9998 15.6667C12.3535 15.6667 12.6926 15.8071 12.9426 16.0572C13.1927 16.3072 13.3332 16.6464 13.3332 17C13.3332 17.2637 13.255 17.5215 13.1085 17.7408C12.962 17.96 12.7537 18.1309 12.5101 18.2318C12.2664 18.3327 11.9984 18.3591 11.7397 18.3077C11.4811 18.2563 11.2435 18.1293 11.057 17.9428C10.8706 17.7563 10.7436 17.5187 10.6921 17.2601C10.6407 17.0015 10.6671 16.7334 10.768 16.4897C10.8689 16.2461 11.0398 16.0379 11.2591 15.8914Z" fill="#141313"/>
  </svg>

</template>

<script>
export default {
  name: "CircledQuestionMark",
  props:{
    colorType: String
  }
}
</script>

<style scoped>

</style>