<template>
  <div class="m-8 flex justify-start grid grid-flow-row xl:grid-flow-col gap-12 items-start">
    <SideNav role="navigation" />
    <div role="main">
      <router-view />
    </div>
  </div>

<!--  <div class="grid-container">-->
<!--    <SideNav role="navigation" class="side-nav" />-->
<!--    <div role="main" class="main-content">-->
<!--      <router-view />-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import SideNav from "../components/SideNav";

export default {
  components: {
    SideNav
  }
};
</script>

<style scoped>
/*.side-nav {*/
/*  !* border: 2px solid black; *!*/
/*  grid-area: side-nav;*/
/*}*/
/*.main-content {*/
/*  !* border: 2px solid black; *!*/
/*  grid-area: main-content;*/
/*}*/

/*.grid-container {*/
/*  margin: 2rem;*/
/*  display: grid;*/
/*  grid-template-columns: 15% 83%;*/
/*  grid-template-rows: auto;*/
/*  grid-template-areas: "side-nav main-content";*/

/*  column-gap: 3rem;*/

/*  align-items: flex-start;*/
/*}*/

/*@media screen and (max-width: 600px) {*/
/*  .grid-container {*/
/*    grid-template-columns: auto;*/
/*    grid-template-areas: "side-nav" "main-content";*/
/*  }*/
/*}*/
</style>
