<template>
  <div class="flex flex-row w-full">
    <base-help-modal
      v-if="showHelp"
      :is-hidden="!showHelp"
      @close-modal="closeModal"
    >
      <TheHelpAgeLimit v-if="helpModal === 'begin'" id="slot" />
      <TheHelpGroup v-if="helpModal === 'group'" id="slot" />
      <div v-if="helpModal === 'reason'" id="slot">
        <h3 class="headline-medium mb-5">Grund der Zurruhesetzung</h3>
        <p class="text-large">Der Grund der Zurruhesetzung ist wichtig für die korrekte Berechnung der Versorgungsbezüge. Durch einen Ruhestandsbeginn vor der regulären Altersgrenze können die Versorgungsbezüge durch einen Abschlag vermindert sein.</p>
      </div>
    </base-help-modal>
    <h1 class="headline-large spce-between" data-test="GeneralPage-headline">
      Allgemeine Angaben
    </h1>

    <InputComponent
      comp-Key="birthdate"
      class="container-flex-column spce-between"
      title="Wann sind Sie geboren?"
      elementType="birthdate"
      containedPage="GENERAL"
      :placeholder="['TT', 'MM', 'JJJJ']"
      :disabledInpts="[]"
      :init-values="initBirthdate"
      :tab-indexe="[1, 2, 3]"
      :show-help-icon="false"
      :initial-focus="true"
      :reset-ctx="resetCtx"
      @err-state-change="onErrChange"
      @show-help="showHelpModal"
      @date-constructed="setBirthdate"
    />

    <BoxComponent
      comp-id="boxC1"
      class="container-flex-column spce-between"
      title="Was ist der Grund Ihrer Zurruhesetzung?"
      hilfe-label="Hilfe für den Grund der Zurruhesetzung"
      :boxes="reasonBoxes"
      :tab-indexe="[4, 5, 6, 7, 8]"
      @select-box="selectReason"
      @show-help="showHelpModal('reason')"
    />
    
    <BoxComponent
      comp-id="boxC2"
      class="container-flex-column spce-between"
      title="Es gelten die Vorschriften für"
      :boxes="ruleBoxes"
      :tab-indexe="[12, 13, 14, 15, 16, 17, 18, 19]"
      @select-box="selectRule"
      @show-help="showHelpModal('group')"
    />

    <InputComponent
      comp-Key="supplyBegin"
      class="container-flex-column spce-between"
      title="Voraussichtlicher Versorgungsbeginn"
      hilfe-label="Hilfe Voraussichtlicher Versorgungsbeginn Eingabe"
      hint="Eingabe Tag bereits mit 01 vorausgewählt"
      elementType="supplyBegin"
      containedPage="GENERAL"
      :placeholder="['01', 'MM', 'JJJJ']"
      :disabledInpts="[]"
      :init-values="initBegin"
      :tab-indexe="[9, 10, 11]"
      :show-help-icon="true"
      :reset-ctx="resetCtx"
      @err-state-change="onErrChange"
      @show-help="showHelpModal('begin')"
      @date-constructed="setBegin"
    />


<!--    <section v-if="hasError || !ruleSelected || !reasonSelected">-->
    <section v-if="inputErrs.birthdate || inputErrs.supplyBegin || !ruleSelected || !reasonSelected">
      <p class="text-medium-medium invalid-text" v-if="inputErrs.birthdate">
        Es gibt noch Fehler für das Geburtsdatum.
      </p>
      <p class="text-medium-medium invalid-text" v-if="inputErrs.supplyBegin">
        Es gibt noch Fehler für das Startdatum der Versorgung.
      </p>
    </section>
    <button class="btn-primary text-large text-white" data-test="GeneralPage-button" @click="nextPage" :disabled="hasError || !ruleSelected || !reasonSelected || !supplyStartSel || !birthDateSel">
      Weiter zu Zeiträume
    </button>
  </div>
</template>

<script>
import InputComponent from "./GeneralFormComponents/InputComponent";
import BoxComponent from "./GeneralFormComponents/BoxComponent";
import BaseHelpModal from "../Help/BaseHelpModal";
// import TheHelpRules from "../Help/TheHelpRules";
// import BaseHelp from "../Help/BaseHelp";
import TheHelpAgeLimit from "../Help/TheHelpAgeLimit";
import TheHelpGroup from "../Help/TheHelpGroup";
import mitt from "mitt";
export default {
  components: {
    InputComponent,
    BoxComponent,
    BaseHelpModal,
    // BaseHelp,
    TheHelpAgeLimit,
    TheHelpGroup
  },
  data() {
    return {
      inputErrs: {supplyBegin: false, birthdate: false},
      showHelp: false,
      helpModal: "",
      inputBirthdaySetup: [
        {
          name: "birthday-input-day",
          placeholder: "TT",
          value: "",
          isActive: true
        },
        {
          name: "birthday-input-month",
          placeholder: "MM",
          value: "",
          isActive: true
        },
        {
          name: "birthday-input-year",
          placeholder: "YYYY",
          value: "",
          isActive: true
        }
      ],
      inputBeginSetup: [
        {
          name: "begin-input-day",
          placeholder: "01",
          isActive: false
        },
        {
          name: "begin-input-month",
          placeholder: "MM",
          isActive: true
        },
        {
          name: "begin-input-year",
          placeholder: "YYYY",
          isActive: true
        }
      ],
      selectBoxTexts: [
        "Erreichen der Altersgrenze",
        "Auf Antrag",
        "Dienstunfähigkeit",
        "Schwerbehinderung",
        "Einstweiliger Ruhestand"
      ],
      rowCnt: 0,
      resetCtx: mitt()
    };
  },
  computed: {
    screenWD(){
      return screen.width
    },
    hasError() {
      return this.$store.getters.hasGeneralPageErrs;
    },
    birthdateErr() {
      return this.$store.getters.getGeneralPageErrs.find(err => err.elem === "birthdate");
    },
    birthDateSel() {
      return this.$store.getters.getBirthdate;
    },
    supplyStartErr(){
      return this.$store.getters.getGeneralPageErrs.find(err => err.elem === "supplyBegin");
    },
    supplyStartSel() {
      return this.$store.getters.getBegin;
    },
    ruleSelected() {
      return this.$store.getters.getRules.find(rule => rule.isSelected === true);
    },
    reasonSelected() {
      return this.$store.getters.getReasons.find(rule => rule.isSelected === true);
    },
    reasonBoxes() {
      return this.$store.getters.getReasons;
    },
    ruleBoxes() {
      return this.$store.getters.getRules;
    },
    initBirthdate() {
      const isDefined = this.$store.getters.getBirthdate;
      if (isDefined) {
        const birthdate = new Date(this.$store.getters.getBirthdate);
        const day = birthdate.getDate().toString();
        const month = (birthdate.getMonth() + 1).toString();
        const year = birthdate.getFullYear().toString();
        return [day, month, year];
      } else {
        return [];
      }
    },
    initBegin() {
      const isDefined = this.$store.getters.getBegin;
      if (isDefined) {
        const begin = new Date(this.$store.getters.getBegin);
        const day = "01";
        const month = (begin.getMonth() + 1).toString();
        const year = begin.getFullYear().toString();
        return [day, month, year];
      } else {
        return ["01"];
      }
    }
  },
  methods: {
    resetErr(){
      this.store.dispatch("resetGeneralPageErr");
      this.resetCtx.emit("revalidate");
    },
    onErrChange({ hasErr, component }){
      switch (component){
        case "birthdate":
          this.inputErrs.birthdate = hasErr;
          break;
        case "supplyBegin":
          this.inputErrs.birthdate = hasErr;
          break;
        default:
          break;
      }
    },
    selectReason(box) {
      this.$store.dispatch({
        type: "selectReason",
        value: box
      });
    },
    selectRule(box) {
      this.$store.dispatch({
        type: "selectRule",
        value: box
      });
    },
    setBirthdate(date) {
      this.$store.dispatch({
        type: "setBirthdate",
      timestamp: isNaN(date.getTime()) ? null: date.getTime()
      });
    },
    setBegin(date) {
      this.$store.dispatch({
        type: "setBegin",
        timestamp: isNaN(date.getTime()) ? null: date.getTime()
      });
    },
    nextPage() {
      this.$store.dispatch("validateGeneral", isValid => {
        if(isValid) this.$router.push("/timeline");
      });
    },
    showHelpModal(selection) {
      this.helpModal = selection;
      this.showHelp = true;
      document.getElementById("help-modal")?.focus();
    },
    closeModal() {
      this.showHelp = false;
    }
  },
  watch: {
    showHelp(){
      const body = document.body;
      if(!this.showHelp){
        body.classList.remove("overflow-hidden")
        return;
      }
      body.classList.add("overflow-hidden");
    }
  },
  mounted() {
    this.resetCtx.on("reset", this.resetErr);
  }
};
</script>

<style>
.container-flex-row {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.container-flex-column {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
}

.row-item {
  margin-right: 0.5rem;
}

.column-item {
  margin-bottom: 1rem;
}

.grid-item {
  grid-column-start: 1;
}

.spce-between {
  margin-bottom: 4rem;
}

.help-icon {
  border: 3px solid #2c2e35;
  border-radius: 50%;
  width: 25px;
  height: 25px;

  cursor: pointer;
}

.text-centered {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
</style>
