<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8334 6.16671H7.83337V1.16671C7.83337 0.705874 7.46004 0.333374 7.00004 0.333374C6.54004 0.333374 6.16671 0.705874 6.16671 1.16671V6.16671H1.16671C0.706707 6.16671 0.333374 6.53921 0.333374 7.00004C0.333374 7.46087 0.706707 7.83337 1.16671 7.83337H6.16671V12.8334C6.16671 13.2942 6.54004 13.6667 7.00004 13.6667C7.46004 13.6667 7.83337 13.2942 7.83337 12.8334V7.83337H12.8334C13.2934 7.83337 13.6667 7.46087 13.6667 7.00004C13.6667 6.53921 13.2934 6.16671 12.8334 6.16671Z"
      fill="#125797"
    />
  </svg>
</template>
