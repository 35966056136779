/**
 * Diese Methode gibt für die Regel der Zurruhesetzung
 * den beschreiben Buchstaben zu Weiterverabreitung zurück.
 * @param {String} reasonTxt
 * @returns beschreibender Buchstabe oder
 * einen leereren String wenn keine Zuordnung existiert.
 * @note Es gibt ein Dupplikat dieser Funktion in helper/helper.js, da diese Funktion hier teilweise nicht über import xxx erreicht werden kann.
 */
export function getRuleFromString(ruleTxt) {
  switch (ruleTxt) {
    case "Verwaltungsdienst":
      return "A";
    case "Lehrkräfte":
      return "L";
    case "Hochschullehrer": //FIXME !!! Aus dem COBOL Code ist nicht ersichtlich auf welchen Buchstaben Hochschullehrer abgebildet wird. FRAGEN !!!!
      return "H";
    case "Polizeivollzugsdienst":
      return "P";
    case "Polizeivollzugsd. mit mind. 25 Jahren Wechselschicht":
      return "W";
    case "Feuerwehr im Einsatzdienst":
      return "F";
    case "Justizvollzugsdienst":
      return "J";
    case "Techn. Aufsichtsbeamte in untertägigen Bergwerksbetrieben":
      return "U";
    default:
      console.log("KeyMapping | getRuleFromString | Unknown: " + ruleTxt);
      return "";
  }
}

/**
 * Diese Methode gibt für den Beschreibungstext des Grundes der Zurruhesetzung
 * den beschreiben Buchstaben zu Weiterverabreitung zurück.
 * @param reasonTxt => Beschreibungstext des Grundes der Zurruhesetzung
 * @return beschreibender Buchstabe<br>
 * ein leerer String wenn keine Zuordnung existiert.
 * @note Es gibt ein Dupplikat dieser Funktion in helper/helper.js, da diese Funktion hier teilweise nicht über import xxx erreicht werden kann.
 */
export function getReasonFromString(reasonTxt) {
  switch (reasonTxt) {
    case "Erreichen der Altersgrenze":
      return "N";
    case "Auf Antrag":
      return "A";
    case "Dienstunfähigkeit":
      return "D";
    case "Schwerbehinderung":
      return "S";
    case "Einstweiliger Ruhestand":
      return "E";
    default:
      console.log("KeyMapping | getReasonFromString | Unknown: " + reasonTxt);
      return "";
  }
}
