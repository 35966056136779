<template>
  <div>
    <h1 class="headline-large">Gesetzliche Altersgrenzen</h1>
    <section>
      <h2 class="headline-medium">
        Beamtinnen und Beamte treten in der Regel mit Ablauf des Monats in den
        Ruhestand, in dem sie die gesetzliche Altersgrenze erreichen.
      </h2>
      <p>
        Die maßgebliche Altersgrenze ergibt sich aus der folgenden Tabelle:
      </p>
      <table>
        <thead>
          <th>Jahrgang</th>
          <th>Anhebung um Monate</th>
          <th>Altersgrenze Jahre + Monate</th>
        </thead>
        <tbody>
          <tr>
            <td>1951</td>
            <td>5</td>
            <td>65 + 5</td>
          </tr>
          <tr>
            <td>1952</td>
            <td>6</td>
            <td>65 + 6</td>
          </tr>
          <tr>
            <td>1953</td>
            <td>7</td>
            <td>65 + 7</td>
          </tr>
          <tr>
            <td>1954</td>
            <td>8</td>
            <td>65 + 8</td>
          </tr>
          <tr>
            <td>1955</td>
            <td>9</td>
            <td>65 + 9</td>
          </tr>
          <tr>
            <td>1956</td>
            <td>10</td>
            <td>65 + 10</td>
          </tr>
          <tr>
            <td>1957</td>
            <td>11</td>
            <td>65 + 11</td>
          </tr>
          <tr>
            <td>1958</td>
            <td>12</td>
            <td>66</td>
          </tr>
          <tr>
            <td>1959</td>
            <td>14</td>
            <td>66 + 2</td>
          </tr>
          <tr>
            <td>1960</td>
            <td>16</td>
            <td>66 + 4</td>
          </tr>
          <tr>
            <td>1961</td>
            <td>18</td>
            <td>66 + 6</td>
          </tr>
          <tr>
            <td>1962</td>
            <td>20</td>
            <td>66 + 8</td>
          </tr>
          <tr>
            <td>1963</td>
            <td>22</td>
            <td>66 + 10</td>
          </tr>
          <tr>
            <td>ab 1964</td>
            <td>24</td>
            <td>67</td>
          </tr>
        </tbody>
      </table>
      <span>
        Beispiel:<br />
        geboren am 15.08.1954<br />
        Eintritt in den Ruhestand mit Ablauf des 30.04.2020
      </span>
    </section>
    <section>
      <h2 class="headline-medium">Besondere Altersgrenzen</h2>
      <table>
        <tbody>
          <tr>
            <td class="Vtext-large-strong">Lehrkräfte</td>
            <td class="td-text-left">
              <p>
                treten mit Ablauf des Schulhalbjahres (31.01. bzw. 31.07.) in
                den Ruhestand, in dem sie die gesetzliche Altersgrenze
                erreichen. → siehe Tabelle oben
              </p>
              <div>
                <strong>Beispiel:</strong><br />
                <p>
                  geboren am 15.08.1954 Eintritt in den Ruhestand mit Ablauf des
                  31.07.2020
                </p>
                <p>
                  Wurde bis zum 01.01.2004 Altersteilzeit/Altersurlaub
                  angetreten, verbleibt es bei der bis 31.12.2003 maßgeblichen
                  Altersgrenze - Ablauf des Schuljahres in dem das 64.
                  Lebensjahr vollendet wird.
                </p>
                <p>
                  Wurde in der Zeit nach dem 31.12.2003 bis zum 01.04.2009
                  Altersteilzeit/Altersurlaub angetreten, verbleibt es bei der
                  bis 31.03.2009 maßgeblichen Altersgrenze - Ablauf des
                  Schulhalbjahres in dem das 65. Lebensjahr vollendet wird.
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td class="text-large-strong">Professorenschaft</td>
            <td class="td-text-left">
              <p>
                treten mit Ablauf der Vorlesungszeit in den Ruhestand, in der
                sie die gesetzliche Altersgrenze erreichen (28.02. bzw. 31.07.).
                → siehe Tabelle oben
              </p>
              <p>
                Fällt der Monat, in dem sie die gesetzliche Altersgrenze
                (Regelaltersgrenze) erreichen, in einen vorlesungsfreien Monat,
                treten sie mit Ablauf dieses Monats in den Ruhestand.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-large-strong">
              Beamtinnen und Beamte im Polizeivollzugsdienst
            </td>
            <td class="td-text-left">
              <p>
                treten mit Ablauf des Monats in den Ruhestand, in dem das 62.
                Lebensjahr vollendet wird.
              </p>
              <p>
                Bei 25 Dienstjahren im Wechselschichtdienst treten sie mit
                Ablauf des Monats in den Ruhestand, in dem das 61. Lebensjahr
                vollendet wird.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-large-strong">
              Beamtinnen und Beamte im Justizvollzugsdienst
            </td>
            <td class="td-text-left">
              <p>
                treten mit Ablauf des Monats in den Ruhestand, in dem das 62.
                Lebensjahr vollendet wird.
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-large-strong">
              Beamtinnen und Beamte des technischen Aufsichtsdienstes in
              untertägigen Bergwerksbetrieben
            </td>
            <td class="td-text-left">
              <p>
                treten mit Ablauf des Monats in den Ruhestand, in dem das 62.
                Lebensjahr vollendet wird (gültig seit 01.07.2016).
              </p>
            </td>
          </tr>
          <tr>
            <td class="text-large-strong">
              Beamtinnen und Beamte in den Feuerwehren
            </td>
            <td class="td-text-left">
              <p>
                treten mit Ablauf des Monats in den Ruhestand, in dem das 60.
                Lebensjahr vollendet wird.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

h1 {
  margin-top: 0px;
}

th,
td {
  border: 1px solid grey;
  padding: 5px;
  text-align: center;
}

.td-text-left {
  border: 1px solid grey;
  padding: 5px;
  text-align: left;
}
</style>
