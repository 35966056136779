<template>
  <div
    class="select-option"
    :class="{ focused: data.menu.focused }"
    :id="`${idPrefix}-search-${data.menu.id}`"
    v-for="data in filteredData"
    :key="data.menu.id"
    @click="itemClicked(data)"
  >
    <div class="option-margin-left flex flex-row justify-start items-start flex-nowrap break-words" role="option" :aria-selected="false">
      <span class="number mr-0.5">{{ data.menu.key }}</span>
      <span class="mx-0.5"> | </span>
      <span class="text-left ml-0.5">{{ data.menu.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["item-selected", "item-focused"],
  props: {
    inputString: String,
    data: Array,
    navContext: Object,
    idPrefix: String,
    selectedElem: String
  },
  computed: {
    filteredData() {
      const searchedData = [];
      this.traverse(this.data, null, (menu, group) => {
        const foundMenu = { menu, group };
        if (
          menu.key &&
          (menu.title.toLowerCase().includes(this.inputString.toLowerCase()) ||
            menu.key.includes(this.inputString))
        ) {
          searchedData.push(foundMenu);
        }
      });
      if(process.env.NODE_ENV === "development"){
        console.log("Searching: ", searchedData.length);
      }
      return searchedData;
    }
  },
  methods: {
    traverse(menus, group, callback) {
      menus.forEach(menu => {
        callback(menu, group);
        if (menu.subMenus && menu.subMenus.length > 0) {
          this.traverse(menu.subMenus, group ?? menu.title, callback);
        }
      });
    },
    itemClicked(data) {
      this.$emit("item-selected", { group: data.group, item: data.menu });
    },
    resetFocus() {
      this.traverse(this.data, null, menu => {
        menu.focused = false;
      });
    }
  },
  watch: {
    inputString() {
      this.resetFocus();
    }
  },
  created() {
    this.resetFocus();

    this.navContext.on("nav-up", () => {
      let newFocuedIndex = -1;
      const focusedIndex = this.filteredData.findIndex(
        elem => elem.menu.focused
      );

      if (focusedIndex > 0) {
        this.filteredData[focusedIndex - 1].menu.focused = true;
        this.filteredData[focusedIndex].menu.focused = false;
        newFocuedIndex = focusedIndex - 1;
      } else {
        this.filteredData[this.filteredData.length - 1].menu.focused = true;
        this.filteredData[focusedIndex].menu.focused = false;
        newFocuedIndex = this.filteredData.length - 1;
      }

      this.$emit("item-focused", this.filteredData[newFocuedIndex].menu.id);
    });

    this.navContext.on("nav-down", () => {
      let newFocuedIndex = -1;
      const focusedIndex = this.filteredData.findIndex(
        elem => elem.menu.focused
      );

      if (focusedIndex === -1 && this.filteredData[0]) {
        this.filteredData[0].menu.focused = true;
        newFocuedIndex = 0;
      } else if (
        focusedIndex >= 0 &&
        focusedIndex < this.filteredData.length - 1
      ) {
        this.filteredData[focusedIndex + 1].menu.focused = true;
        this.filteredData[focusedIndex].menu.focused = false;
        newFocuedIndex = focusedIndex + 1;
      } else {
        this.filteredData[focusedIndex].menu.focused = false;
        this.filteredData[0].menu.focused = true;
        newFocuedIndex = 0;
      }

      this.$emit("item-focused", this.filteredData[newFocuedIndex].menu.id);
    });

    this.navContext.on("nav-selected", () => {
      const focusedElem = this.filteredData.find(elem => elem.menu.focused);
      this.itemClicked(focusedElem);
    });

    this.navContext.on("nav-clear", () => {
      this.resetFocus();
    });
  },
  unmounted() {
    this.navContext.all.clear();
  }
};
</script>

<style scoped>
.select-option {
  border-top: 1px solid #acacac;
  width: inherit;
  height: inherit;
  padding: 10px 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.select-option:hover,
.focused {
  background: #f6f6f6;
}

.option-margin-right {
  margin-right: 1rem;
}

.option-margin-left {
  margin-left: 1rem;
}

.number {
  color: #2c5cfc;
}
</style>
