<template>
  <div>
    <h1 class="headline-large">
      Beispiele für die Nutzung des Berechnungsprogramms
    </h1>
    <h2 class="headline-medium">Beispiel 1:</h2>
    <section>
      <h4 class="headline-small">
        <u>Erster Arbeitsschritt:</u> Zusammenstellung des beruflichen
        Werdegangs
      </h4>
      <p>
        hier: Beamtin/Beamter geboren 24.01.1945<br />
        <br />
        01.10.1965 - 30.04.1970 Studien- und Prüfungszeit<br />
        01.08.1970 - 30.09.1972 Vorbereitungsdienst<br />
        01.01.1973 - 31.07.2000 Beamtendienstzeit (Vollbeschäftigung)<br />
        01.08.2000 - 31.01.2008 Altersteilzeit<br />
        Beabsichtigt ist die Versetzung in den Ruhestand auf eigenen Antrag mit
        Ablauf des 31.01.2008 (Monat der Vollendung des 63. Lebensjahres)<br />
      </p>
      <br />
      <h4 class="headline-small">
        <u>Zweiter Arbeitsschritt:</u> Nutzung des Schlüsselkatalogs
      </h4>
      <div>
        <span>Studien- und Prüfungszeit:</span>
        <ul>
          <li>Ausbildungszeiten</li>
          <li>Hochschul- und Fachhochschulausbildung</li>
          <li><strong>Schlüssel 1230</strong></li>
          <li>Mindeststudienzeit angeben</li>
        </ul>
      </div>
      <div>
        <span>Vorbereitungsdienst:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>Mit voller Stundenzahl einschl. Vorbereitungsdienst</li>
          <li><strong>Schlüssel 0602</strong></li>
        </ul>
      </div>
      <div>
        <span>Beamtendienstzeit:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>Mit voller Stundenzahl einschl. Vorbereitungsdienst</li>
          <li><strong>Schlüssel 0602</strong></li>
        </ul>
      </div>
      <div>
        <span>Altersteilzeit:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>Altersteilzeit im Block- oder Teilzeitmodell</li>
          <li><strong>Schlüssel 0616/0617</strong></li>
        </ul>
      </div>
      <br />
      <h4 class="headline-small"><u>Dritter Arbeitsschritt:</u> Eingaben</h4>
      <div>
        <div class="grid1">
          <span class="flex-container-start-center">Geburtsdatum:</span>
          <span class="flex-container-start-center">24.01.1945</span>
          <span class="flex-container-start-center"
            >Voraussichtlicher Versorgungsbeginn:</span
          >
          <span class="flex-container-start-center">01.02.2008</span>
          <span class="flex-container-start-center"
            >Grund der Zurruhesetzung:</span
          >
          <span class="flex-container-start-center">Auf Antrag</span>
          <span class="flex-container-start-center"
            >Es gelten die Vorschriften für:</span
          >
          <span class="flex-container-start-center"
            >Allg. Verwaltungsdienst</span
          >
        </div>
        <div class="grid2">
          <span class="flex-container-start-center"
            >01.10.196 - 30.04.1970</span
          >
          <span class="flex-container-start-center">1230</span>
          <span>2 125 *)</span>
          <span class="flex-container-start-center"
            >01.08.1970 - 30.09.1972</span
          >
          <span class="flex-container-start-center">0602</span>
          <span></span>
          <span class="flex-container-start-center"
            >01.01.1973 - 31.07.2000</span
          >
          <span class="flex-container-start-center">0602</span>
          <span></span>
          <span class="flex-container-start-center"
            >01.08.2000 - 31.01.2008</span
          >
          <span class="flex-container-start-center">0616</span>
          <span></span>
        </div>
      </div>
      <span class="text-small">
        *) Als "höchstens anrechenbar" ist die im Zeitpunkt des Ablegens der
        Prüfung vorgeschriebene Mindeststudien- und -prüfungszeit einzugeben.
        Wurde das für die Versorgung massgebende Beamtenverhältnis nach dem
        31.12.1991 begründet, entfällt diese Angabe.
      </span>
      <h4 class="headline-small">
        <u>Vierter Arbeitsschritt:</u> Ruhegehaltssatz berechnen
      </h4>
      <span>Feld "Berechnung starten" anklicken.</span>
    </section>
    <h2 class="headline-medium">Beispiel 2:</h2>
    <section>
      <h4 class="headline-small">
        <u>Zweiter Arbeitsschritt:</u> Nutzung des Schlüsselkatalogs
      </h4>
      <span>hier: Beamtin/Beamter geboren 15.07.1954</span>
      <div class="grid3">
        <span>01.08.1973 - 31.07.1976</span>
        <span>orbereitungsdienst</span>
        <span>Beamtendienstzeit (Vollbeschäftigung)</span>
        <span>am 05.06.1991</span>
        <span>bis 31.07.1991</span>
        <span>Mutterschutzfrist</span>
        <span>01.08.1991 - 04.11.1993</span>
        <span
          >Erziehungsurlaub bis zum vollendeten 18. Lebensmonats des
          Kindes</span
        >
        <span>05.11.1993 - 31.07.1994</span>
        <span>Beurlaubung (§ 85a LBG)</span>
        <span>01.08.1994 - 31.07.2000</span>
        <span
          >Teilzeitbeschäftigung (§ 85 a LBG ), ermäßigte Arbeitszeit mit 20
          Std./Woche</span
        >
        <span>01.08.2000 - 31.07.2018 </span>
        <span>Vollbeschäftigung im Beamtenverhältnis</span>
      </div>
      <span
        >Beabsichtigt ist die Versetzung in den Ruhestand auf eigenen Antrag mit
        Ablauf des 31.07.2018 (Monat der Vollendung des 64. Lebensjahres)</span
      >
      <h4 class="headline-small">
        <u>Zweiter Arbeitsschritt:</u> Nutzung des Schlüsselkatalogs
      </h4>
      <div>
        <span>Studien- und Prüfungszeit:</span>
        <ul>
          <li>Vorbereitungsdienst</li>
          <li>Mit voller Stundenzahl einschl. Vorbereitungsdienst</li>
          <li><strong>Schlüssel 0602</strong></li>
        </ul>
      </div>
      <div>
        <span>Beamtendienstzeit:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>Mit voller Stundenzahl einschl. Vorbereitungsdienst</li>
          <li><strong>Schlüssel 0602</strong></li>
        </ul>
      </div>
      <div>
        <span>Mutterschutzfrist:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>
            Mit voller Stundenzahl einschl. Vorbereitungsdienst Die Zeit des
            Beschäftigungsverbots (Mutterschutzfrist) ist als Vollbeschäftigung
            zu berücksichtigen.
          </li>
          <li><strong>Schlüssel 0602</strong></li>
        </ul>
      </div>
      <div>
        <span>Erziehungsurlaub:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>Kindererziehungszeit für Kinder, die vor 1992 geboren sind</li>
          <li>bis zum vollendeten 6. Lebensmonat</li>
          <li><strong>Schlüssel 0657</strong></li>
          <li>bis zum vollendeten 18. Lebensmonat</li>
          <li>
            Beurlaubung ohne Dienstbezüge einschließlich
            Kindererziehungs-/Elternzeit
          </li>
          <li><strong>Schlüssel 0613</strong></li>
        </ul>
      </div>
      <div>
        <span>Beurlaubung:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>Beurlaubung ohne Dienstbezüge</li>
          <li><strong>Schlüssel 0613</strong></li>
        </ul>
      </div>
      <div>
        <span>Teilzeitbeschäftigung:</span>
        <ul>
          <li>Zeiten im Beamten- und Richterverhältnis</li>
          <li>Teilzeitbeschäftigung</li>
          <li><strong>Schlüssel 0607</strong></li>
        </ul>
      </div>
      <div>
        <span>Teilzeitbruch:</span>
        <span>siehe unter Reiter Regelmäßige Arbeitszeit, Pflichtstunden</span>
        <ul>
          <li>Regelmäßige Arbeitszeit nach der AZV NRW</li>
          <li>ab 04/90 → <strong>38,5 Stunden</strong></li>
        </ul>
      </div>
      <div>
        <div class="grid1">
          <span class="flex-container-start-center">Geburtsdatum:</span>
          <span class="flex-container-start-center">01.08.2018</span>
          <span class="flex-container-start-center"
            >Voraussichtlicher Versorgungsbeginn:</span
          >
          <span class="flex-container-start-center">01.08.2018</span>
          <span class="flex-container-start-center"
            >Grund der Zurruhesetzung:</span
          >
          <span class="flex-container-start-center">Auf Antrag</span>
          <span class="flex-container-start-center"
            >Es gelten die Vorschriften für:</span
          >
          <span class="flex-container-start-center"
            >Allg. Verwaltungsdienst</span
          >
        </div>
        <div class="grid2">
          <span class="flex-container-start-center"
            >01.08.1973 - 31.07.1991</span
          >
          <span class="flex-container-start-center">0602</span>
          <span>2 125 *)</span>
          <span class="flex-container-start-center"
            >01.08.1970 - 30.09.1972</span
          >
          <span class="flex-container-start-center">0657</span>
          <span></span>
          <span class="flex-container-start-center"
            >01.01.1973 - 31.07.2000</span
          >
          <span class="flex-container-start-center">0613</span>
          <span></span>
          <span class="flex-container-start-center"
            >01.08.2000 - 31.01.2008</span
          >
          <span class="flex-container-start-center">0607</span>
          <span class="flex-container-start-center">20 / 38,5</span>
          <span class="flex-container-start-center"
            >01.08.2000 - 31.07.2018</span
          >
          <span class="flex-container-start-center">0602</span>
          <span class="flex-container-start-center"></span>
          <span></span>
        </div>
        <span class="text-small"
          ><u>Hinweis:</u> Aufeinanderfolgende Zeiträume mit gleichem Schlüssel
          können zusammengefasst werden.</span
        >
      </div>
      <h4 class="headline-small">
        <u>Vierter Arbeitsschritt:</u> Ruhegehaltssatz berechnen
      </h4>
      <span>Feld "Berechnung starten" anklicken.</span>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-container-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.grid1 {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: auto;
}

.grid2 {
  display: grid;
  grid-template-columns: 20% 10% 10%;
  grid-template-rows: auto;
}

.grid3 {
  display: grid;
  grid-template-columns: 20% 25%;
  grid-template-rows: auto;
}

h1 {
  margin-top: 0px;
}
</style>
