<template>
  <input
    :aria-label="`${col} ${compPlace}`"
    :aria-placeholder="compPlace"
    :id="refId"
    :ref="refId"
    type="text"
    :step="stepCnt"
    :placeholder="placeholderString"
    :disabled="isDisabled"
    :maxlength="placeholderString.length"
    v-model="inputValue"
    @input="validate"
    @focus="closeDropdown"
  />
</template>

<script>
export default {
  emits: [
    "save-value",
    "input-invalid",
    "focus-next",
    "propagate-refs",
    "close-dropdown"
  ],
  props: {
    rowId: Number,
    col: String,
    compPlace: String,
    typeString: String,
    stepCnt: Number,
    placeholderString: String,
    maxLength: Number,
    initValue: String,
    isDisabled: Boolean,
    refId: String,
    nextRef: String,
    employment: Object,
    resetContext: Object,
    pollContext: Object
  },
  data() {
    return {
      inputValue: "",
      invalid: false,
      checkforChar: /[a-zA-Z]/,
      checkForNum: /\d/
    };
  },
  methods: {
    validate() {
      // if(this.col === "Teilzeitbruch"){
      //   this.$emit("input-invalid", { action: "reset", type: "CounterDenomError" });
      // }

      if (this.typeString === "text") {
        this.invalid = this.checkForNum.test(this.inputValue);
      } else if (this.typeString === "number") {
        this.invalid = this.checkforChar.test(this.inputValue);
      }

      if (!this.invalid) {
        if (this.inputValue.length === 5) {
          this.$emit("focus-next", this.nextRef);
        } else if (this.refId === "durYear" && this.inputValue.length === 2) {
          this.$emit("focus-next", this.nextRef);
        }
        if(this.refId === "durYear" || this.refId === "durDay"){
          this.$emit("input-invalid", { key: "eligible", action: "reset", type: "elig-missing" });
          this.$emit("input-invalid", { key: "eligible", action: "reset", type: "elig-mandatory" });
        }
        this.saveValue();
        return;
      }
      this.$emit("input-invalid", { action: "set", type: "day", msg: "Eingabe Falsch" });
    },
    saveValue() {
      let value = this.inputValue;
      if(value.includes(",")){
        value = value.replace(",", ".");
      }
      const valueNum = parseFloat(value);
      this.$emit("save-value", valueNum);
    },
    closeDropdown() {
      this.$emit("close-dropdown");
    },
    reset(){
      this.inputValue = "";
    },
    getValue(){
      return this.inputValue;
    }
  },
  watch: {
    inputValue() {
      if(this.col === "Teilzeitbruch"){
        this.$emit("input-invalid", { key: "fraction", action: "reset", type: "CounterDenomError" });
      }
      //################################################################
      // check if the input typ is correct
      if (this.typeString === "text") {
        this.invalid = this.checkForNum.test(this.inputValue);
      } else if (this.typeString === "number") {
        this.invalid = this.checkforChar.test(this.inputValue);
      }

      //reset input if not
      if (this.invalid) {
        const numVal = parseInt(this.inputValue);
        const newVal = numVal.toString();
        if (this.typeString === "number") {
          this.inputValue = isNaN(numVal) ? "" : newVal;
        } else if (this.typeString === "text") {
          this.inputValue = !isNaN(numVal) ? "" : newVal;
        }
      }

      if (this.inputValue === "00") {
        this.inputValue = "0";
      }
    },
    employment() {
      this.disableSelf();
    }
  },
  created() {
    this.inputValue = this.initValue;
  },
  mounted() {
    const periods = this.$store.getters.getServicePeriods;
    const eligible = periods.find(period => period.id === this.rowId)?.eligible;
    const fracture = periods.find(period => period.id === this.rowId)?.fracture;

    switch (this.compPlace){
      case "Tage":
        this.inputValue = eligible?.days >= 0 ? eligible.days :  "";
        break;
      case "Jahre":
        this.inputValue = eligible?.years >= 0 ? eligible.years : "";
        break;
      case "Zähler":
        this.inputValue = fracture?.counter >= 0 ? fracture.counter : "";
        break;
      case "Nenner":
        this.inputValue = fracture?.denominator >= 0 ? fracture.denominator : "";
        break;
      default:
        break;
    }

    this.pollContext?.set(this.getValue);

    this.$emit("propagate-refs", this.$refs[this.refId]);
    try {
      this.resetContext?.on("reset", this.reset);
    }catch(err) {
      if(process.env.NODE_ENV === "development"){
        console.log(err);
      }
    }
  },
  unmounted() {
    this.resetContext?.off("reset", this.reset);
  }
};
</script>

<style scoped>
input:disabled {
  background-color: #f6f6f6;
  border-bottom: 0px solid #d9dada;
}

input {
  border: 0px;
  border-bottom: 2px solid #acacac;
  box-sizing: border-box;

  width: 58px;
  height: 40px;

  text-align: center;
  margin-right: 8px;
}

input:focus {
  outline: none;
}
</style>
