/**
 * Wandelt ein Date objekt in einen String mit dem Format dd.mm.JJJJ um.
 * @param {Date} date
 */
import cloneDeep from "lodash.clonedeep";

export function dateTosTring(date){
  let day = "";
  let month = "";

  if(date.getDate() < 10) day = `0${date.getDate()}`;
  else day = date.getDate();

  if(date.getMonth() + 1 < 10) month = `0${date.getMonth() + 1}`;
  else month = date.getMonth() + 1;

  return `${day}.${month}.${date.getFullYear()}`
}

/**
 *
 * @param {Number} numVal
 */
export function convertToCommaStr(numVal){
  const numberStr = numVal.toString();
  return numberStr.split(".").join(",");
}
/**
 *
 * @param {String} numStr
 */
export function convertStrToCommaStr(numStr){
  return numStr.split(".").join(",");
}

/**
 * Returns true if the given year is a Leap year otherwise false.
 * @param {Number} year
 */
export function checkIfLeapYear(year){
  const febDate = new Date(year, 2, 0);
  return febDate.getDate() === 29;
}

export function timeStampToDateString(timestamp){
  if(!timeStampToDateString) return "";

  const date = new Date(timestamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  let dayStr = "";
  let monthStr = "";

  if(day < 10) dayStr = "0" + day;
  else dayStr = day.toString();

  if(month < 10) monthStr = "0" + month;
  else monthStr = month.toString();

  // return dayStr + monthStr + year;
  return `${dayStr}.${monthStr}.${year}`;
}

/**
 * Nimmt einen String entgegen und konvertiert ihn zum Typ number(float).
 * Falls das ergebnis der konvertierung keine number ist (NaN) dann wir -1 zurückgegeben
 * sonst immer die konvertierte Zahl.
 * @param {String} convStr
 * @returns {number}
 */
export function strToNum(convStr){
  if(convStr.includes(",")){
    const splitStrArr = convStr.split(",");
    convStr = splitStrArr.join(".");
  }
  const num = parseFloat(convStr);

  if(isNaN(num)) {
    console.error(convStr, "konnte nicht zum Typ number konvertiert werden!");
    return -1;
  }
  return num
}

/**
 * Prüft einen String Charakter die nicht alphanumerisch sind.
 * @param {String} str
 * @returns {boolean}  true, wenn ein Buchstabe im String vorhanden ist.
 *                     Sonst false.
 */
export function containsUnwantedLetter(str){
  return !!str.match(/[^0-9,]/);
}

/**
 *
 * @param {number} month => the actual month 1 for Januar, 2 for Februar. Not the JS Date style.
 * @param {number} year
 * @return {number} - -1 if year and month are invalid, the number of days in the given momth of the given year otherwise
 */
export function getDaysInMonth(year,month){
  const date = new Date(year, month, 0);
  if(isNaN(date.getDate())) return -1
  return date.getDate();
}

/**
 *
 * @param {import("@/types").Period[]} periods
 * @returns {import("@/types").Period[]}
 */
export function sortTimeFrames(periods){
  const sortingArr = cloneDeep(periods);
  sortingArr.sort((periodA, periodB) => {
    return periodA.workPeriod.from - periodB.workPeriod.from;
  });

  return sortingArr;
}

/**
 * Diese Methode gibt für die Regel der Zurruhesetzung
 * den beschreiben Buchstaben zu Weiterverabreitung zurück.
 * @param {String} reasonTxt
 * @returns beschreibender Buchstabe oder
 * einen leereren String wenn keine Zuordnung existiert.
 * @note Diese Funktion ist ein Dupplikat der Funktion in helper/KeyMapping.js, da die Funktion dort teilweise nicht über import xxx erreicht werden kann.
 */
export function getReasonFromString(reasonTxt) {
  switch (reasonTxt) {
    case "Erreichen der Altersgrenze":
      return "N";
    case "Auf Antrag":
      return "A";
    case "Dienstunfähigkeit":
      return "D";
    case "Schwerbehinderung":
      return "S";
    case "Einstweiliger Ruhestand":
      return "E";
    default:
      console.log("Helper | getReasonFromString | Unknown: " + reasonTxt);
      return "";
  }
}

/**
 * Diese Methode gibt für den Beschreibungstext des Grundes der Zurruhesetzung
 * den beschreiben Buchstaben zu Weiterverabreitung zurück.
 * @param {String} ruleTxt
 * @returns beschreibender Buchstabe oder
 * einen leereren String wenn keine Zuordnung existiert.
 * @note Diese Funktion ist ein Dupplikat der Funktion in helper/KeyMapping.js, da die Funktion dort teilweise nicht über import xxx erreicht werden kann.
 */
export function getRuleFromString(ruleTxt) {
  switch (ruleTxt) {
    case "Verwaltungsdienst":
      return "A";
    case "Lehrkräfte":
      return "L";
    case "Professorenschaft Universität":
      return "H";
    case "Professorenschaft Fachhochschule":
      return "FH";
    case "Polizeivollzugsdienst":
      return "P";
    case "Polizeivollzugsdienst mit mind 25 Jahren Wechselschicht":
      return "W";
    case "Feuerwehr im Einsatzdienst":
      return "F";
    case "Justizvollzugsdienst":
      return "J";
    case "Technischer Aufsichtsdienst in untertägigen Bergwerksbetrieben":
      return "U";
    default:
      console.log("Helper | getRuleFromString | Unknown: " + ruleTxt);
      return "";
  }
}

/**
 * @param {String} id
 * @param {import("../types").Key[]} keys
 */
export function getKeyFromId(id, keys) {
  let key = "";
  for(const keyEl of keys) {
    const subMenus = keyEl.subMenus;
    if(keyEl.id === id){
      return keyEl.key;
    }

    if(subMenus !== undefined && subMenus !==  null && key == "")
      key = getKeyFromId(id, subMenus);
  }

  return key;
}
