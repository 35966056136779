<template>
  <h2 class="headline-large mb-5">Dienstzeitenschlüssel für Versorgungsrechner</h2>

  <details open>
    <summary class="rounded"><h3>Zeiten im Beamten- und Richterverhältnis</h3></summary>

    <section class="rounded border border-cc-border-color">
      <div class="flex-container-tabelle">
        <div class="flex-item80">Mit voller Stundenzahl einschließlich Vorbereitungsdienst<br>
          <u>Hinweis:</u> Die Zeit des Beschäftigungsverbots (Mutterschutzfrist) ist als Vollbeschäftigung zu berücksichtigen.</div>
        <div class="flex-item20">0602</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Teilzeitbeschäftigung - auch unterhälftig</div>
        <div class="flex-item20">0607</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Beurlaubung ohne Dienstbezüge<br>
          einschließlich Kindererziehungs-/Elternzeit für	Kinder, die nach 1991 geboren sind</div>
        <div class="flex-item20">0613</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Kindererziehungszeit für Kinder, die vor 1992 geboren sind<br>
          vom Tag der Geburt bis zum Ablauf des 6. Lebensmonats</div>
        <div class="flex-item20">0657</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Altersteilzeit - im Block- oder Teilzeitmodell<br>
          (Teilzeitbruch ist nur einzugeben, wenn innerhalb der letzten 5 Jahre vor Beginn der Altersteilzeit eine	Teilzeitbeschäftigung vorgelegen hat)</div>
        <div class="flex-item20">0616</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Altersteilzeit im Blockmodell,<br>
          sofern sie vor dem 01.01.2013 angetreten wurde und am 01.08.2013 eine vollständige Freistellung vorlag<br>
          (Teilzeitbruch ist nur einzugeben, wenn innerhalb der letzten 5 Jahre vor Beginn der Altersteilzeit eine Teilzeitbeschäftigung vorgelegen hat)</div>
        <div class="flex-item20">0617</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Teildienstfähigkeit</div>
        <div class="flex-item20">0603</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80"><b>Aufbauhilfe in den neuen Bundesländern</b></div>
        <div class="flex-item20"></div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">mit voller Stundenzahl</div>
        <div class="flex-item20">0685</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">mit Teilzeitbeschäftigung</div>
        <div class="flex-item20">0686</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80"><h4>Verwendung in Ländern mit gesundheitsschädigendem Klima, wenn sie mindestens 1 Jahr ununterbrochen gedauert hat</h4></div>
        <div class="flex-item20"></div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">mit voller Stundenzahl</div>
        <div class="flex-item20">1301</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">mit Teilzeitbeschäftigung</div>
        <div class="flex-item20">1304</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80"><b>Unterbrechung der Verwendung z. B. durch Heimaturlaub</b></div>
        <div class="flex-item20"><br></div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">bei voller Stundenzahl</div>
        <div class="flex-item20">1306</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">bei Teilzeitbeschäftigung</div>
        <div class="flex-item20">1309</div>
      </div>
    </section>
  </details>

  <details>
    <summary class="rounded"><h3> Bundeswehr und Zivildienst</h3></summary>

    <section class="rounded border border-cc-border-color">
      <div class="flex-container-tabelle">
        <div class="flex-item80">Berufs- und nicht berufsmäßiger Dienst in der Bundeswehr<br>
          Zivildienst<br>Wehrübungen außerhalb des Beamtenverhältnisses</div>
        <div class ="flex-item20">0901</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Unmittelbar vor dem Beamtenverhältnis liegendes<br>
          Berufssoldatenverhältnis, das am 31.12.1991 bestanden hat</div>
        <div class ="flex-item20">0803</div>
      </div>
    </section>
  </details>

  <details>
    <summary class="rounded">
      <h3>Angestelltenverhältnis im Öffentlichen Dienst <br>
        Tätigkeit in einem Angestelltenverhältnis oder als
        Arbeiterin/Arbeiter im Dienst eines öffentlich-rechtlichen
        Dienstherrn, soweit sie unmittelbar vor dem Beamtenverhältnis
        ausgeübt wurde und zur Ernennung geführt hat</h3></summary>
    
    <section class="rounded border border-cc-border-color">
      <div class="flex-container-tabelle">
        <div class="flex-item80">mit voller Stundenzahlen</div>
        <div class ="flex-item20">1001</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Teilzeitbeschäftigung</div>
        <div class ="flex-item20">1002</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Unterhälftige Teilzeitbeschäftigung während der Elternzeit<br>
          oder Freistellung aus familienpolitischen Gründen</div>
        <div class ="flex-item20">1006</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Beurlaubung ohne Entgelt</div>
        <div class ="flex-item20">1004</div>
      </div>
    </section>
  </details>

  <details>
    <summary class="rounded"><h3>Beschäftigung im Öffentlichen Dienst<br>
      Versicherungsfreies Beschäftigungsverhältnis im Dienst eines
      Öffentlich-rechtlichen Dienstherrn (§ 5 Abs. 1 Nr. 2 SGB VI),
      soweit es unmittelbar vor dem Beamtenverhältnis ausgeübt wurde</h3></summary>

    <section class="rounded border border-cc-border-color">
      <div class="flex-container-tabelle">
        <div class="flex-item80">mit voller Stundenzahl</div>
        <div class ="flex-item20">1085</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Teilzeitbeschäftigung</div>
        <div class ="flex-item20">1083</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Beurlaubung ohne Entgelt</div>
        <div class ="flex-item20">1084</div>
      </div>    
    </section>
  </details>

  <details>
    <summary class="rounded"><h3>Ausbildungszeiten <br>
      Lt. Ausbildungs-und Prüfungsordnungen, Laufbahnverordnungen, Studien-
      und Prüfungsordnungen vorgeschriebene Ausbildungszeiten oder
      hauptberufliche Tätigkeiten, (Jedoch keine allgemeine Schulausbildung
      oder diese ersetzende andere Art der Ausbildung)</h3></summary>

    <section class="rounded border border-cc-border-color">
    <div class="flex-container-tabelle">
      <div class="flex-item80">Vorbereitungsdienst außerhalb des
        Beamtenverhältnisses <br>
        Praktikum, Lehre, Anerkennungsjahr, hauptberufliche
        Tätigkeit
        (vorgeschriebene Mindestdauer ist unter "höchst.
        anr." einzugeben)</div>
      <div class ="flex-item20">1210</div>
    </div>

    <div class="flex-container-tabelle">
      <div class="flex-item80">Fachschulausbildung</div>
      <div class ="flex-item20">1233</div>
    </div>

    <div class="flex-container-tabelle">
      <div class="flex-item80">Hochschul- und Fachhochschulausbildung einschließlich
        Prüfungszeit <br>
        (bestand das Beamtenverhältnis bereits am 31.12.1991 ist
        unter "höchst. anr." die vorgeschriebene
        Mindeststudienzeit und Prüfungszeit einzugeben.</div>
      <div class ="flex-item20">1230</div>
    </div>

    <div class="flex-container-tabelle">
      <div class="flex-item80">Nur für Beamte des Vollzugsdienstes und Einsatzdienstes der Feuerwehr<br>
        Förderliche praktische Ausbildung oder hauptberufliche Tätigkeit <br>
        Bei Teilzeitbeschäftigung Teilzeitbruch eingeben</div>
      <div class ="flex-item20">1221</div>
    </div>
    </section>
  </details>

  <details>
    <summary class="rounded"><h3>Sonstige Beschäftigungen</h3><br>
      <b>im öffentlichen oder nichtöffentlichen Schuldienst</b>
      <br><b>im Dienst öffentlich-rechtlicher Religionsgemeinschaften oder ihrer Verbände</b><br>
      <b>im Dienst der Fraktionen des Bundestages, der Landtage oder kommunaler Vertretungskörperschaften</b><br>
      <b>im Dienst kommunaler Spitzenverbände oder ihrer Landesverbände sowie von Spitzenverbänden der Sozialversicherung oder ihrer Landesverbände</b><br>
      <b>im ausländischen öffentlichen Dienst</b></summary>

    <section class="rounded border border-cc-border-color">
      <div class="flex-container-tabelle">
        <div class="flex-item80">mit voller Stundenzahl</div>
        <div class ="flex-item20">1102</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Teilzeitbeschäftigung</div>
        <div class="flex-item20"> 1103</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Unterhälftige Beschäftigung während der Elternzeit oder Freistellung aus familienpolitischen Gründen </div>
        <div class="flex-item20"> 1119</div>
      </div>

      <div class="flex-container-tabelle">

        <div class="flex-item80">Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar ohne Ruhegehaltsberechtigung (anrechenbar zur Hälfte), wenn das Beamten- oder Richterverhältnis vor dem 01.07.2016 bereits bestanden hat</div>
        <div class ="flex-item20">1101</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Tätigkeit Entwicklungshelfer (anrechenbar zur Hälfte)</div>
        <div class ="flex-item20">1118</div>
      </div>
    </section>
  </details>

  <details>
    <summary class="rounded"><h3>Versicherungsfreie Beschäftigung als Planstelleninhaber im Ersatzschuldienst oder im Kirchendienst als Geistlicher oder Kirchenbeamter (§§ 5, 6 SGB VI), die unmittelbar vor dem Beamtenverhältnis liegt und am 31.12.1991 bestanden hat</h3></summary>
    <section class="rounded border border-cc-border-color">
      <div class="flex-container-tabelle">
        <div class="flex-item80">mit voller Stundenzahl</div>
        <div class ="flex-item20">1185</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">Teilzeitbeschäftigt</div>
        <div class ="flex-item20">1183</div>
      </div>

      <div class="flex-container-tabelle">
        <div class="flex-item80">beurlaubt ohne Bezüge</div>
        <div class ="flex-item20">1184</div>
      </div>
    </section>
  </details>

  <details>
    <summary class="rounded"><h3> Hochschulpersonal im Sinne § 67 LBeamtVG NRW</h3></summary>
    <section class="rounded border border-cc-border-color">
      <div class="flex-container-tabelle">
        <div class="flex-item80">Promotionszeit oder promotionsadäquate Zeiten<br>
          maximal 2 Jahre</div>
        <div class ="flex-item20">6701</div>
      </div>
  
      <div class="flex-container-tabelle">
        <div class="flex-item80">Vorgeschriebene Mindestzeit für die Erbringung der
          Habilitationsleistungen oder sonstiger gleichwertiger
          wissenschaftlicher Leistungen<br>
          maximal 3 Jahre</div>
        <div class ="flex-item20">6719</div>
      </div>
  
      <div class="flex-container-tabelle">
        <div class="flex-item80">Zeit der Zugehörigkeit zum Lehrkörper einer Hochschule nach der Habilitation</div>
        <div class ="flex-item20">6702</div>
      </div>
  
      <div class="flex-container-tabelle">
        <div class="flex-item80">Zeit des Erwerbs besonderer Fachkenntnisse<br>
          maximal 5 Jahre voll, danach maximal 10 Jahre zur Hälfte</div>
        <div class ="flex-item20">6705</div>
      </div>
    </section>
  </details>
</template>

<script>
export default {};
</script>

<style scoped>
* {
  padding: 0;
}

h1, h2, h3, h4, h5, h6
{
  /*margin-top: 10px;*/
  color: black;
}

summary {
  border: 1px solid #aaa;
  @apply rounded;
  @apply bg-cc-blue-200;
  color:#000000;
  font-weight: bold;
  font-size:100%;
  margin: 4px 0; /* Oberer und unterer Abstand zwischen den summary (Überschriften) Tags */
  padding: 10px;
}

summary::marker { content: ""; }
summary:hover { cursor: pointer; }

/* flexbox Klassen für Inhalt mit Bildern und Text, usw.. Quelle: https://kulturbanause.de/blog/einfuhrung-in-das-flexbox-modell-von-css/ */

.flex-container {
  display: flex;
}

.flex-container-tabelle {
  display: flex;
  align-items: center; /* Damit alle Items (Inhalte) vertikal zentriert sind, wenn z.B. eine Zeile einen Zeilenumbruch hat... ist das geil mit flex-box */
}

.flex-container-tabelle:nth-child(even) /* jede erste/ungerade Zeile in der Tabelle */
{
  background: #FFF;
}

.flex-container-tabelle:nth-child(odd) /* jede zweite/gerade Zeile in der Tabelle */
{
  background: #DDF;
}

/* Ein paar vordefinierte Breiten - die heissen halt so, wie breit in % sie sind */
.flex-item05, flex-item10,.flex-item20, .flex-item25, .flex-item30, .flex-item33, .flex-item40,.flex-item50,.flex-item60,.flex-item66 ,.flex-item75, flex-item70,.flex-item80,.flex-item90, .flex-item95{
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 10%;
  margin: 10px;
}

.flex-item05 {flex-basis:  5%; }
.flex-item10 {flex-basis: 10%; }
.flex-item20 {flex-basis: 20%; font-weight: bold; }
.flex-item25 {flex-basis: 25%; }
.flex-item30 {flex-basis: 30%; }
.flex-item33 {flex-basis: 33%; }
.flex-item40 {flex-basis: 40%; }
.flex-item50 {flex-basis: 50%; }
.flex-item60 {flex-basis: 60%; }
.flex-item66 {flex-basis: 66%; }
.flex-item70 {flex-basis: 70%; }
.flex-item75 {flex-basis: 75%; }
.flex-item80 {flex-basis: 80%; }
.flex-item90 {flex-basis: 90%; }
.flex-item95 {flex-basis: 95%; }
</style>
