<template>
  <div>
    <h1 class="headline-large">Regelmäßige Arbeitszeit, Pflichtstunden</h1>
    <h2 class="headline-medium">Regelmäßige Arbeitszeit nach der AZVO NRW</h2>
    <section>
      <div class="help-text-small">
        gilt nicht für:<br />
        <ul>
          <li>
            Lehrerinnen und Lehrer
          </li>
          <li>
            Professorinnen und Professoren, Juniorprofessorinnen und
            Juniorprofessoren Fachhochschullehrerinnen und Fachhochschullehrer,
            Studienprofessorinnen und Studienprofessoren und Dozentinnen und
            Dozenten an Hochschulen des Landes sowie Dozentinnen und Dozenten an
            Fachhochschulen für den öffentlichen Dienst
          </li>
        </ul>
      </div>
      <table border="1" cellspacing="1" cellpadding="4">
        <tbody>
          <tr>
            <td align="center">
              <b><font size="3">Zeitraum</font></b>
            </td>
            <td colspan="2" align="center">
              <b
                ><font size="3">Wöchentliche Arbeitszeit<b></b></font
              ></b>
            </td>
          </tr>
          <tr>
            <td width="28%" align="center">
              <font size="3"> 01.01.1969 - 31.12.1970 </font>
            </td>
            <td width="62%">&nbsp;</td>
            <td width="10%" align="center">
              <font size="3"> <b> 43 </b></font>
            </td>
          </tr>
          <tr>
            <td align="center">
              <font size="3"> 01.01.1971 - 30.09.1974 </font>
            </td>
            <td>&nbsp;</td>
            <td align="center">
              <font size="3"> <b> 42 </b></font>
            </td>
          </tr>
          <tr>
            <td align="center">
              <font size="3"> 01.10.1974 - 31.03.1989 </font>
            </td>
            <td>&nbsp;</td>
            <td align="center">
              <font size="3"> <b> 40 </b></font>
            </td>
          </tr>
          <tr>
            <td align="center">
              <font size="3"> 01.04.1989 - 31.03.1990 </font>
            </td>
            <td><b> &nbsp; </b></td>
            <td align="center">
              <font size="3"> <b> 39 </b></font>
            </td>
          </tr>
          <tr>
            <td align="center">
              <font size="3"> 01.04.1990 - 31.12.2003 </font>
            </td>
            <td>&nbsp;</td>
            <td align="center">
              <font size="3"> <b> 38,5 </b></font>
            </td>
          </tr>
          <tr>
            <td rowspan="3" align="center">
              <font size="3"> 01.01.2004 - 31.07.2006 </font>
            </td>
            <td>&nbsp;</td>
            <td align="center">
              <font size="3"> <b> 41 </b></font>
            </td>
          </tr>
          <tr>
            <td>
              <font size="2">
                <b>mit Vollendung</b> des <b>55.</b> Lebensjahres</font
              >
            </td>
            <td align="center">
              <font size="3"> <b> 40 </b></font>
            </td>
          </tr>
          <tr>
            <td>
              <font size="2">
                <b>mit Vollendung</b> des <b>60.</b> Lebensjahres<br />
                <b>oder</b> <br />
                bei einem Grad der <b>Schwerbehinderung</b> von mindestens
                <b>80</b> vom Hundert</font
              >
            </td>
            <td align="center">
              <font size="3"> <b> 39 </b></font>
            </td>
          </tr>
          <tr>
            <td rowspan="3" align="center">
              <font size="3"> 01.08.2006 - 28.09.2009 </font>
            </td>
            <td>&nbsp;</td>
            <td align="center">
              <font size="3"> <b> 41 </b></font>
            </td>
          </tr>
          <tr>
            <td>
              <font size="2">
                <b>mit Beginn des Monats,</b> in dem das <b>55.</b> Lebensjahr
                vollendet<br />
                <b>oder</b> <br />
                ein Grad der <b>Behinderung</b> von mindestens
                <b>50</b> festgestellt wird
              </font>
            </td>
            <td align="center">
              <font size="3"> <b> 40 </b></font>
            </td>
          </tr>
          <tr>
            <td>
              <font size="2">
                <b>mit Beginn des Monats,</b> in dem das <b>60.</b> Lebensjahr
                vollendet<br />
                <b>oder</b> <br />
                ein Grad der <b>Behinderung</b> von mindestens
                <b>80</b> festgestellt wird
              </font>
            </td>
            <td align="center">
              <font size="3"> <b> 39 </b></font>
            </td>
          </tr>
          <tr>
            <td rowspan="4" align="center">
              <font size="3"> 29.09-2009 - &nbsp; </font>
            </td>
            <td>&nbsp;</td>
            <td align="center">
              <font size="3"> <b> 41 </b></font>
            </td>
          </tr>
          <tr>
            <td>
              <font size="2">
                <b>mit Ablauf des Tages,</b> an dem das <b>55.</b> Lebensjahr
                <b>vollendet</b> wird
              </font>
            </td>
            <td align="center">
              <font size="3"> <b> 40 </b></font>
            </td>
          </tr>
          <tr>
            <td>
              <font size="2">
                <b>mit dem Tag,</b> ab dem ein Grad der <b>Behinderung</b> von
                mindestens <b>50</b> festgestellt wird
              </font>
            </td>
            <td align="center">
              <font size="3"> <b> 39,83 </b></font>
            </td>
          </tr>
          <tr>
            <td>
              <font size="2">
                <b>mit Ablauf des Tages,</b> an dem das <b>60.</b> Lebensjahr
                <b>vollendet</b><br />
                <b>oder</b> <br />
                <b>mit dem Tag,</b> ab dem ein Grad der <b>Behinderung</b> von
                mindestens <b>80</b> festgestellt wird
              </font>
            </td>
            <td align="center">
              <font size="3"> <b> 39 </b></font>
            </td>
          </tr>
        </tbody>
      </table>
      <h2 class="headline-medium">
        Pflichtstunden der Lehrkräfte nach der Verordnung zur Ausführung des §
        93 Abs. 2 Schulgesetz NRW (VO zu § 93 Abs. 2 SchulG)
      </h2>
      <table border="1" cellspacing="1" cellpadding="4">
        <tbody>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>Schulform</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>bis 07/69</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/69</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/70</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/72</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/73</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/81</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/87</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/89</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/90</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/97</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/98</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 08/99</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>ab 02/04</b></font>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"
                  ><b>Grund-/<br />Hauptschule</b></font
                >
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>30</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>29</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>29</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>Realschule</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,5</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b> </font
                ><sup><font size="2">4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>Gymnasium</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>23,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>23,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>23,5</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>Gesamtschule</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>23,5</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b>
              <p>
                <b><font size="2">Berufskolleg </font></b>
                <sup><font size="2">1)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b> </font
                ><sup><font size="2">2)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>Kollegschule</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>23,5</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>23,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3) 4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>Sonderschule</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>28</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,5</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,5</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>26,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>27,5</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"
                  ><b>Abend-<br />realschule</b></font
                >
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>22</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>22</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>22</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>21,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>22,75</b> </font
                ><sup><font size="2">4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b> </font
                ><sup><font size="2">4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>24</b> </font
                ><sup><font size="2">3) 4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>25</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"
                  ><b>Abend-<br />gymnasium/<br />-kolleg</b></font
                >
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>20</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>20</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>20</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>19</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>19</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>19</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>18,75</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>19,75</b> </font
                ><sup><font size="2">4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>21</b> </font
                ><sup><font size="2">4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>21</b> </font
                ><sup><font size="2">3) 4)</font></sup>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>22</b> </font
                ><sup><font size="2">3)</font></sup>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="TOP">
              <b><font size="2"></font></b><font size="2"></font>
              <p>
                <font size="2"><b>Sekundarschule</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b><font size="3"></font>
              <p align="CENTER">
                <font size="3"><b>-</b></font>
              </p>
            </td>
            <td valign="TOP">
              <b><font size="3"></font></b>
              <p align="CENTER">
                <b><font size="3">25,5</font></b>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section>
      <ol>
        <li>
          <span class="help-text-small">
            früher Berufsschule, Berufsfachschule, Fachschule und Fachoberschule
          </span>
        </li>
        <li>
          <span class="help-text-small">
            für den Bereich der höheren Fachschulen:<br />
            ab 01.10.1959: 25 Wochenstunden<br />
            ab 01.08.1969: 24 Wochenstunden<br />
            ab 10.08.1973: 26 Wochenstunden<br />
            ab 01.02.1975: 25 Wochenstunden<br />
          </span>
        </li>
        <li>
          <span class="help-text-small">
            Die Zahl der wöchentlichen Pflichtstunden erhöht sich für
            Lehrkräfte, die vor Beginn des jeweiligen Schuljahres das 30.
            Lebensjahr, aber noch nicht das 50. Lebensjahr vollendet haben,
            vorübergehend für einen Zeitraum bis zu sechs Jahren um eine Stunde
            - sog. Vorgriffsstunde -. So erhöhte sich z.B. im Schuljahr 1997/98
            die Pflichtstundenzahl für Lehrkräfte an Hauptschulen, die zwischen
            dem 02.08.1947 und 01.08.1967 geboren sind, von 27 auf 28
            Wochenstunden. Der zeitliche Ausgleich erfolgt durch Absenkung der
            Pflichtstundenzahl schrittweise ab dem Schuljahr 2008/09 (§ 4 der VO
            zu § 5 SchFG). Bei der späteren Festsetzung der Versorgungsbezüge
            bleiben sämtliche Vorgriffsstunden unberücksichtigt.
          </span>
        </li>
        <li>
          <span class="help-text-small"
            >differenzierte Pflichtstundenerhöhung</span
          >
        </li>
      </ol>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style>
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

h1 {
  margin-top: 0px;
}
</style>
