<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.37187 2.65752C8.79287 2.23652 9.36387 2 9.95925 2H13.8368C14.4322 2 15.0032 2.23652 15.4242 2.65752C15.8452 3.07852 16.0817 3.64951 16.0817 4.2449V4.58502H21.0952C21.6212 4.58502 22.0476 5.01142 22.0476 5.5374C22.0476 6.06339 21.6212 6.48978 21.0952 6.48978H20.1004L19.2984 19.3211C19.2043 20.8269 17.9556 22 16.4468 22H7.59712C6.08837 22 4.83966 20.8269 4.74554 19.3211L3.94359 6.48978H2.95238C2.4264 6.48978 2 6.06339 2 5.5374C2 5.01142 2.4264 4.58502 2.95238 4.58502H7.71436V4.2449C7.71436 3.64951 7.95087 3.07852 8.37187 2.65752ZM5.85207 6.48978L6.6466 19.2023C6.67797 19.7042 7.09421 20.0952 7.59712 20.0952H16.4468C16.9497 20.0952 17.366 19.7042 17.3974 19.2023L18.1919 6.48978H15.1342L15.1293 6.4898H8.66674L8.66183 6.48978H5.85207ZM14.1769 4.2449V4.58502H9.61912V4.2449C9.61912 4.15469 9.65495 4.06817 9.71874 4.00439C9.78253 3.9406 9.86904 3.90476 9.95925 3.90476H13.8368C13.927 3.90476 14.0135 3.9406 14.0773 4.00439C14.1411 4.06817 14.1769 4.15469 14.1769 4.2449ZM11.061 10.0613C11.061 9.53528 10.6346 9.10889 10.1086 9.10889C9.58265 9.10889 9.15625 9.53528 9.15625 10.0613V16.5239C9.15625 17.0498 9.58265 17.4762 10.1086 17.4762C10.6346 17.4762 11.061 17.0498 11.061 16.5239V10.0613ZM13.9866 9.10889C14.5125 9.10889 14.9389 9.53528 14.9389 10.0613V16.5239C14.9389 17.0498 14.5125 17.4762 13.9866 17.4762C13.4606 17.4762 13.0342 17.0498 13.0342 16.5239V10.0613C13.0342 9.53528 13.4606 9.10889 13.9866 9.10889Z"
      fill="#2C2E35"
    />
  </svg>
</template>
