<template>
  <div class="container">
    <header>
      <h1 class="headline-large">Berechnung Ruhegehalt</h1>
    </header>
    <p>
      Hier können Sie das
      voraussichtlich zustehende Ruhegehalt selbst berechnen.
    </p>

    <p>F&uuml;r die Berechnung sind folgende Daten erforderlich:</p>

    <p class="py-3">
      <span class="text-large-strong"><u>Ruhegehaltssatz</u></span><br>
      Einzugeben ist der mit der Versorgungsauskunft ermittelte oder der Berechnung des Ruhegehalts bereits zugrunde liegende  Ruhegehaltssatz;
      evtl. Nachkommastellen sind einzutragen (z.B. 70,58).
    </p>

    <p class="py-3">
      <span class="text-large-strong"><u>Versorgungsabschlag</u></span><br>
      Einzugeben ist der mit der Versorgungsauskunft ermittelte oder f&uuml;r die Minderung des Ruhegehalts bereits festgesetzte Versorgungsabschlag;
      evtl. Nachkommastellen sind einzutragen (z.B. 6,39).
      Wird kein Versorgungsabschlag vorgenommen, ist eine &quot;0&quot; einzugeben.
    </p>

    <div class="py-3">
      <span class="text-large-strong"><u>Ruhegehaltf&auml;hige Dienstbez&uuml;ge</u></span><br>
      <p>
        Einzutragen ist die <u>Summe</u> aus Grundgehalt, Familienzuschlag
        (nur Stufe 1) und ruhegehaltf&auml;higen Stellen-, Struktur-,
        Ausgleichs-, &Uuml;berleitungs- oder Amtszulagen
        (Betragseingabe 4000,44 EUR statt 4.000,44 EUR).
      </p>

      <div class="mt-1">Entnehmen Sie diesen Betrag:</div>
      <ul>
        <li>Ihrer aktuellen Bez&uuml;gemitteilung
          (Teilzeitbesch&auml;ftigte tragen bitte die ungek&uuml;rzten
          Bez&uuml;ge ein).
        </li>
        <li>
          der entsprechenden
          <a href="http://www.finanzverwaltung.nrw.de/de/besoldungstabellen-fuer-beamtinnen-und-beamte" target="_blank" rel="noreferrer">Besoldungstabelle f&uuml;r Beamtinnen und Beamte</a>.
        </li>
      </ul>

      <p>
        Bitte beachten Sie, dass bis zum Beginn Ihres Ruhestandes noch
        Ver&auml;nderungen Ihrer Bruttobez&uuml;ge eintreten k&ouml;nnen.
        Beispielsweise durch allgemeine Bez&uuml;geerh&ouml;hungen, Aufstiege in
        h&ouml;here Erfahrungsstufen oder Bef&ouml;rderungen.
      </p>
    </div>

    <p class="py-3">
      <span class="text-large-strong"><u>Absenkungsfaktor</u></span><br>
      Zum 01.01.2017 ist die fr&uuml;here j&auml;hrliche Sonderzahlung f&uuml;r
      aktive Beamte in die laufenden Bez&uuml;ge eingerechnet worden.
      Da die fr&uuml;here j&auml;hrliche Sonderzahlung f&uuml;r die
      Versorgungsberechtigten jedoch geringer war als f&uuml;r aktive
      Beamtinnen und Beamte, sind die ruhegehaltf&auml;higen Dienstbez&uuml;ge
      mit entsprechenden Faktoren abzusenken.
    </p>

    <PensionCalculation/>

    <div class="py-3">
      <span class="headline-medium"><u>Hinweise</u></span><br>
      <span class="text-large-strong">Mindestversorgung</span><br>
      Das Mindestruhegehalt beträgt 1.886,92 € (ohne Familienzuschlag der Stufe 1), 1.932,79 € (mit Familienzuschlag der Stufe 1 zur Hälfte) oder 1.978,67 € (mit Familienzuschlag der Stufe 1 voll).
    </div>

    <p class="py-3">
      <b>Das Ruhegehalt erh&ouml;ht sich</b> -&nbsp;sofern die
      Anspruchsvoraussetzungen erf&uuml;llt sind&nbsp;- z.B. um den
      Familienzuschlag von der Stufe 2 an aufw&auml;rts, das Kindergeld,
      einen Kindererziehungs- und / oder Kindererziehungserg&auml;nzungszuschlag
      und einen Unfallausgleich.
    </p>

    <p class="py-3">
      <span class="text-large-strong">Regelungen</span><br>
      Soweit Sie w&auml;hrend ihres Ruhestandes noch weitere Eink&uuml;nfte
      (z.B. Renten) erzielen, <U>kann</U> es zu einer Anrechnung dieser Betr&auml;ge
      kommen. <br>
      N&auml;here Informationen hierzu entnehmen Sie bitte unseren entsprechenden
      <a href="http://www.finanzverwaltung.nrw.de/de/merkblaetter" target="_blank" rel="noreferrer">Merkbl&auml;ttern</a>.
    </p>

    <p class="py-3">
      <span class="text-large-strong">Versteuerung</span><br>
      Der Versorgungsbezug (ohne Kindergeld) unterliegt der Steuerpflicht.
      <br>
      Anfallende Steuern k&ouml;nnen Sie mit dem
      <a href="https://www.bmf-steuerrechner.de" target="_blank" rel="noreferrer">Steuerrechner des Bundesfinanzministeriums</a> 
      ermitteln.
    </p>

    <p class="py-3">
      <span class="text-large-strong">Versorgungsausgleich</span><br>
      Wurde zu Lasten einer Beamtin&nbsp;/ eines Beamten ein Versorgungsausgleich durchgef&uuml;hrt, ist nach dem Eintritt in den Ruhestand das Ruhegehalt zu k&uuml;rzen (&#167;&nbsp;72 LBeamtVG).
      Soweit Ihre Versorgungsanwartschaft mit einem Versorgungsausgleich belastet ist oder k&uuml;nftig belastet werden sollte, ist das Ruhegehalt um den jeweils aktuellen K&uuml;rzungsbetrag zu mindern.
    </p>

  </div>
</template>

<script>
import PensionCalculation from "../components/pensionCalculator/PensionCalculation";
export default {
  name: "PensionCalculatorMain.vue",
  components: {PensionCalculation}
}
</script>

<style scoped>
p, h1, h2, h3{
  @apply mb-3
}

.container{
  margin: 1.5rem 1.5rem;
}
</style>