<template>
  <section v-if="hasError || missingResult" class="xl:w-5/6">
    <div class="error-container error-message">
      <h1 class="headline-small text-cc-red-600">
        Bei der Berechnung ist leider etwas schiefgelaufen!
      </h1>
      <div class="mt-2 buttoncontainer-right">
        <button class="btn-primary btn-font-large-prim" @click="goBack">
          Zurück
        </button>
      </div>
    </div>
  </section>
  <section v-else>
    <h1 class="headline-large">Ergebnis</h1>
    <p>
      Aus der mit Hilfe dieses Versorgungsrechners erstellten Berechnung können
      keine Rechtsansprüche hergeleitet werden.
    </p>
    <table class="table-auto">
      <tbody>
        <tr>
          <td class="flex justify-between border">
            <span role="cell" class="mr-4">Berechnung vom:</span>
            <span role="cell">{{ dateDisplay }}</span>
          </td>
        </tr>
        <tr>
          <td class="flex justify-between border">
            <span role="cell" class="mr-4">Geburtsdatum:</span>
            <span role="cell">{{ birthDate }}</span>
          </td>
        </tr>
        <tr>
          <td class="flex justify-between border td-text-left">
            <div role="cell" class="mr-4"> Gesetzliche Altersgrenze {{ ruleToString }}: </div>
            <div role="cell">{{ legalRestAgeDate }}</div>
          </td>
        </tr>
        <tr>
          <td class="flex justify-between border td-text-left">
            <span role="cell" class="mr-4">Versorgungsbeginn bei {{ reasonToString }}:</span>
            <span role="cell">{{ beginnByAge }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div role="table" class="mt-20 grid grid-flow-row w-cc-screen md:w-full overflow-x-auto">
      <div role="row" class="flex justify-start grid grid-flow-col" :class="isRechtUeb?'grid-cols-cc-10-sm md:grid-cols-cc-10-md xl:grid-cols-cc-10-xl':'grid-cols-cc-6-sm md:grid-cols-cc-6-md xl:grid-cols-cc-6-xl'">
        <div role="cell" class="border bg-cc-neutral-300 col-start-5 col-span-2">
          Neues Recht
        </div>
        <div v-if="isRechtUeb" role="cell" class="border bg-cc-neutral-300 col-start-7 col-span-2">
          Altes Recht
        </div>
        <div v-if="isRechtUeb" role="cell" class="border bg-cc-neutral-300 col-start-9 col-span-2 break-all">
          Übergangsrecht
        </div>
      </div>
      <div role="row" class="flex justify-start grid grid-flow-col" :class="isRechtUeb?'grid-cols-cc-10-sm md:grid-cols-cc-10-md xl:grid-cols-cc-10-xl':'grid-cols-cc-6-sm md:grid-cols-cc-6-md xl:grid-cols-cc-6-xl'">
        <div v-if="supplybegin < new Date(2012, 0, 1)" role="cell" class="border bg-cc-neutral-300 col-span-4">
          Berechnungsformel seit 2012:
        </div>
        <div v-if="supplybegin < new Date(2012, 0, 1)" role="cell" class="border bg-cc-neutral-300 col-span-2">
          vH-Satz = Dienstjahre x 1,79375
        </div>
        <div v-if="isRechtUeb && supplybegin < new Date(2012, 0, 1)" role="cell" class="border bg-cc-neutral-300 col-span-2">
          bis zu 10 Dienstjahre = 35%, 11.-25. Jahr je +2%, ab dem 26. Jahr je +1%, Ergebnis x 0,95667
        </div>
        <div v-if="isRechtUeb && supplybegin < new Date(2012, 0, 1)" role="cell" class="border bg-cc-neutral-300 col-span-2">
          Dienstzeit bis 31.12.1991 aber mindestens 10 Jahre nach dem altem
          Recht, danach für jedes Jahr je +1%, Ergebnis x 0,95667
        </div>
      </div>
      <div role="row" class="flex justify-start grid grid-flow-col" :class="isRechtUeb?'grid-cols-cc-10-sm md:grid-cols-cc-10-md xl:grid-cols-cc-10-xl':'grid-cols-cc-6-sm md:grid-cols-cc-6-md xl:grid-cols-cc-6-xl'">
        <span role="cell" class="border bg-cc-neutral-300">Beginn</span>
        <span role="cell" class="border bg-cc-neutral-300">Ende</span>
        <span role="cell" class="border bg-cc-neutral-300">Beschäftigung</span>
        <span role="cell" class="border bg-cc-neutral-300">Teilzeit</span>
        <span role="cell" class="border bg-cc-neutral-300">Jahre</span>
        <span role="cell" class="border bg-cc-neutral-300">Tage</span>
        <span v-if="isRechtUeb" role="cell" class="border bg-cc-neutral-300">Jahre</span>
        <span v-if="isRechtUeb" role="cell" class="border bg-cc-neutral-300">Tage</span>
        <span v-if="isRechtUeb" role="cell" class="border bg-cc-neutral-300">Jahre</span>
        <span v-if="isRechtUeb" role="cell" class="border bg-cc-neutral-300">Tage</span>
      </div>
      <div v-if="resultPeriods.length" class="grid grid-flow-row" :class="isRechtUeb?'grid-cols-cc-10-sm md:grid-cols-cc-10-md xl:grid-cols-cc-10-xl':'grid-cols-cc-6-sm md:grid-cols-cc-6-md xl:grid-cols-cc-6-xl'">
        <the-result-table-row
            role="cell" v-for="(resultEl, idx) in resultPeriods"
            :key="idx"
            :service-periods="resultEl"
        ></the-result-table-row>
      </div>
      <div v-if="resultPeriods.length > 0" role="row" class="grid grid-flow-col" :class="isRechtUeb?'grid-cols-cc-10-sm md:grid-cols-cc-10-md xl:grid-cols-cc-10-xl':'grid-cols-cc-6-sm md:grid-cols-cc-6-md xl:grid-cols-cc-6-xl'">
        <span v-if="zurechAboveZero"
              class="col-span-5 border">
                  Zurechnungszeit
              </span>
        <span v-if="zurechAboveZero"
              role="cell"
              class="border">{{ zurechTimeNeuYear }}</span>
        <span v-if="zurechAboveZero"
              role="cell"
              class="border">{{ zurechTimeNeuDay }}</span>
        <span v-if="zurechAboveZero && isRechtUeb"
              role="cell"
              class="border">{{ zurechTimeAltesRecht.years }}</span>
        <span v-if="zurechAboveZero && isRechtUeb"
              role="cell"
              class="border">{{ zurechTimeAltesRecht.days }}</span>
        <span v-if="zurechAboveZero && isRechtUeb"
              role="cell"
              class="border">{{ zurechTimeUebRecht.years }}</span>
        <span v-if="zurechAboveZero && isRechtUeb"
              role="cell"
              class="border">{{ zurechTimeUebRecht.days }}</span>
      </div>
      <div role="row" class="grid grid-flow-row" :class="isRechtUeb?'grid-cols-cc-10-sm md:grid-cols-cc-10-md xl:grid-cols-cc-10-xl':'grid-cols-cc-6-sm md:grid-cols-cc-6-md xl:grid-cols-cc-6-xl'">
        <span role="cell" class="border h-1.5 bg-cc-border-color rounded-m" :class="isRechtUeb?'col-span-10':'col-span-6'"></span>
      </div>
      <div role="row" class="grid grid-flow-row" :class="isRechtUeb?'grid-cols-cc-10-sm md:grid-cols-cc-10-md xl:grid-cols-cc-10-xl':'grid-cols-cc-6-sm md:grid-cols-cc-6-md xl:grid-cols-cc-6-xl'">
        <span role="cell" class="border col-span-2"></span>
        <span role="cell" class="border col-span-2">Summe:</span>
        <span role="cell" class="border">
          {{timeSums.neuesRecht.years}}
        </span>
        <span role="cell" class="border">
            {{timeSums.neuesRecht.days}}
        </span>
        <span v-if="isRechtUeb" class="border">
          {{timeSums.altesRecht.years}}
        </span>
        <span v-if="isRechtUeb" role="cell" class="border">
          {{timeSums.altesRecht.days}}
        </span>
        <span v-if="isRechtUeb" role="cell" class="border">
          {{timeSums.uebergangsRecht.years}}
        </span>
        <span v-if="isRechtUeb" role="cell" class="border">
          {{timeSums.uebergangsRecht.days}}
        </span>
        <span role="cell" class="border col-span-2"></span>
        <span role="cell" class="border col-span-2">Dienstjahre:</span>
        <span role="cell" class="border">
          {{workYearsNeuesRecht}}
        </span>
        <span v-if="isRechtUeb" role="cell" class="border"></span>
        <span v-if="isRechtUeb" role="cell" class="border">
          {{workYearsAltesRecht}}
        </span>
        <span role="cell" class="border"></span>
        <span v-if="isRechtUeb" role="cell" class="border">
          {{workYearsUebRecht}}
        </span>
        <span v-if="isRechtUeb"
              role="cell"
              class="border"></span>
        <span role="cell" class="border col-span-2"></span>
        <span role="cell" class="border col-span-2">v.H.-Satz:</span>
        <span role="cell" class="border">
          {{ruhegehaltssatz?.neuesRecht || ""}}
        </span>
        <span role="cell" class="border"> </span>
        <span v-if="isRechtUeb" role="cell" class="border">
          {{ruhegehaltssatz?.altesRecht || ""}}
        </span>
        <span v-if="isRechtUeb" role="cell" class="border"> </span>
        <span v-if="isRechtUeb" role="cell" class="border">
          {{ruhegehaltssatz?.uebergangsRecht || ""}}
        </span>
        <span v-if="isRechtUeb" role="cell" class="border"> </span>
      </div>
    </div>
    <div class="infotext" v-html="resultText"></div>
    <div class="flex justify-between">
      <button class="btn-secondary text-large text-cc-blue-600" @click="goBack">Zurück</button>
      <button class="btn-primary text-large text-white" @click="printContent()">Ergebnis drucken</button>
    </div>
    <div class="my-10 h-0.5 bg-cc-border-color"></div>
    <h2 class="headline-large">Berechnung des Brutto-Ruhegehalts</h2>
    <p>Die voraussichtliche Höhe Ihres Ruhegehalts können Sie mit dem folgenden Programm selbst ermitteln.</p>
    <button class="btn-secondary text-large text-cc-blue-800" @click="gotToRuhegehalt">Berechnung des Ruhegehalts</button>
  </section>
</template>

<script>
import TheResultTableRow from "./GeneralFormComponents/UiElements/TheResultTableRow.vue";
import {getReasonFromString, getRuleFromString} from "@/helper/KeyMapping";
import {convertToCommaStr, dateTosTring} from "@/helper/helper";
import cloneDeep from "lodash.clonedeep";

export default {
  components: { TheResultTableRow},
  data() {
    return {
      currentDate: null,
      restDate: null
    };
  },
  computed: {
    resultText(){
      return this.$store.getters.getResult.resultText;  
    },
    supplybegin(){
      return this.$store.getters.getBegin;
    },
    missingResult(){
      return !this.$store.getters.getResult;
    },
    hasError(){
      return !!this.$store.getters.getResult.error;
    },
    dateDisplay() {
      return dateTosTring(new Date(this.currentDate));
    },
    birthDate() {
      const timestamp = this.$store.getters.getBirthdate;
      return dateTosTring(new Date(timestamp));
    },
    birthDateDate(){
      const timestamp = this.$store.getters.getBirthdate;
      return new Date(timestamp);
    },
    servicePeriods() {
      return this.$store.getters.getServicePeriods;
    },
    resultPeriods() {
      return this.$store.getters.getResult.periods;
    },
    result() {
      return this.$store.getters.getResult;
    },
    restAgeDate() {
      const timestamp = this.$store.getters.getResult.regelaltersgrenze;
      if (timestamp) {
        return dateTosTring(new Date(timestamp));
      }
      return "";
    },
    legalRestAgeDate(){
      //Gestzliche Altersgrenze
      const timestamp = this.$store.getters.getResult.gesetzlicheAltersgrenze;
      if (timestamp) {
        return dateTosTring(new Date(timestamp));
      }
      return "";
    },
    beginnByAge() {
      const supplybegin = this.$store.getters.getBegin;
      const supplybeginDate = new Date(supplybegin);
      return dateTosTring(supplybeginDate);
    },
    ruleToString() {
      // return getRuleFromString(
      //   this.$store.getters.getRules.find(rule => rule.isSelected === true)
      // );
      return this.$store.getters.getRules.find(rule => rule.isSelected === true)
        ?.text;
    },
    ruleIdentifier(){
      return getRuleFromString(this.ruleToString);
    },
    reasonToString() {
      return this.$store.getters.getReasons.find(
        reason => reason.isSelected === true
      )?.text;
    },
    reasonIdentifier() {
      return getReasonFromString(this.reasonToString);
    },
    ruhegehaltssatz() {
      const ruhegehaltssatz = this.$store.getters.getResult.ruhegehaltssatz;
      return {
        neuesRecht: convertToCommaStr(ruhegehaltssatz.neuesRecht),
        altesRecht: convertToCommaStr(ruhegehaltssatz.altesRecht),
        uebergangsRecht: convertToCommaStr(ruhegehaltssatz.uebergangsRecht),
        used: convertToCommaStr(ruhegehaltssatz.used)
      }
    },
    timeSums() {
      const workPeriodSum = this.$store.getters.getResult.workPeriodSum;
      return {
        neuesRecht: {
            days: convertToCommaStr(workPeriodSum.neuesRecht.days),
            years: convertToCommaStr(workPeriodSum.neuesRecht.years)
        },
        altesRecht: {
            days: convertToCommaStr(workPeriodSum.altesRecht.days),
            years: convertToCommaStr(workPeriodSum.altesRecht.years)
        },
        uebergangsRecht: {
            days: convertToCommaStr(workPeriodSum.uebergangsRecht.days),
            years: convertToCommaStr(workPeriodSum.uebergangsRecht.years)
        }
      }
    },
    zurechAboveZero(){
      const zurechNeu = this.$store.getters.getResult.zurechTimeNeuesRecht;
      const zurechAlt = this.$store.getters.getResult.zurechTimeAltesRecht;
      const zurechUeb = this.$store.getters.getResult.zurechTimeUebergangsRecht;

      return zurechNeu.days > 0 || zurechNeu.years > 0 || zurechAlt.days > 0 || zurechAlt.years > 0 || zurechUeb.years > 0 || zurechUeb.days > 0;
    },
    zurechTimeNeuYear() {
      return convertToCommaStr(this.$store.getters.getResult.zurechTimeNeuesRecht.years) || "";
    },
    zurechTimeNeuDay() {
      return convertToCommaStr(this.$store.getters.getResult.zurechTimeNeuesRecht.days) || "";
    },
    zurechTimeAltesRecht() {
      return {
        years: convertToCommaStr(this.$store.getters.getResult.zurechTimeAltesRecht.years) || "",
        days: convertToCommaStr(this.$store.getters.getResult.zurechTimeAltesRecht.days) || ""
      };
    },
    zurechTimeUebRecht() {
      return {
        years: convertToCommaStr(this.$store.getters.getResult.zurechTimeUebergangsRecht.years) || "",
        days: convertToCommaStr(this.$store.getters.getResult.zurechTimeUebergangsRecht.days) || ""
      };
    },
    workYearsNeuesRecht() {      
      const years = this.$store.getters.getResult.workPeriodSum.neuesRecht.years;
      const days = this.$store.getters.getResult.workPeriodSum.neuesRecht.days;

      // return Math.round((years + (days / 365) * Number.EPSILON) * 100) / 100;
      return convertToCommaStr((years + days / 365).toFixed(2));
    },
    workYearsAltesRecht() {
      const years = this.$store.getters.getResult.workPeriodSum.altesRecht
        .years;
      const days = this.$store.getters.getResult.workPeriodSum.altesRecht.days;
      if(days > 182){
        return `${years + 1},00`
      }
      return `${years},00`
    },
    workYearsUebRecht() {
      const result = this.$store.getters.getResult;
      const FSTJJBIS = result.fstjjbis;
      const FSTJJAB = result.fstjjab;
      return `${FSTJJBIS} + ${convertToCommaStr(FSTJJAB)}`;
    },
    isRechtNeu() {
      return this.$store.getters.getResult.workPeriodSum.neuesRecht.days ||
        this.$store.getters.getResult.workPeriodSum.neuesRecht.days
        ? true
        : false;
    },
    isRechtAlt() {
      return this.$store.getters.getResult.workPeriodSum.altesRecht.years ||
        this.$store.getters.getResult.workPeriodSum.altesRecht.days
        ? true
        : false;
    },
    isRechtUeb() {
      return this.$store.getters.getResult.workPeriodSum?.uebergangsRecht.years ||
        this.$store.getters.getResult.workPeriodSum?.uebergangsRecht.days
        ? true
        : false;
    }
  },
  methods: {
    gotToRuhegehalt(){
      // this.$router.push({name: "Berechnung"});
      const pageData = this.$router.resolve({name: "Berechnung"});
      window.open(pageData.href, "_blank");
      localStorage.setItem("Ruhegehaltssatz", convertToCommaStr(this.result.ruhegehaltssatz?.used));
      localStorage.setItem("Minderung", convertToCommaStr(this.result.minderungsSatz));
    },
    goBack(){
      // window.location.replace(window.location.href.replace("/result", "/general"));
      this.$router.push({ name: "Timeline" })
    },

    async printContent() {
 
      const resultVars = cloneDeep(this.$store.getters.getResult);
      resultVars.periods = resultVars.periods.map(period => {
        const fromDate = new Date(period.workPeriod.from);
        const toDate = new Date(period.workPeriod.to);

        const yearStrFrom = fromDate.getFullYear();
        const monthStrFrom = fromDate.getMonth() + 1;
        const dayStrFrom = fromDate.getDate();

        const fromDateStr = `${dayStrFrom}.${monthStrFrom}.${yearStrFrom}`;

        const yearStrTo = toDate.getFullYear();
        const monthStrTo = toDate.getMonth() + 1;
        const dayStrTo = toDate.getDate();

        const toDateStr = `${dayStrTo}.${monthStrTo}.${yearStrTo}`;

        const reqPeriod = cloneDeep(period);
        reqPeriod.workPeriod.from = fromDateStr;
        reqPeriod.workPeriod.to = toDateStr;

        return reqPeriod;
      });

      /** @type {import("../../types").Period[]} */
      let tempPeriods = cloneDeep(this.$store.getters.getServicePeriods);

      tempPeriods.forEach(period => {
          period.employment = period.employment?.id;
      });

      const birthdateDate = new Date(this.$store.getters.getBirthdate);
      const supplyBeginnDate = new Date(this.$store.getters.getBegin);
      const selectedRule = this.$store.getters.getRules.find(rule => rule.isSelected === true);

      const calcVars = {
        birthdate: `${birthdateDate.getDate()}.${birthdateDate.getMonth() + 1}.${birthdateDate.getFullYear()}`,
        reason: getReasonFromString(
            this.$store.getters.getReasons.find(reason => reason.isSelected === true).text
        ),
        supplyBeginn: `${supplyBeginnDate.getDate()}.${supplyBeginnDate.getMonth() + 1}.${supplyBeginnDate.getFullYear()}`,
        rule: getRuleFromString(selectedRule?.subText ? selectedRule?.text + " " + selectedRule?.subText : selectedRule?.text),
        timeFrames: tempPeriods.map(/** @type{import("../types").Period} */ period => {
          const fromDate = new Date(period.workPeriod.from);
          const toDate = new Date(period.workPeriod.to);

          const yearStrFrom = fromDate.getFullYear();
          const monthStrFrom = fromDate.getMonth() + 1;
          const dayStrFrom = fromDate.getDate();

          const fromDateStr = `${dayStrFrom}.${monthStrFrom}.${yearStrFrom}`;

          const yearStrTo = toDate.getFullYear();
          const monthStrTo = toDate.getMonth() + 1;
          const dayStrTo = toDate.getDate();

          const toDateStr = `${dayStrTo}.${monthStrTo}.${yearStrTo}`;

          const reqPeriod = cloneDeep(period);
          reqPeriod.workPeriod.from = fromDateStr;
          reqPeriod.workPeriod.to = toDateStr;

          return reqPeriod;
        })
      };

      const body = {
        calcVars,
        resultVars,
        calcDate: new Date(this.currentDate).getTime()
      }

      try {
        const resp = await fetch(window.location.href.replace("/result", "/rest/pdf"), {
          method: "Post",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        });

        const fileContent = await resp.blob();

        // window.open(URL.createObjectURL(fileContent), "_blank");

        const a = document.createElement("a");
        a.id = "PDF-DOWNLOADER";
        a.download = "Berechnungsergebnis.pdf";
        a.href = URL.createObjectURL(fileContent);
        a.onclick = () => {
          setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        };
        a.click();
        document.removeChild(a);
      }catch (e){
        console.error(e);
      }
    },
    test() {
      this.$store.dispatch("sendRequest");
    }
  },
  beforeMount() {
    if(!this.$store.getters.getBirthdate) this.goBack();
    this.currentDate = new Date().getTime();
  }
};
</script>

<style scoped>
     

p{
  @apply mr-5;
  @apply my-5;
}
    
.infotext {
  @apply my-5;   
}
:deep() .infotext > section > p, 
:deep() .infotext > section > div > p{
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.info-grid {
  display: grid;
  grid-template-columns: 30%;
  grid-template-rows: auto;
}

.border {
  margin: 0.5px;
  border: 2px solid #d9dada;
}

.title-item {
  grid-area: title;
  background-color: #f6f6f6;
}

.satz-item {
  grid-area: satz;
  background-color: #f6f6f6;
}

.satz-item-alt {
  grid-area: satzAlt;
  background-color: #f6f6f6;
}

.satz-item-ue {
  grid-area: satzUe;
  background-color: #f6f6f6;
}

.neuRech-item {
  grid-area: neuRech;
  background-color: #f6f6f6;
}

.altRech-item {
  grid-area: altRech;
  background-color: #f6f6f6;
}

.uebRech-item {
  grid-area: uebRech;
  background-color: #f6f6f6;
}

.heading-grid {
  /* padding-right: 2rem; */
  display: grid;
  grid-template-columns: 8% 8% 8% 47% 4% 5% 4% 5% 4% 5%;
  grid-template-rows: auto;
  grid-template-areas: ". . . . neuRech neuRech altRech altRech uebRech uebRech" "title title title title satz satz satzAlt satzAlt satzUe satzUe";
}
/* ########################################################## */
.times-grid-neuRecht {
  display: grid;
  grid-template-columns: 8% 8% 50% 5% 4% 5%;
  grid-template-rows: auto;
}

.times-grid-neuRecht-altuebRecht {
  display: grid;
  grid-template-columns: 8% 8% 50% 5% 4% 5% 4% 5% 4% 5%;
  /*grid-template-columns: minmax(8%, auto) minmax(8%, a) minmax() minmax() minmax() minmax() minmax() minmax() minmax() minmax(); */
  grid-template-rows: auto;
}
/* ########################################################## */
.title-item-head1 {
  grid-area: title1;
  background-color: #f6f6f6;
}
.title-item-head2 {
  grid-area: title2;
  background-color: #f6f6f6;
}
.title-item-head3 {
  grid-area: title3;
  background-color: #f6f6f6;
}
.title-item-head4 {
  grid-area: title4;
  background-color: #f6f6f6;
}

.satz-item-head1 {
  grid-area: satz1;
  background-color: #f6f6f6;
}
.satz-item-head2 {
  grid-area: satz2;
  background-color: #f6f6f6;
}

.satz-item-head1-alt {
  grid-area: satz1Alt;
  background-color: #f6f6f6;
}
.satz-item-head2-alt {
  grid-area: satz2Alt;
  background-color: #f6f6f6;
}

.satz-item-head1-ue {
  grid-area: satz1Ue;
  background-color: #f6f6f6;
}
.satz-item-head2-ue {
  grid-area: satz2Ue;
  background-color: #f6f6f6;
}

.times-head-grid {
  display: grid;
  grid-template-columns: 8% 8% 50% 5% 4% 5% 4% 5% 4% 5%;
  grid-template-rows: auto;
  grid-template-areas: "title1 title2 title3 title4 satz1 satz2 satz1Alt satz2Alt satz1Ue satz2Ue";
}
/* ########################################################## */
.sep-item {
  grid-area: sep;
  background-color: #acacac;
  height: 2px;
}

.sum-item {
  grid-area: sum;
}

.sumyear-item {
  grid-area: sumyear;
}

.sumday-item {
  grid-area: sumday;
}

.sumAltYear-item {
  grid-area: sumAltYear;
}

.sumAltDay-item {
  grid-area: sumAltDay;
}

.sumUebYear-item {
  grid-area: sumUebYear;
}

.sumUebDay-item {
  grid-area: sumUebDay;
}

.servyears-item {
  grid-area: servyears;
}

.servyear-item {
  grid-area: servyear;
}

.servday-item {
  grid-area: servday;
}

.servAltYear-item {
  grid-area: servAltYear;
}

.servAltDay-item {
  grid-area: servAltDay;
}

.servUebYear-item {
  grid-area: servUebYear;
}

.servUebDay-item {
  grid-area: servUebDay;
}

.finalsatz-item {
  grid-area: finalsatz;
}

.satzyear-item-neu {
  grid-area: satzyear;
}

.satzday-item {
  grid-area: satzday;
}

.satzyear-item-alt {
  grid-area: satzyearAlt;
}

.satzday-item-alt {
  grid-area: satzdayAlt;
}

.satzyear-item-ueb {
  grid-area: satzyearUeb;
}

.satzday-item-alt {
  grid-area: satzdayUeb;
}

.result-grid {
  display: grid;
  grid-template-columns: 8% 8% 50% 5% 4% 5%;
  grid-template-rows: auto;
  grid-template-areas: "sep sep sep sep sep sep" ". sum sum sum sumyear sumday" ". servyears servyears servyears servyear servday" ". finalsatz finalsatz finalsatz satzyear satzday";
  /* grid-template-areas:
    "sep sep sep sep sep sep"
    ". sum sum sum sumyear sumday"
    ". servyears servyears servyears servyear servday"
    ". finalsatz fialsatz finalsatz satzyear satzday";
  */
}

.result-grid-neu-alt-ueb-recht {
  display: grid;
  grid-template-columns: 8% 8% 50% 5% 4% 5% 4% 5% 4% 5%;
  grid-template-rows: auto;
  grid-template-areas: "sep sep sep sep sep sep sep sep sep sep" ". sum sum sum sumyear sumday sumAltYear sumAltDay sumUebYear sumUebDay" ". servyears servyears servyears servyear servday servAltYear servAltDay servUebYear servUebDay" ". finalsatz finalsatz finalsatz satzyear satzday satzyearAlt satzdayAlt satzyearUeb satzdayUeb";
}

/**displayes the element from the first column to the fith column */
.col-span-5 {
  grid-column: 1 / 5;
}
/* ########################################################## */
.spacer {
  margin-bottom: 5rem;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #d9dada;
  padding: 5px;
}

.error-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.container-center {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.error-message {
  background-color: #ffe8e8;
  /*border: 2px solid #2c2e35;*/
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  /*width: 50%;*/
}

.buttoncontainer-right {
    display: flex;
    justify-content: right;
    align-items: center;
}
.buttoncontainer-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spacer {
  width: 100vw;
  height: 2px;
  margin: 3rem 0px;
  background-color: lightgray;
  border-radius: 50px;
}
</style>
