<template>
  <div class="rounded-lg pb-3 grid grid-flow-row w-cc-screen md:w-full overflow-x-auto">
    <div role="row" class="bg-cc-neutral-300 border-none rounded-tl-lg grid grid-flow-col grid-cols-cc-6">
      <span class="flex justify-center border-r-2 border-gray-600">Zeile</span>
      <div class="flex justify-center bg-cc-neutral-300 border-r-2 border-gray-600">
        Startdatum - Enddatum
      </div>
      <div class="flex justify-center bg-cc-neutral-300 border-r-2 border-gray-600">
        Beschäftigung
      </div>
      <div class="flex justify-center bg-cc-neutral-300 border-r-2 border-gray-600">
        Teilzeitbruch
      </div>
      <div class="flex justify-center border-none bg-cc-neutral-300 border-r-2 border-gray-600">
        Höchst. anrechenbar
      </div>
      <div class="border-none bg-cc-neutral-300 rounded-tr-lg">

      </div>
    </div>
    <slot></slot>
  </div>
<!--  <div class="rounded-lg pb-3 grid grid-flow-row w-cc-screen md:w-full overflow-x-scroll">-->
<!--    <table class="">-->
<!--      <thead>-->
<!--        <tr>-->
<!--          <th class="bg-cc-neutral-300 border-none rounded-tl-lg">-->
<!--            <span class="mx-3">Zeile</span>-->
<!--          </th>-->
<!--          <th class="bg-cc-neutral-300">-->
<!--            Zeitraum von - bis-->
<!--          </th>-->
<!--          <th class="bg-cc-neutral-300">-->
<!--            Beschäftigung-->
<!--          </th>-->
<!--          <th class="bg-cc-neutral-300">-->
<!--            Teilzeitbruch-->
<!--          </th>-->
<!--          <th class="border-none bg-cc-neutral-300">-->
<!--            Höchst. anrechenbar-->
<!--          </th>-->
<!--          <th class="border-none bg-cc-neutral-300 rounded-tr-lg">-->
<!--          </th>-->
<!--        </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--        <slot></slot>-->
<!--      </tbody>-->
<!--    </table>-->
<!--  </div>-->

<!--  <div class="grid grid-cols-6 grid-flow-row auto-cols-auto">-->
<!--    <div id="zeile-head" role="columnheader" class="bg-cc-neutral-300">-->
<!--      <div>Zeile</div>-->
<!--    </div>-->
<!--    <div role="columnheader" class="bg-cc-neutral-300">-->
<!--      <div>| Zeitraum von - bis</div>-->
<!--    </div>-->
<!--    <div role="columnheader" class="bg-cc-neutral-300">-->
<!--      | Beschäftigung-->
<!--    </div>-->
<!--    <div role="columnheader" class="bg-cc-neutral-300">-->
<!--      | Teilzeitbruch-->
<!--    </div>-->
<!--    <div role="columnheader" class="bg-cc-neutral-300" aria-label="Höchstens anrechenbar">-->
<!--      | Höchst. anrechenbar-->
<!--    </div>-->
<!--    <slot></slot>-->
<!--  </div>-->
<!--  <div role="table" class="grid auto-cols-auto grid-flow-row">-->
<!--    <div class="grid auto-cols-max grid-flow-col-dense row">-->
<!--      <div id="zeile-head" role="columnheader" class="bg-cc-neutral-300">-->
<!--        <div>Zeile</div>-->
<!--      </div>-->
<!--      <div role="columnheader" class="bg-cc-neutral-300">-->
<!--        <div>| Zeitraum von - bis</div>-->
<!--      </div>-->
<!--      <div role="columnheader" class="bg-cc-neutral-300">-->
<!--        | Beschäftigung-->
<!--      </div>-->
<!--      <div role="columnheader" class="bg-cc-neutral-300">-->
<!--        | Teilzeitbruch-->
<!--      </div>-->
<!--      <div role="columnheader" class="bg-cc-neutral-300" aria-label="Höchstens anrechenbar">-->
<!--        | Höchst. anrechenbar-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="grid grid-flow-col auto-cols-auto auto-rows-max">-->
<!--      <div class="flex justify-start items-center">-->
<!--        <slot></slot>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div role="table" class="grid-container-input">-->
<!--    <div id="zeile-head" role="columnheader" class="header flex-start-center">-->
<!--      <div>Zeile</div>-->
<!--    </div>-->
<!--    <div role="columnheader" class="header1 flex-start-center">-->
<!--      <div>Zeitraum | von - bis</div>-->
<!--    </div>-->
<!--    <div role="columnheader" class="header2 flex-start-center">-->
<!--      Beschäftigung-->
<!--    </div>-->
<!--    <div role="columnheader" class="header3 flex-start-center">-->
<!--      Teilzeitbruch-->
<!--    </div>-->
<!--    <div-->
<!--      role="columnheader"-->
<!--      class="header4 flex-start-center"-->
<!--      aria-label="Höchstens anrechenbar"-->
<!--    >-->
<!--      Höchst. anrechenbar-->
<!--    </div>-->
<!--    <div role="columnheader" class="header5 flex-start-center"></div>-->
<!--    <slot></slot>-->
<!--  </div>-->
</template>

<script>
export default {};
</script>

<style scoped>
th{
  @apply border-t-0;
  @apply border-b-0;
  @apply border-l-2;
  @apply border-r-2;
}
.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.grid-container-input {
  display: grid;
  grid-template-columns: auto auto auto auto auto 5%;
  grid-template-rows: auto;
  grid-template-areas: "header header1 header2 header3 header4 header5" "content content1 content2 content3 content4 content5";

  row-gap: 1rem;

  align-items: center;
}

.header {
  height: 32px;
  grid-area: header;
  background: #eceded;
}

.header1 {
  height: 32px;
  grid-area: header1;
  background: #eceded;
}

.header2 {
  height: 32px;
  grid-area: header2;
  background: #eceded;
}

.header3 {
  height: 32px;
  grid-area: header3;
  background: #eceded;
}

.header4 {
  height: 32px;
  grid-area: header4;
  background: #eceded;
}

.header5 {
  height: 32px;
  grid-area: header5;
  background: #eceded;
}

/* .content {
  grid-area: content;
  justify-self: center;
} */

.flex-start-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
