<template>
  <input
    :data-test-matInp="`${typeString}-day`"
    role="textbox"
    aria-label="Tag Eingabe"
    aria-placeholder="Tag"
    :class="{ invalid: dayInvalid }"
    :id="refIds[0]"
    :ref="refIds[0]"
    type="text"
    :placeholder="placeholder[0]"
    :disabled="disabledInputs[0]"
    maxlength="2"
    v-model="inputValueDay"
    @input="onInputDay"
    @focus="focus(0)"
    @blur="blur()"
  />
  <input
    :data-test-matInp="`${typeString}-month`"
    role="textbox"
    aria-label="Monat Eingabe"
    aria-placeholder="Monat"
    :class="{ invalid: monthInvalid }"
    :id="refIds[1]"
    :ref="refIds[1]"
    type="text"
    :placeholder="placeholder[1]"
    :disabled="disabledInputs[1]"
    maxlength="2"
    v-model="inputValueMonth"
    @input="onInputMonth"
    @focus="focus(1)"
    @blur="blur()"
  />
  <input
    :data-test-matInp="`${typeString}-year`"
    role="textbox"
    aria-label="Jahr Eingabe"
    aria-placeholder="Jahr"
    :class="{ invalid: yearInvalid }"
    :id="refIds[2]"
    :ref="refIds[2]"
    type="text"
    :placeholder="placeholder[2]"
    :disabled="disabledInputs[2]"
    maxlength="4"
    v-model="inputValueYear"
    @input="onInputYear"
    @focus="focus(2)"
    @blur="onBlurYear"
  />
</template>

<script>
import { checkIfLeapYear, getDaysInMonth, timeStampToDateString } from "@/helper/helper";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(isSameOrBefore);

export default {
  emits: ["dateConstructed", "input-invalid", "focus-next", "propagate-refs"],
  props: {
    classes: String,
    typeString: String,
    placeholder: Array,
    disabledInputs: Array,
    initValues: Array,
    refIds: Array,
    colNum: Number,
    initialFocus: Boolean,
    resetContext: Object,
    idx: Number,
    revalidateBus: Object,
    periodErrCtx: Object,
    rowId: Number,
    interactionCtx: Object
  },
  data() {
    return {
      focused: false,
      inputInFocus: -1,
      inputValueDay: "",
      inputValueMonth: "",
      inputValueYear: "",
      dayInvalid: false,
      monthInvalid: false,
      yearInvalid: false,
      checkForChar: /\D/
    };
  },
  computed: {
    inputs() {
      const dayInp = this.$refs.dayInp;
      const monthInp = this.$refs.monthInp;
      const yearInp = this.$refs.yearInp;
      return [dayInp, monthInp, yearInp];
    }
  },
  methods: {
    /**
     *
     * @param {Boolean} shouldBubble
     * @returns {{valid: boolean, reason: string}}
     */
    revalidate(shouldBubble){
      this.dayInvalid = false;
      this.monthInvalid = false;
      this.yearInvalid = false;

      this.validateDay();
      this.checkDayForZero();
      this.validateMonth();
      this.checkMonthZero();
      this.validateYear();

      if(this.inputValueYear !== "" && this.inputValueYear.length < 4){
        this.$emit("input-invalid", { key: this.typeString, action: "set", type: "yearLength", msg: "Bitte geben Sie das Jahr vierstellig ein." });
        this.yearInvalid = true;
      }else {
        this.$emit("input-invalid", {key: this.typeString, action: "reset", type: "yearLength"});
      }

      this.checkIfDateIsAfterSupplyBegin();
      this.checkForPeriodOrder(shouldBubble);
      this.checkIfBeforeBirthdate()

      const valid = !this.dayInvalid && !this.monthInvalid && !this.yearInvalid && !this.periodErr;
      return { valid, reason: ""}
    },
    onInputDay(){
      this.validateDay();
      if (this.inputValueDay.length === 2) {
        this.$refs[this.refIds[1]]?.focus();
      }
    },
    onInputMonth(){
      this.validateMonth();
      if (this.inputValueMonth.length === 2) {
        this.$refs[this.refIds[2]]?.focus();
      }
    },
    onInputYear(){
      this.validateYear();
    },
    checkForDaysInMonth(){
      const monthNum = parseInt(this.inputValueMonth);
      const dayNum = parseInt(this.inputValueDay);
      const yearNum = parseInt(this.inputValueYear);

      if(this.inputValueMonth && !isNaN(monthNum) && this.inputValueYear && !isNaN(dayNum)){
        if(dayNum > getDaysInMonth(yearNum, monthNum)){
          this.dayInvalid = true;
          this.$emit("input-invalid", {
            key: this.typeString,
            action: "set",
            type: "day-month-out-bounds",
            msg:
                "Dieser Monat hat nur "+ getDaysInMonth(yearNum, monthNum) +" Tage, Sie haben " + dayNum + " Tage eingegeben.",
            inpElem: "day"
          });
          this.$emit("input-invalid", {action: "reset", key: this.typeString, type: "day"});
        }else{
          this.$emit("input-invalid", {action: "reset", key: this.typeString, type: "day-month-out-bounds"});
        }
      }
    },
    checkIfDateIsAfterSupplyBegin(){
      const date = new Date(
          parseInt(this.inputValueYear),
          parseInt(this.inputValueMonth) - 1,
          parseInt(this.inputValueDay)
      );

      if(date >= new Date(this.$store.getters.getBegin)){
        this.$emit("input-invalid", {
          key: this.typeString,
          action: "set",
          type: "period-supp-beg",
          msg: `Der Zeitraum muss vor dem Versorgungsbeginn (${timeStampToDateString(this.$store.getters.getBegin)}) enden.`
        });
        this.dayInvalid = true;
        this.monthInvalid = true;
        this.yearInvalid = true;
      }
      else{
        this.$emit("input-invalid", { key: this.typeString, action: "reset", type: "period-supp-beg" });
      }
    },
    /**
     *
     * @param {Boolean} shouldBubble
     */
    checkForPeriodOrder(shouldBubble){
      const compType = this.typeString;
      let startDate;
      let endDate;
      if(compType === "from"){
        startDate = new Date(
            parseInt(this.inputValueYear),
            parseInt(this.inputValueMonth) - 1,
            parseInt(this.inputValueDay)
        );

        const period = this.$store.getters.getServicePeriods.find(period => period.id === this.rowId);
        const toTimestamp = period?.workPeriod?.to;

        if(toTimestamp !== null && toTimestamp !== undefined){
          endDate = new Date(toTimestamp);
        }
      } else {
        endDate = new Date(
            parseInt(this.inputValueYear),
            parseInt(this.inputValueMonth) - 1,
            parseInt(this.inputValueDay)
        );

        const period = this.$store.getters.getServicePeriods.find(period => period.id === this.rowId);
        const fromTimestamp = period?.workPeriod?.from;

        if(fromTimestamp !== null && fromTimestamp !== undefined){
          startDate = new Date(fromTimestamp);
        }
      }

      if(!isNaN(startDate?.getTime()) && !isNaN(endDate?.getTime()) && startDate > endDate){
        this.$emit("input-invalid",{
          key: "period",
          action: "set",
          type: "period-order",
          msg: "Das Startdatum muss vor dem Enddatum liegen!"
        });

        this.dayInvalid = true;
        this.monthInvalid = true;
        this.yearInvalid = true;
      } else {
        this.$emit("input-invalid",{
          key: "period",
          action: "reset",
          type: "period-order",
        });
      }

      if(shouldBubble){
        if(this.typeString === "from"){
          this.periodErrCtx.emit("period-changed", {type: "to", shouldBubble: false});
        } else if(this.typeString === "to") {
          this.periodErrCtx.emit("period-changed", {type: "from", shouldBubble: false});
        }
      }
    },
    checkIfBeforeBirthdate(){
      console.log("MaterialDateInput | checkIfBeforeBirthdate | dateStr", `${this.inputValueYear}-${this.inputValueMonth}-${this.inputValueDay}`);
      if(this.inputValueDay === "" || this.inputValueMonth === "" || this.inputValueYear === "")
        return;
      const startDate = dayjs(`${this.inputValueYear}-${this.inputValueMonth}-${this.inputValueDay}`);
      const birthdate = dayjs(this.$store.getters.getBirthdate);
      let errMsg;

      if(this.typeString === "from"){
        errMsg = "Das Startdatum darf nicht vor dem Geburtstag liegen.";
      }else {
        errMsg = "Das Enddatum darf nicht vor dem Geburtstag liegen.";
      }

      console.log("MaterialDateInput | checkIfBeforeBirthdate | startDate", startDate.toString());
      console.log("MaterialDateInput | checkIfBeforeBirthdate | birthdate", birthdate.toString());
      console.log("MaterialDateInput | checkIfBeforeBirthdate | startDate.isValid", startDate.isValid());
      if(!startDate.isValid() || !birthdate.isValid())
        return;

      console.log("MaterialDateInput | checkIfBeforeBirthdate | isSameOrBefore", startDate.isSameOrBefore(birthdate));
      if(startDate.isSameOrBefore(birthdate)){
        this.$emit("input-invalid",{
          key: "period",
          action: "set",
          type: "period-start",
          msg: errMsg
        });

        this.dayInvalid = true;
        this.monthInvalid = true;
        this.yearInvalid = true;
      }else{
        this.$emit("input-invalid",{ key: "period", action: "reset", type: "period-start" });
      }
    },
    checkDayForZero(){
      const dayNumVal = parseInt(this.inputValueDay);
      if(isNaN(dayNumVal) || dayNumVal === null)
        return;

      if(dayNumVal === 0){
        this.$emit("input-invalid", {
          key: this.typeString,
          action: "set",
          type: "dayZero",
          msg:
              "Für den Tag sind nur Eingaben größer 0 erlaubt.",
          inpElem: "day"
        });
        this.dayInvalid = true;
      }else {
        this.$emit("input-invalid", { key: this.typeString, action: "reset", type: "day" });
      }
    },
    checkMonthZero(){
      const monthNumVal = parseInt(this.inputValueMonth);
      if(isNaN(monthNumVal) || monthNumVal === null)
        return;

      if(monthNumVal === 0){
        this.$emit("input-invalid", {
          key: this.typeString,
          action: "set",
          type: "monthZero",
          msg:
              "Für den Monat sind nur Eingaben größer 0 erlaubt.",
          inpElem: "month"
        });
        this.monthInvalid = true;
      }else {
        this.$emit("input-invalid", { key: this.typeString, action: "reset", type: "monthZero" });
      }
    },
    checkPlausi(){
      if(this.typeString !== "to")
      {
        this.$emit("input-invalid", { key: this.typeString, action: "reset", type: "plausi"});
        return { valid: true, reason: ""};
      }

      /** @type{import("@/types").Period} */
      const servicePeriod = this.$store.getters.getServicePeriods.find(period => period.id === this.rowId && period.employment.text.includes("0657"));
      if(!servicePeriod) {
        this.$emit("input-invalid", { key: this.typeString, action: "reset", type: "plausi"});
        return {valid: true, reason: ""};
      }

      const startTs = servicePeriod.workPeriod.from;
      const stopTs = servicePeriod.workPeriod.to;
      const startDate = dayjs(startTs);
      const stopDate = dayjs(stopTs);

      let reason = "";

      let sixMonthsAfter = startDate.add(6, "month");
      sixMonthsAfter = sixMonthsAfter.subtract(1, "day");
      if(stopDate.isAfter(sixMonthsAfter)) {
        reason = "Ein Zeitraum in Erziehungszeit darf nur 6 Monate betragen.";
        this.$emit("input-invalid", { key: this.typeString, action: "set", type: "plausi", msg: reason});

        this.dayInvalid = false;
        this.monthInvalid = false;
        this.yearInvalid = false;

        return { valid: false, reason};
      }
      this.$emit("input-invalid", { key: this.typeString, action: "reset", type: "plausi"});
      return { valid: true, reason};
    },
    validateDay() {
      const daysVal = parseInt(this.inputValueDay);

      if(this.inputValueDay === ""){
        this.$emit("input-invalid", {action: "reset", key: this.typeString, type: "day-month-out-bounds"});
        this.$emit("input-invalid", {action: "reset", key: this.typeString, type: "day"});
        return;
      }


      //################################################################
      // check if the input isn't a number

      this.dayInvalid = this.checkForChar.test(this.inputValueDay);

      if (this.dayInvalid) {
        const newVal = daysVal.toString();
        this.inputValueDay = isNaN(daysVal) ? "" : newVal;
        return;
      }

      //################################################################
      // when the input is a number check it's valitdity

      //check if the number is the range between 1 and 31
      if (!isNaN(daysVal) && (daysVal > 31 || daysVal < 0)) {
        this.dayInvalid = true;
        this.$refs[this.refIds[0]].invalid = true;
        this.$emit("input-invalid", {
          key: this.typeString,
          action: "set",
          type: "day",
          msg:
            "Für den Tag sind nur Eingaben im Bereich von 1 bis 31 erlaubt.",
          inpElem: "day"
        });
        this.$emit("input-invalid", {action: "reset", key: this.typeString, type: "day-month-out-bounds"});
        return;
      }

      //################################################################
      //Check if the provided number of days are higher than the max number of days in the month
      if(this.inputValueDay && this.inputValueMonth && this.inputValueYear){
        this.checkForDaysInMonth();
      }

      //################################################################
      //if there ist still a period error
      if(this.periodErr){
        this.dayInvalid = true;
        this.constructDate();
        return;
      }

      //################################################################
      // if no validation error occured

      if (!this.dayInvalid && this.inputValueMonth !== "" && this.inputValueYear !== "") {
        this.$emit("input-invalid", { action: "reset", key: this.typeString, type: "day" });
        this.constructDate();
      }else if(!this.dayInvalid){
        if(!this.periodErr) {
          this.$emit("input-invalid", {action: "reset", key: this.typeString, type: "day"});
        }
      }
    },
    validateFebDay() {
      const day = this.inputValueDay;
      const month = this.inputValueMonth;
      const year = this.inputValueYear;

      //########################################
      //Checks for Day, Month and Year
      if (!day || !month || !year || year.length < 4) {
        return;
      }

      const monthNum = parseInt(month);

      if(monthNum !== 2){
        return;
      }

      if(monthNum === 2 && checkIfLeapYear(parseInt(year))){
        if(parseInt(day) > 29){
          this.$emit("input-invalid", { action: "set", type: "day-feb", msg: "Für ein Schaltjahr sind im Februar nur 29 Tage zulässig." });
          this.dayInvalid = true;
          return;
        }
      } else if(monthNum === 2) {
        if(parseInt(day) > 28) {
          this.$emit("input-invalid", { action: "set", type: "day-feb", msg: "Im Februar sind regulär nur 28 Tage zulässig." });
          this.dayInvalid = true;
          return;
        }
      } 

      this.dayInvalid = false;
    },
    validateMonth() {
      const monthVal = parseInt(this.inputValueMonth);

      //################################################################
      // check if the input isn't a number
      this.monthInvalid = this.checkForChar.test(this.inputValueMonth);

      if (this.monthInvalid) {
        const newVal = monthVal.toString();
        this.inputValueMonth = isNaN(monthVal) ? "" : newVal;
        return;
      }

      //################################################################
      // If max number of Months is exceeded

      if (!isNaN(monthVal) && monthVal > 12) {
        this.monthInvalid = true;
        this.$emit("input-invalid", {
          key: this.typeString,
          action: "set",
          type: "month",
          msg:
            "Für den Monat sind nur Eingaben im Bereich von 1 bis 12 erlaubt.",
          inpElem: "month"
        });
        return;
      }
      //################################################################
      //Check if the provided number of days are higher than the max number of days in the month
      if(this.inputValueDay && this.inputValueMonth && this.inputValueYear){
        this.checkForDaysInMonth();
      }

      //################################################################
      //if there ist stilla an period error
      if(this.periodErr){
        this.monthInvalid = true;
        this.constructDate();
        return;
      }

      //################################################################
      // if no validation error occured

      if (!this.monthInvalid && this.inputValueDay !== "" && this.inputValueYear !== "") {
        this.$emit("input-invalid", { action: "reset", key: this.typeString, type: "month" });
        this.monthInvalid = false;
        this.constructDate();
      }
      else if(!this.monthInvalid){
        this.$emit("input-invalid", { action: "reset", key: this.typeString, type: "month" });
        this.monthInvalid = false;
      }
    },
    validateYear() {
      const yearVal = parseInt(this.inputValueYear);

      if(this.inputValueYear === ""){
        this.$emit("input-invalid", { action: "reset", key: this.typeString, type: "year" });
        this.$emit("date-constructed", null, this.typeString);
        return;
      }

      if(yearVal === 0){
        this.$emit("input-invalid", {
          key: this.typeString,
          action: "set",
          type: "year",
          msg: "Ein Jahr darf nicht mit einer 0 starten!",
          inpElem: "year"
        });
      }

      if(this.inputValueYear.length < 4) {
        this.$emit("date-constructed", null, this.typeString);
        return;
      }
      //################################################################
      // check if the input isn't a number

      this.yearInvalid = this.checkForChar.test(this.inputValueYear);

      if (this.yearInvalid) {
        const newVal = yearVal.toString();
        this.inputValueYear = isNaN(yearVal) ? "" : newVal;
        return;
      }

      //################################################################
      // check if the year is in a sensible range
      if (!isNaN(yearVal) && yearVal < 1950) {
        // this.inputValueYear = "";
        this.yearInvalid = true;
        this.$emit("input-invalid", {
          key: this.typeString,
          action: "set",
          type: "year",
          msg: "Für ein Jahr sind nur Eingaben ab 1950 erlaubt.",
          inpElem: "year"
        });
        return;
      }
      //################################################################
      //Check if the provided number of days are higher than the max number of days in the month
      if(this.inputValueDay && this.inputValueMonth && this.inputValueYear){
        this.checkForDaysInMonth();
      }

      //################################################################
      //if there ist stilla an period error
      if(this.periodErr){
        this.yearInvalid = true;
        this.constructDate();
        return;
      }

      //################################################################
      // if no validation error occurred
      if (!this.yearInvalid && this.inputValueMonth !== "" && this.inputValueDay !== "") {
        this.$emit("input-invalid", { action: "reset", key: this.typeString, type: "year" });
        this.constructDate();
        return;
      }
      this.$emit("input-invalid", { action: "reset", key: this.typeString, type: "year" });
      },
      constructDate() {

      if(this.inputValueYear.length < 4){
        this.$emit("date-constructed", null, this.typeString);
        return;
      }

      if(this.dayInvalid || this.monthInvalid || this.yearInvalid){
        this.$emit("date-constructed", null, this.typeString);
        return;
      }

      const date = new Date(
        parseInt(this.inputValueYear),
        parseInt(this.inputValueMonth) - 1,
        parseInt(this.inputValueDay)
      );

      this.checkIfDateIsAfterSupplyBegin();

      this.$emit("date-constructed", date, this.typeString);
    },
    focus(idx) {
      this.focused = true;
      this.inputInFocus = idx;
      this.revalidate(true);
    },
    blur() {
      this.focused = false;
      this.revalidate(true);
    },
    onBlurYear(){
      this.blur();
    },
    keyDown(event) {
      if (this.inputInFocus === -1) return;

      if (this.focused && event.keyCode == 39) {
        if (this.inputInFocus + 1 <= 2) {
          this.inputs[this.inputInFocus].blur();

          this.inputInFocus++;

          this.inputs[this.inputInFocus].focus();
          this.inputs[this.inputInFocus].select();
        }
      } else if (this.focused && event.keyCode == 37) {
        if (this.inputInFocus - 1 >= 0) {
          this.inputs[this.inputInFocus].blur();

          this.inputInFocus--;

          this.inputs[this.inputInFocus].focus();
          this.inputs[this.inputInFocus].select();
        }
      }
    },
    reset({shouldBubble}) {
      this.inputValueDay = "";
      this.inputValueMonth = "";
      this.inputValueYear = "";
      this.revalidate(shouldBubble);
    },
    // timelinePageErrAdded(timelinePageErrs, rowNum){
    //   timelinePageErrs.forEach(err => {
    //     if(err.index === rowNum && err.period?.length > 0){
    //       this.monthInvalid = true;
    //       this.dayInvalid = true;
    //       this.yearInvalid = true;
    //       this.periodErr = true;
    //     }
    //   })
    // },
    // timelinePageErrRemoved(timelinePageErrs, rowNum){
    //   let errFound = false;
    //   timelinePageErrs.forEach(err => {
    //     if(err.index === rowNum && err.period?.length > 0){
    //       errFound = true;
    //     }
    //   })
    //   if(!errFound){
    //     this.monthInvalid = false;
    //     this.dayInvalid = false;
    //     this.yearInvalid = false;
    //     this.periodErr = false;
    //   }
    //   // if(!timelinePageErrs[rowNum] || timelinePageErrs[rowNum]?.period.length === 0){
    //   //   this.monthInvalid = false;
    //   //   this.dayInvalid = false;
    //   //   this.yearInvalid = false;
    //   // }
    // },
    /**
     *
     * @param {import("@/types").ValidationErrType[]} validationErrs
     */
    rerunValidation(validationErrs){
      validationErrs.forEach( err => {
        if(err.index === this.idx){
          let fromDayErr;
          let fromMonthErr;
          let fromYearErr;

          let toDayErr;
          let toMonthErr;
          let toYearErr;

          if(this.typeString === "from"){
            fromDayErr = err.from.find(fromErr => fromErr.key === "day");
            fromMonthErr = err.from.find(fromErr => fromErr.key === "month");
            fromYearErr = err.from.find(fromErr => fromErr.key === "year");
          }

          if(this.typeString === "to"){
            toDayErr = err.to.find(toErr => toErr.key === "day");
            toMonthErr = err.to.find(toErr => toErr.key === "month");
            toYearErr = err.to.find(toErr => toErr.key === "year");
          }

          if((fromDayErr || toDayErr) && !isNaN(this.inputValueDay)){
            this.dayInvalid = true;
          }
          if((fromMonthErr || toMonthErr) && !isNaN(this.inputValueMonth)){
            this.monthInvalid = true;
          }
          if((fromYearErr || toYearErr) && !isNaN(this.inputValueYear)){
            this.yearInvalid = true;
          }
        }
      })
    }
  },
  mounted() {
    this.periodErrCtx.on("period-changed", ({type, shouldBubble}) => {
      if(type === this.typeString)
        this.revalidate(shouldBubble);
    });

    this.$store.getters.getTimelinePageErrCtx.on("err-added", timelinePageErrs => {
      this.timelinePageErrAdded(timelinePageErrs, this.idx)
    });

    this.$store.getters.getTimelinePageErrCtx.on("err-removed", timelinePageErrs => {
      this.timelinePageErrRemoved(timelinePageErrs, this.idx);
    });

    this.$store.getters.getValidationContext.on("rerun-validation", this.rerunValidation);

    if (this.initialFocus) this.$refs[this.refIds[0]].focus();

    try {
      this.resetContext?.on("reset", this.reset);
    }catch(err) {
      if(process.env.NODE_ENV === "development"){
        console.log(err);
      }
    }

    this.$emit("propagate-refs", { ...this.$refs });
    document.addEventListener("keydown", this.keyDown);
    let index = 0;
    if (this.initValues && this.initValues.length > 0) {
      this.initValues.forEach(val => {
        if (val) {
          switch (index) {
            case 0:
              this.inputValueDay = val;
              break;
            case 1:
              this.inputValueMonth = val;
              break;
            case 2:
              this.inputValueYear = val;
              break;
            default:
              break;
          }
        }
        index++;
      });
    }

    this.revalidateBus.add(`${this.typeString}-${this.idx}`, this.revalidate);
    this.revalidateBus.add(`${this.typeString}-${this.idx}-plausiCheck`, this.checkPlausi);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keyDown);
    this.resetContext?.off("reset")
    this.revalidateBus.remove(`${this.typeString}-${this.idx}`);
    this.revalidateBus.remove(`${this.typeString}-${this.idx}-plausiCheck`);
  }
};
</script>

<style scoped>
input:disabled {
  background-color: #f6f6f6;
  border-bottom: 0px solid #d9dada;
}
input {
  border: 0px;
  border-bottom: 2px solid #acacac;
  box-sizing: border-box;

  width: 58px;
  height: 40px;

  text-align: center;
  margin-right: 8px;
}

input:focus {
  outline: none;
}

.invalid {
  border-bottom-color: #e30613;
}
</style>
