<template>
  <NavBar />
  <router-view></router-view>
  <!-- <div class="grid-container">
    <SideNav class="side-nav" />
    <div class="main-content">
      <router-view />
    </div>
  </div> -->
</template>

<script>
/**
 * This Compononent is the entrypoint for the application.
 */
import NavBar from "@/components/TitleBar";
// import SideNav from "./components/SideNav";
import "./assets/tailwind.css"

export default {
  components: {
    NavBar
    // SideNav,
  },
  mounted(){
    window.onload = () => {
      this.$store.dispatch("fetchEmploymentKeys");
    }
  }
  
};
</script>

<style>
/* latin */
@font-face {
    font-family: 'Big Shoulders Stencil Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./assets/fonts/BigShoulder/BigShouldersDisplay-Regular.ttf") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
    font-family: 'Libre Franklin';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./assets/fonts/Libre_Franklin/LibreFranklin-Regular.ttf") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./assets/fonts/NotoSans/NotoSans-Regular.ttf") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Noto Sans", "Libre Franklin", sans-serif;
}

/*#########################################################*/
/*Text Styles*/
.text-small{
  @apply not-italic;
  @apply font-normal;
  @apply text-cc-small;
}

.text-small-strong{
  @apply not-italic;
  @apply font-semibold;
  @apply text-cc-small;
}
.text-medium{
  @apply not-italic;
  @apply font-normal;
  @apply text-cc-medium;
}

.text-medium-medium{
  @apply not-italic;
  @apply font-medium;
  @apply text-cc-medium;
}

.text-medium-strong{
  @apply not-italic;
  @apply font-semibold;
  @apply text-cc-medium;
}

.text-large {
  @apply not-italic;
  @apply font-normal;
  @apply text-cc-large;
}

.text-large-medium{
  @apply not-italic;
  @apply font-semibold;
  @apply text-cc-large;
}

.text-large-strong{
  @apply not-italic;
  @apply font-bold;
  @apply text-cc-large;
}

/*--------------------------------------*/
/*Headline Styles*/
.headline-xsmall{
  @apply not-italic;
  @apply font-bold;
  @apply text-cc-medium;
  @apply mt-1
}

.headline-small{
  @apply not-italic;
  @apply font-semibold;
  @apply text-cc-large;
  @apply mt-1
}

.headline-medium{
  @apply not-italic;
  @apply font-semibold;
  @apply text-cc-xlarge;
  @apply mt-3;
}

.headline-large{
  @apply not-italic;
  @apply font-semibold;
  @apply text-cc-2xlarge;
  @apply mt-4;
}

/*--------------------------------------*/
.text-selected{
  @apply text-white
}
/*#########################################################*/
/*#########################################################*/
table{
  border-spacing: 0;
}

/*Buttons Styles*/
button {
  border: 2px solid #125797;
}

.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #e6f0fa;
}
button:focus {
  outline: none;
}

button:disabled {
  background-color: #7b7b7b;
  border-color: #7b7b7b;
  cursor: not-allowed;
}

.btn-primary {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  background: #125797;
  border-radius: 4px;

  align-self: flex-end;
  justify-self: flex-end;

  cursor: pointer;
}

.btn-primary:focus,
.btn-primary:hover:enabled {
  background-color: #e6f0fa;
  color: #125797;
}

.btn-secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;

  background-color: #ffffff;
  border-color: #125797;
  border-radius: 4px;

  align-self: flex-end;
  justify-self: flex-end;

  cursor: pointer;
}
/*#########################################################*/
</style>
