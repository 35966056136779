export const keysObj = JSON.parse("{\n" +
  "    \"keys\": [\n" +
  "        {\n" +
  "            \"id\": \"1\",\n" +
  "            \"title\": \"Beamten und Richterverhältnis\",\n" +
  "            \"value\": \"Beamten und Richterverhältnis\",\n" +
  "            \"isParent\": true,\n" +
  "            \"subMenus\": [\n" +
  "                {\n" +
  "                    \"id\": \"0602\",\n" +
  "                    \"key\": \"0602\",\n" +
  "                    \"title\": \"Mit voller Stundenzahl einschließlich Vorbereitungsdienst\",\n" +
  "                    \"value\": \"Mit voller Stundenzahl einschließlich Vorbereitungsdienst\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"0607\",\n" +
  "                    \"key\": \"0607\",\n" +
  "                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"0613\",\n" +
  "                    \"key\": \"0613\",\n" +
  "                    \"title\": \"Beurlaubung ohne Dienstbezüge\",\n" +
  "                    \"value\": \"Beurlaubung ohne Dienstbezüge\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"0616\",\n" +
  "                    \"key\": \"0616\",\n" +
  "                    \"title\": \"Altersteilzeit\",\n" +
  "                    \"value\": \"Altersteilzeit\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"0617\",\n" +
  "                    \"key\": \"0617\",\n" +
  "                    \"title\": \"Altersteilzeit im Blockmodell\",\n" +
  "                    \"value\": \"Altersteilzeit im Blockmodell\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"0603\",\n" +
  "                    \"key\": \"0603\",\n" +
  "                    \"title\": \"Teildienstfähigkeit\",\n" +
  "                    \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"11\",\n" +
  "                    \"title\": \"Aufbauhilfe in den neuen Bundesländern\",\n" +
  "                    \"value\": \"Beamten und Richterverhältnis\",\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"0685\",\n" +
  "                            \"key\": \"0685\",\n" +
  "                            \"title\": \"Mit voller Stundenzahl\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"0686\",\n" +
  "                            \"key\": \"0686\",\n" +
  "                            \"title\": \"Mit Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        }\n" +
  "                    ]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"12\",\n" +
  "                    \"title\": \"Verwendung in Ländern mit gesundheitsschädigendem Klima\",\n" +
  "                    \"value\": \"Beamten und Richterverhältnis\",\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"1301\",\n" +
  "                            \"key\": \"1301\",\n" +
  "                            \"title\": \"Mit voller Stundenzahl\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"1304\",\n" +
  "                            \"key\": \"1304\",\n" +
  "                            \"title\": \"Mit Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"1306\",\n" +
  "                            \"key\": \"1306\",\n" +
  "                            \"title\": \"Unterbrechung der Verwendung bei voller Stundenzahl\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"1309\",\n" +
  "                            \"key\": \"1309\",\n" +
  "                            \"title\": \"Unterbrechung der Verwendung bei Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        }\n" +
  "                    ]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"13\",\n" +
  "                    \"title\": \"Kinderziehung, Mutterschutz\",\n" +
  "                    \"value\": \"Beamten und Richterverhältnis\",\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"130602\",\n" +
  "                            \"key\": \"0602\",\n" +
  "                            \"title\": \"Mutterschutzfrist\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"130657\",\n" +
  "                            \"key\": \"0657\",\n" +
  "                            \"title\": \"Erziehungszeit bis Ablauf des 6. Lebensmonats (Kind vor 1992 geboren)\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"1310613\",\n" +
  "                            \"key\": \"0613\",\n" +
  "                            \"title\": \"Beurlaubung nach 6. Lebensmonat (Kind vor 1992 geboren)\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"1320613\",\n" +
  "                            \"key\": \"0613\",\n" +
  "                            \"title\": \"Elternzeit (Kind nach 1991 geboren)\",\n" +
  "                            \"value\": \"Beamten und Richterverhältnis\"\n" +
  "                        }\n" +
  "    \n" +
  "                    ]\n" +
  "                }\n" +
  "            ]\n" +
  "        },\n" +
  "        {\n" +
  "            \"id\": \"2\",\n" +
  "            \"title\": \"Bundeswehr und Zivildienst\",\n" +
  "            \"value\": \"Bundeswehr und Zivildienst\",\n" +
  "            \"isParent\": true,\n" +
  "            \"subMenus\": [\n" +
  "                {\n" +
  "                    \"id\": \"0901\",\n" +
  "                    \"key\": \"0901\",\n" +
  "                    \"title\": \"Bundeswehr, Zivildienst, Wehrübung außerhalb des Beamtenverhältnisses\",\n" +
  "                    \"value\": \"Bundeswehr, Zivildienst, Wehrübung außerhalb des Beamtenverhältnisses\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"0803\",\n" +
  "                    \"key\": \"0803\",\n" +
  "                    \"title\": \"Unmittelbar vor dem Beamtenverhältnis liegendes Berufssoldatenverhältnis, das bereits am 31.12.1991 bestanden hat\",\n" +
  "                    \"value\": \"Unmittelbar vor dem Beamtenverhältnis liegendes Berufssoldatenverhältnis, das bereits am 31.12.1991 bestanden hat\"\n" +
  "                }\n" +
  "            ]\n" +
  "        },\n" +
  "        {\n" +
  "            \"id\": \"3\",\n" +
  "            \"title\": \"Angestelltenverhältnis im Öffentlichen Dienst\",\n" +
  "            \"value\": \"Angestelltenverhältnis im Öffentlichen Dienst\",\n" +
  "            \"isParent\": true,\n" +
  "            \"subMenus\": [\n" +
  "                {\n" +
  "                    \"id\": \"1001\",\n" +
  "                    \"key\": \"1001\",\n" +
  "                    \"title\": \"mit voller Stundenzahl\",\n" +
  "                    \"value\": \"mit voller Stundenzahl\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1002\",\n" +
  "                    \"key\": \"1002\",\n" +
  "                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1006\",\n" +
  "                    \"key\": \"1006\",\n" +
  "                    \"title\": \"Unterhälftige Teilzeitbeschäftigung\",\n" +
  "                    \"value\": \"Unterhälftige Teilzeitbeschäftigung\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1004\",\n" +
  "                    \"key\": \"1004\",\n" +
  "                    \"title\": \"Beurlaubung ohne Entgelt\",\n" +
  "                    \"value\": \"Beurlaubung ohne Entgelt\"\n" +
  "                }\n" +
  "            ]\n" +
  "        },\n" +
  "        {\n" +
  "            \"id\": \"4\",\n" +
  "            \"title\": \"Beschäftigung im Öffentlichen Dienst\",\n" +
  "            \"value\": \"Beschäftigung im Öffentlichen Dienst\",\n" +
  "            \"isParent\": true,\n" +
  "            \"subMenus\": [\n" +
  "                {\n" +
  "                    \"id\": \"1085\",\n" +
  "                    \"key\": \"1085\",\n" +
  "                    \"title\": \"mit voller Stundenzahl\",\n" +
  "                    \"value\": \"mit voller Stundenzahl\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1083\",\n" +
  "                    \"key\": \"1083\",\n" +
  "                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1084\",\n" +
  "                    \"key\": \"1084\",\n" +
  "                    \"title\": \"Beurlaubung ohne Entgelt\",\n" +
  "                    \"value\": \"Beurlaubung ohne Entgelt\"\n" +
  "                }\n" +
  "            ]\n" +
  "        },\n" +
  "        {\n" +
  "            \"id\": \"5\",\n" +
  "            \"title\": \"Ausbildung\",\n" +
  "            \"value\": \"Ausbildung\",\n" +
  "            \"isParent\": true,\n" +
  "            \"subMenus\": [\n" +
  "                {\n" +
  "                    \"id\": \"1210\",\n" +
  "                    \"key\": \"1210\",\n" +
  "                    \"title\": \"Vorbereitungsdienst außerhalb Beamtenverhältnis\",\n" +
  "                    \"value\": \"Vorbereitungsdienst außerhalb Beamtenverhältnis\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1233\",\n" +
  "                    \"key\": \"1233\",\n" +
  "                    \"title\": \"Fachschulausbildung\",\n" +
  "                    \"value\": \"Fachschulausbildung\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1230\",\n" +
  "                    \"key\": \"1230\",\n" +
  "                    \"title\": \"Hochschul- und Fachhochschulausbildung\",\n" +
  "                    \"value\": \"Hochschul- und Fachhochschulausbildung\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"1221\",\n" +
  "                    \"key\": \"1221\",\n" +
  "                    \"title\": \"Vollzugsdienst & Einsatzdienst Feuerwehr\",\n" +
  "                    \"value\": \"Vollzugsdienst & Einsatzdienst Feuerwehr\"\n" +
  "                }\n" +
  "            ]\n" +
  "        },\n" +
  "        {\n" +
  "            \"id\": \"6\",\n" +
  "            \"title\": \"Sonstige Zeiten\",\n" +
  "            \"value\": \"Sonstige Zeiten\",\n" +
  "            \"isParent\": true,\n" +
  "            \"subMenus\": [\n" +
  "                {\n" +
  "                    \"id\": \"61\",\n" +
  "                    \"title\": \"Im öffentlichen oder nichtöffentlichen Schuldienst\",\n" +
  "                    \"value\": \"Im öffentlichen oder nichtöffentlichen Schuldienst\",\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"61_1102\",\n" +
  "                            \"key\": \"1102\",\n" +
  "                            \"title\": \"mit voller Stundenzahl\",\n" +
  "                            \"value\": \"mit voller Stundenzahl\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"61_1103\",\n" +
  "                            \"key\": \"1103\",\n" +
  "                            \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"61_1119\",\n" +
  "                            \"key\": \"1119\",\n" +
  "                            \"title\": \"Unterhälftige Beschäftigung\",\n" +
  "                            \"value\": \"Unterhälftige Beschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"61_1101\",\n" +
  "                            \"key\": \"1101\",\n" +
  "                            \"title\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\",\n" +
  "                            \"value\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"61_1118\",\n" +
  "                            \"key\": \"1118\",\n" +
  "                            \"title\": \"Tätigkeit Entwicklungshelfer\",\n" +
  "                            \"value\": \"Tätigkeit Entwicklungshelfer\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"511\",\n" +
  "                            \"title\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"value\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"subMenus\": [\n" +
  "                                {\n" +
  "                                    \"id\": \"61_1185\",\n" +
  "                                    \"key\": \"1185\",\n" +
  "                                    \"title\": \"mit voller Stundenzahl\",\n" +
  "                                    \"value\": \"mit voller Stundenzahl\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"61_1183\",\n" +
  "                                    \"key\": \"1183\",\n" +
  "                                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"61_1184\",\n" +
  "                                    \"key\": \"1184\",\n" +
  "                                    \"title\": \"beurlaubt ohne Bezüge\",\n" +
  "                                    \"value\": \"beurlaubt ohne Bezüge\"\n" +
  "                                }\n" +
  "                            ]\n" +
  "                        }\n" +
  "                    ]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"62\",\n" +
  "                    \"title\": \"Im Dienst öffentlich-rechtlicher Religionsgemeinschaften\",\n" +
  "                    \"value\": \"Im Dienst öffentlich-rechtlicher Religionsgemeinschaften\",\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"62_1102\",\n" +
  "                            \"key\": \"1102\",\n" +
  "                            \"title\": \"mit voller Stundenzahl\",\n" +
  "                            \"value\": \"mit voller Stundenzahl\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"62_1103\",\n" +
  "                            \"key\": \"1103\",\n" +
  "                            \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"62_1119\",\n" +
  "                            \"key\": \"1119\",\n" +
  "                            \"title\": \"Unterhälftige Beschäftigung\",\n" +
  "                            \"value\": \"Unterhälftige Beschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"62_1101\",\n" +
  "                            \"key\": \"1101\",\n" +
  "                            \"title\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\",\n" +
  "                            \"value\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"62_1118\",\n" +
  "                            \"key\": \"1118\",\n" +
  "                            \"title\": \"Tätigkeit Entwicklungshelfer\",\n" +
  "                            \"value\": \"Tätigkeit Entwicklungshelfer\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"511\",\n" +
  "                            \"title\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"value\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"subMenus\": [\n" +
  "                                {\n" +
  "                                    \"id\": \"62_1185\",\n" +
  "                                    \"key\": \"1185\",\n" +
  "                                    \"title\": \"mit voller Stundenzahl\",\n" +
  "                                    \"value\": \"mit voller Stundenzahl\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"62_1183\",\n" +
  "                                    \"key\": \"1183\",\n" +
  "                                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"62_1184\",\n" +
  "                                    \"key\": \"1184\",\n" +
  "                                    \"title\": \"beurlaubt ohne Bezüge\",\n" +
  "                                    \"value\": \"beurlaubt ohne Bezüge\"\n" +
  "                                }\n" +
  "                            ]\n" +
  "                        }\n" +
  "                    ]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"63\",\n" +
  "                    \"title\": \"Im Dienst der Fraktionen des Bundestages, der Landtage\",\n" +
  "                    \"value\": \"Im Dienst der Fraktionen des Bundestages, der Landtage\",\n" +
  "                    \"isParent\": true,\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"63_1102\",\n" +
  "                            \"key\": \"1102\",\n" +
  "                            \"title\": \"mit voller Stundenzahl\",\n" +
  "                            \"value\": \"mit voller Stundenzahl\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"63_1103\",\n" +
  "                            \"key\": \"1103\",\n" +
  "                            \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"63_1119\",\n" +
  "                            \"key\": \"1119\",\n" +
  "                            \"title\": \"Unterhälftige Beschäftigung\",\n" +
  "                            \"value\": \"Unterhälftige Beschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"63_1101\",\n" +
  "                            \"key\": \"1101\",\n" +
  "                            \"title\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\",\n" +
  "                            \"value\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"63_1118\",\n" +
  "                            \"key\": \"1118\",\n" +
  "                            \"title\": \"Tätigkeit Entwicklungshelfer\",\n" +
  "                            \"value\": \"Tätigkeit Entwicklungshelfer\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"511\",\n" +
  "                            \"title\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"value\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"isParent\": true,\n" +
  "                            \"subMenus\": [\n" +
  "                                {\n" +
  "                                    \"id\": \"63_1185\",\n" +
  "                                    \"key\": \"1185\",\n" +
  "                                    \"title\": \"mit voller Stundenzahl\",\n" +
  "                                    \"value\": \"mit voller Stundenzahl\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"63_1183\",\n" +
  "                                    \"key\": \"1183\",\n" +
  "                                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"63_1184\",\n" +
  "                                    \"key\": \"1184\",\n" +
  "                                    \"title\": \"beurlaubt ohne Bezüge\",\n" +
  "                                    \"value\": \"beurlaubt ohne Bezüge\"\n" +
  "                                }\n" +
  "                            ]\n" +
  "                        }\n" +
  "                    ]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"64\",\n" +
  "                    \"title\": \"Im Dienst kommunaler Spitzenverbände oder ihrer Landesverbände\",\n" +
  "                    \"value\": \"Im Dienst kommunaler Spitzenverbände oder ihrer Landesverbände\",\n" +
  "                    \"isParent\": true,\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"64_1102\",\n" +
  "                            \"key\": \"1102\",\n" +
  "                            \"title\": \"mit voller Stundenzahl\",\n" +
  "                            \"value\": \"mit voller Stundenzahl\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"64_1103\",\n" +
  "                            \"key\": \"1103\",\n" +
  "                            \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"64_1119\",\n" +
  "                            \"key\": \"1119\",\n" +
  "                            \"title\": \"Unterhälftige Beschäftigung\",\n" +
  "                            \"value\": \"Unterhälftige Beschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"64_1101\",\n" +
  "                            \"key\": \"1101\",\n" +
  "                            \"title\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\",\n" +
  "                            \"value\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"64_1118\",\n" +
  "                            \"key\": \"1118\",\n" +
  "                            \"title\": \"Tätigkeit Entwicklungshelfer\",\n" +
  "                            \"value\": \"Tätigkeit Entwicklungshelfer\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"611\",\n" +
  "                            \"title\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"value\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"isParent\": true,\n" +
  "                            \"subMenus\": [\n" +
  "                                {\n" +
  "                                    \"id\": \"64_1185\",\n" +
  "                                    \"key\": \"1185\",\n" +
  "                                    \"title\": \"mit voller Stundenzahl\",\n" +
  "                                    \"value\": \"mit voller Stundenzahl\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"64_1183\",\n" +
  "                                    \"key\": \"1183\",\n" +
  "                                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"64_1184\",\n" +
  "                                    \"key\": \"1184\",\n" +
  "                                    \"title\": \"beurlaubt ohne Bezüge\",\n" +
  "                                    \"value\": \"beurlaubt ohne Bezüge\"\n" +
  "                                }\n" +
  "                            ]\n" +
  "                        }\n" +
  "                    ]\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"65\",\n" +
  "                    \"title\": \"Im ausländischen öffentlichen Dienst\",\n" +
  "                    \"value\": \"Im ausländischen öffentlichen Dienst\",\n" +
  "                    \"isParent\": true,\n" +
  "                    \"subMenus\": [\n" +
  "                        {\n" +
  "                            \"id\": \"65_1102\",\n" +
  "                            \"key\": \"1102\",\n" +
  "                            \"title\": \"mit voller Stundenzahl\",\n" +
  "                            \"value\": \"mit voller Stundenzahl\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"65_1103\",\n" +
  "                            \"key\": \"1103\",\n" +
  "                            \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                            \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"65_1119\",\n" +
  "                            \"key\": \"1119\",\n" +
  "                            \"title\": \"Unterhälftige Beschäftigung\",\n" +
  "                            \"value\": \"Unterhälftige Beschäftigung\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"65_1101\",\n" +
  "                            \"key\": \"1101\",\n" +
  "                            \"title\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\",\n" +
  "                            \"value\": \"Tätigkeit als Rechtsanwalt, Anwaltsassessor oder Notar\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"65_1118\",\n" +
  "                            \"key\": \"1118\",\n" +
  "                            \"title\": \"Tätigkeit Entwicklungshelfer\",\n" +
  "                            \"value\": \"Tätigkeit Entwicklungshelfer\"\n" +
  "                        },\n" +
  "                        {\n" +
  "                            \"id\": \"651\",\n" +
  "                            \"title\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"value\": \"Versicherungsfreie Beschäftigung als Planstelleninhaber\",\n" +
  "                            \"isParent\": true,\n" +
  "                            \"subMenus\": [\n" +
  "                                {\n" +
  "                                    \"id\": \"65_1185\",\n" +
  "                                    \"key\": \"1185\",\n" +
  "                                    \"title\": \"mit voller Stundenzahl\",\n" +
  "                                    \"value\": \"mit voller Stundenzahl\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"65_1183\",\n" +
  "                                    \"key\": \"1183\",\n" +
  "                                    \"title\": \"Teilzeitbeschäftigung\",\n" +
  "                                    \"value\": \"Teilzeitbeschäftigung\"\n" +
  "                                },\n" +
  "                                {\n" +
  "                                    \"id\": \"65_1184\",\n" +
  "                                    \"key\": \"1184\",\n" +
  "                                    \"title\": \"beurlaubt ohne Bezüge\",\n" +
  "                                    \"value\": \"beurlaubt ohne Bezüge\"\n" +
  "                                }\n" +
  "                            ]\n" +
  "                        }\n" +
  "                    ]\n" +
  "                }\n" +
  "            ]\n" +
  "        },\n" +
  "        {\n" +
  "            \"id\": \"7\",\n" +
  "            \"title\": \"Hochschulpersonal\",\n" +
  "            \"value\": \"Hochschulpersonal\",\n" +
  "            \"isParent\": true,\n" +
  "            \"subMenus\": [\n" +
  "                {\n" +
  "                    \"id\": \"6701\",\n" +
  "                    \"key\": \"6701\",\n" +
  "                    \"title\": \"Promotionszeit oder promotionsadäquate Zeiten\",\n" +
  "                    \"value\": \"Promotionszeit oder promotionsadäquate Zeiten\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"6719\",\n" +
  "                    \"key\": \"6719\",\n" +
  "                    \"title\":\n" +
  "                        \"Vorgeschriebene Mindestzeit für die Erbringung der Habilitationsleistungen oder sonstiger gleichwertiger wissenschaftlicher Leistungen maximal 3 Jahre \",\n" +
  "                    \"value\":\n" +
  "                        \"Vorgeschriebene Mindestzeit für die Erbringung der Habilitationsleistungen oder sonstiger gleichwertiger wissenschaftlicher Leistungen maximal 3 Jahre \"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"6702\",\n" +
  "                    \"key\": \"6702\",\n" +
  "                    \"title\": \"Zeit der Zugehörigkeit Lehrkörper\",\n" +
  "                    \"value\": \"Zeit der Zugehörigkeit Lehrkörper\"\n" +
  "                },\n" +
  "                {\n" +
  "                    \"id\": \"6705\",\n" +
  "                    \"key\": \"6705\",\n" +
  "                    \"title\": \"Zeit des Erwerbs Fachkenntnisse, 5 Jahre voll, dann max. 10 Jahre zur Hälfte\",\n" +
  "                    \"value\": \"Zeit des Erwerbs Fachkenntnisse, 5 Jahre voll, dann max. 10 Jahre zur Hälfte\"\n" +
  "                }\n" +
  "            ]\n" +
  "        }\n" +
  "    ]\n" +
  "}")