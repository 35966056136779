<template>
  <svg
    width="10"
    height="20"
    viewBox="0 0 10 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.67689 1.04289C10.0674 1.43342 10.0674 2.06658 9.67689 2.45711L2.134 10L9.67689 17.5429C10.0674 17.9334 10.0674 18.5666 9.67689 18.9571C9.28637 19.3476 8.6532 19.3476 8.26268 18.9571L0.543184 11.2376C0.214646 10.9095 0.0300327 10.4643 0.0300327 10C0.0300327 9.5357 0.214645 9.09046 0.543184 8.76239L8.26268 1.04289C8.65321 0.652369 9.28637 0.652369 9.67689 1.04289Z"
      fill="#2C2E35"
    />
  </svg>
</template>
