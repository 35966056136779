<template>
  <div class="nav-bar">
    <span class="nav-heading" :class="{'cursor-pointer': this.$route.name !== 'Berechnung'}" @click="routeToGeneral">{{pageTitle}}</span>
    <div role="navigation" class="nav-help-box centered" v-if="!isHelpPage && showHelpPage">
      <CircledQuestionMark class="mr-1" id="question-mark" color-type="white" @click="routeToHelp"/>
      <div
        id="help-text"
        class="text text-bold"
        @click="routeToHelp"
        tabindex="0"
      >
        Hilfe
      </div>
    </div>
  </div>
</template>

<script>
import CircledQuestionMark from "@/components/FormSites/GeneralFormComponents/UiElements/SVG-Icons/CircledQuestionMark";

export default {
  components: {
    CircledQuestionMark
  },
  data() {
    return {
      pageTitle: "Landesamt für Besoldung und Versorgung - Versorgungsrechner",
      lagreScreenPageTitle: "Landesamt für Besoldung und Versorgung - Versorgungsrechner",
      smallScreenPageTitle: "LBV - Versorgungsrechner"
    }
  },
  computed: {
    isHelpPage() {
      return (
        this.$route.name === "Einfuehrung" ||
        this.$route.name === "Altersgrenze" ||
        this.$route.name === "Schluessel" ||
        this.$route.name === "Regeln" ||
        this.$route.name === "Laufbahn" ||
        this.$route.name === "Example"
      );
    },
    showHelpPage(){
      return this.$route.name === "General" || this.$route.name === "Timeline";
    }
  },
  methods: {
    routeToHelp() {
      this.$router.push({ name: "Einfuehrung" });
    },
    setTitle(){
      if(screen.width < 1024){
        this.pageTitle = this.smallScreenPageTitle;
      }else{
        this.pageTitle = this.lagreScreenPageTitle;
      }

      if(this.isHelpPage && !this.pageTitle.includes("Hilfe")){
        this.pageTitle += " Hilfe";
      }
    },
    routeToGeneral(){
      if(this.$route.name !== 'Berechnung') {
        this.$router.push({name: "General"});
      }
    }
  },
  mounted() {
    const observer = new ResizeObserver(elements => {
      const resizedElement = elements.find(element => element.target === document.body);
      if(resizedElement && resizedElement.contentRect.width < 1024){
        this.pageTitle = this.smallScreenPageTitle;
      }else if(resizedElement){
        this.pageTitle = this.lagreScreenPageTitle;
      }
    });

    //Register Observer which will call the callback everytime the body is resized
    observer.observe(document.body);
    //Initial set Title
    this.setTitle()
  }
};
</script>

<style scoped>
.nav-bar {
  width: 100%;
  height: 80px;

  background-color: #125797;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.nav-heading {
  margin-left: 1rem;

  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;

  color: #ffffff;
}

.nav-help-box {
  margin-right: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  color: #ffffff;
}

.hollowed-circle {
  border: 3px solid #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 100%;

  margin-right: 1rem;
}

.text-bold {
  font-size: larger;
  color: #ffffff;
}

#question-mark:hover {
  cursor: pointer;
}

#help-text:hover {
  cursor: pointer;
}
</style>
