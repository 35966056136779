<template>
  <span :role="roleStr" class="border">{{ period.from }}</span>
  <span :role="roleStr" class="border">{{ period.to }}</span>
  <span :role="roleStr" class="border" style="white-space:pre-wrap;">{{ getFullText(keyId) }}</span>
  <span :role="roleStr" class="border">{{ fracture }}</span>
  <span :role="roleStr" class="border">{{ neuesRechtYear }}</span>
  <span :role="roleStr" class="border">{{ neuesRechtDay }}</span>
  <span v-if="isRechtUeb" :role="roleStr" class="border">{{
    altesRechtYear
  }}</span>
  <span v-if="isRechtUeb" :role="roleStr" class="border">{{
    altesRechtDay
  }}</span>
  <span v-if="isRechtUeb" :role="roleStr" class="border">{{
    ubergangsRechtYear
  }}</span>
  <span v-if="isRechtUeb" :role="roleStr" class="border">{{
    ubergangsRechtDay
  }}</span>
</template>

<script>
import { dateTosTring, convertToCommaStr } from "../../../../helper/helper";
export default {
  props: {
    servicePeriods: Object,
    roleStr: String
  },
  computed: {
    period() {
      const period = {
        from: "",
        to: ""
      };
      if (!this.servicePeriods || !this.servicePeriods.workPeriod)
        return period;

      const from = this.servicePeriods.workPeriod.from;
      const to = this.servicePeriods.workPeriod.to;

      period.from = dateTosTring(new Date(from));
      period.to = dateTosTring(new Date(to));
      return period;
    },
    keyId() {
      if (!this.servicePeriods || !this.servicePeriods.keyId) return "";
      if(this.servicePeriods.keyId.toString().length < 4) return `0${this.servicePeriods.keyId}`
      return this.servicePeriods.keyId;
    },
    fracture() {

      if (
        !this.servicePeriods ||
        !this.servicePeriods.counter ||
        !this.servicePeriods.denominator
      ) {
        return "";
      }
      return (
        convertToCommaStr(this.servicePeriods.counter) + " / " + convertToCommaStr(this.servicePeriods.denominator)
      );
    },
    neuesRechtYear() {
      return this.servicePeriods.neuesRecht.years;
    },
    neuesRechtDay() {
      return convertToCommaStr(this.servicePeriods.neuesRecht.days);
    },
    altesRechtYear() {
      return this.servicePeriods.altesRecht.years;
    },
    altesRechtDay() {
      return convertToCommaStr(this.servicePeriods.altesRecht.days);
    },
    ubergangsRechtYear() {
      return this.servicePeriods.mixedRecht.years;
    },
    ubergangsRechtDay() {
      return convertToCommaStr(this.servicePeriods.mixedRecht.days);
    },
    isRechtNeu() {
      return this.$store.getters.getResult.workPeriodSum.neuesRecht.days ||
        this.$store.getters.getResult.workPeriodSum.neuesRecht.days
        ? true
        : false;
    },
    isRechtAlt() {
      return this.$store.getters.getResult.workPeriodSum.altesRecht.years ||
        this.$store.getters.getResult.workPeriodSum.altesRecht.days
        ? true
        : false;
    },
    isRechtUeb() {
      return this.$store.getters.getResult.workPeriodSum.uebergangsRecht.years ||
        this.$store.getters.getResult.workPeriodSum.uebergangsRecht.days
        ? true
        : false;
    }
  },
  methods: {
    getFullText(keyId){      
      // return this.$store.getters.getServicePeriods.find(period => period.employment.text.includes(keyId))?.employment.text.replace(">", ">\n");
      return this.$store.getters.getServicePeriods.find(period => period.employment.id === keyId)?.employment.text.replace(">", ">\n");
    }
  }
};
</script>

<style scoped>
.border {
  margin: 0.5px;
  border: 2px solid #d9dada;
}
</style>
